/**
 * Account React Component
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CheckboxRadio from 'aligentreact/build/components/CheckboxRadio/CheckboxRadio';
import ReturningUserContainer from '../../common/ReturningUserContainer';
import TabbedUiContainer from 'aligentreact/build/containers/TabbedUiContainer/TabbedUiContainer';
import SignUpForm from './../../common/SignUpForm';
import SocialLogin from 'aligentreact/build/components/SocialLogin/SocialLogin';
import { userLogin, resetPassword, createCustomerAccount } from '../../Util/API';
import HeaderTabs from './HeaderTabs';
import { featureFlags, getSignUpContentFromCountry, isValidTelephone } from '../../Util/functions';

class Account extends Component {

    constructor(props) {
        super(props);

        this.signUpFormRef = React.createRef();

        this.state = {
            email: {
                value: '',
                valid: true,
                isAutoComplete: false
            },
            password: {
                value: '',
                valid: true,
                isAutoComplete: false
            },
            loginFeedback: '',
            socialNetworks: [],
            signupRequiredError: false,
            termsAndConditionsChecked: false
        };
    }

    handleChange = ({ target }) => {
        const value = target.type === 'checkbox' ? target.checked : {
            ...this.state[target.name],
            value: target.value
        };

        this.setState({
            ...this.state,
            [target.name]: value
        });
    };

    handleLogin = async () => {
        // Clear any previous errors that might be displayed
        this.setState({
            loginFeedback: ''
        });

        await this.props.increasePendingRequestsCount();
        try {
            const { status, data } = await userLogin(this.state.email.value, this.state.password.value, 'checkout');

            if (status === 200 && data.errors === false) {
                this.props.handleUserLoggedIn();
                await this.props.decreasePendingRequestsCount();
                return;
            }

            this.setState({
                loginFeedback: data.message
            });
            await this.props.decreasePendingRequestsCount();
        } catch (error) {
            await this.props.decreasePendingRequestsCount();
        }
    };

    /**
     * Handle the event fired when the user selects the 'Continue' button
     *
     * @returns {null}
     */
    handleContinue = async (personalDetails) => {
        const {
            firstname,
            lastname,
            email,
            language_preference,
            telephone,
            password,
            dob,
            signUpNewsletter,
            checkout_registration_secret_key,
        } = personalDetails;
        const { defaultCountryId, quoteData } = this.props.checkoutConfig;
        const { termsAndConditionsChecked } = this.state;

        // sign up requirements
        // AU, NZ: user must sign up to newsletter (combined with T&C)
        // CA, UK: user must check T&C only
        await this.setState({
            signupRequiredError:
                featureFlags(defaultCountryId).SEPARATE_POLICY_CHECKBOX_ENABLED ?
                !termsAndConditionsChecked : !signUpNewsletter
        });
        if (this.state.signupRequiredError) return;

        // validate phone number
        // max: 15
        // only contain number digits
        // if(!isValidTelephone(telephone)){
        //     // print out message
        //     return this.signUpFormRef.current.setValidationMessage('Invalid phone number. Please enter a valid number with no more than 15 digits');
        // }

        await this.props.increasePendingRequestsCount();

        try {
            await createCustomerAccount({
                storeId: quoteData.store_id,
                email,
                firstname,
                lastname,
                telephone,
                password,
                dob,
                isSubscribed: signUpNewsletter,
                checkout_registration_secret_key,
                language_preference
            });
            this.props.decreasePendingRequestsCount();

            this.setState({
                ...this.state,
                email: {
                    ...this.state.email,
                    value: email
                },
                password: {
                    ...this.state.password,
                    value: password
                }
            });

            //send client-side FB Pixel event
            let eventIdgenerateUrl = BASE_URL + 'fbpixel/Pixel/GenerateEventId/';
            jQuery.ajax({
                url: eventIdgenerateUrl,
                type: 'POST',
                dataType: 'json',
                data: {
                    type: 'CompleteRegistration',
                    event: 'customer_register_success_after',
                },
                complete: function(response) {
                    if (response && response.responseJSON) {
                        const responseObj = response.responseJSON;
                        if (responseObj.is_browser_event_enabled && responseObj.event_id) {                   
                            fbq('set', 'agent', responseObj.facebook_agent_version || '', responseObj.facebook_pixel_id || '');
                            fbq('track', 'CompleteRegistration', {
                                source: responseObj.source || '',
                                pluginVersion: responseObj.pluginVersion || '',
                                register: responseObj.register || '',
                            }, {
                                eventID: responseObj.event_id || '',
                            });
                        }
                    }  
                },
                error: function (xhr, status, errorThrown) {
                    //console.log('');
                }
            });

            this.handleLogin();
        } catch ({ response: { data: { message, parameters = [] } } }) {
            let validationMessage = message;
            this.props.decreasePendingRequestsCount();

            // Replace placeholders in validation message with actual validation parameters
            if (parameters.length > 0) {
                parameters.forEach((param, index) => {
                    validationMessage = validationMessage.replace(`%${(index + 1)}`, param);
                });
            }

            this.signUpFormRef.current.setValidationMessage(validationMessage);
        }
    };

    /**
     * Handle the reset password event
     */
    handleReset = async () => {
        await this.props.increasePendingRequestsCount();
        try {
            const passwordReset = await resetPassword(this.state.email.value);
            this.props.decreasePendingRequestsCount();

            this.setState({
                loginFeedback: 'A password reset link has been sent to your provided email address'
            });
        } catch (e) {
            this.props.decreasePendingRequestsCount();

            this.setState({
                loginFeedback: 'There was an error sending the password reset link. Please try again'
            });
        }
    };

    getReturningUserFormJSX() {
        return (
            <section className="returning-user__section">
                <ReturningUserContainer
                    email={this.state.email}
                    password={this.state.password}
                    handleChange={this.handleChange}
                    handleLogin={this.handleLogin}
                    handleReset={this.handleReset}
                    loginFeedback={this.state.loginFeedback}
                    slotOne={(
                        <header className="returning-user__header">
                            <h1 className="returning-user__heading">Welcome Back</h1>
                        </header>
                    )}
                />
            </section>
        );
    }

    getNewsletterLabel() {
        return this.props.checkoutConfig.defaultCountryId === 'CA' ?
            <span>I agree to receive email marketing communications from Ever New.</span> :
            <span>Join Forever You Rewards to get your $25 birthday voucher, 20% off<em>*</em> reward, VIP access, spend and earn notifications and special offers. I agree to the <a href="/policy" target="_blank" style={{textDecoration: "underline"}}>Privacy Policy</a></span>
    }

    getTermsAndConditions() {
        const { defaultCountryId } = this.props.checkoutConfig;
        const termsAndConditionsContent = getSignUpContentFromCountry(defaultCountryId).TERMS_AND_CONDITIONS_CONTENT;

        return featureFlags(defaultCountryId).SEPARATE_POLICY_CHECKBOX_ENABLED && (
            <div className="sign-up-terms-and-conditions checkout-checkbox">
                <CheckboxRadio
                    type="checkbox"
                    id="terms-and-conditions"
                    name="termsAndConditionsChecked"
                    label={<span>{termsAndConditionsContent} <a href="/policy" target="_blank">Privacy Policy</a></span>}
                    onChange={this.handleChange}
                />
            </div>
        )
    }

    /**
     * Display base content for sign up form
     * CA store has newsletter and T&C separated
     *
     * @return {Element}
     */
    getSlotTwo() {
        return (
            <Fragment>
                {this.getTermsAndConditions()}
                {this.state.signupRequiredError && <div className="checkout-checkbox__error">This is a required field</div>}
            </Fragment>
        );
    }

    getSignUpFormJSX() {
        return (
            <section className="sign-up-form__section">
                <button type="button" className="sign-up-form__section-button--guest" onClick={this.props.handleGuestCheckout}>Checkout as Guest</button>
                <SignUpForm
                    ref={this.signUpFormRef}
                    handleContinue={this.handleContinue}
                    canCheckoutAsGuest={false}
                    handleGuest={this.props.handleGuestCheckout}
                    slotOne={(
                        <header className="signup-form__header">
                            <h1 className="sign-up-form__heading--main">New around here?</h1>
                            <p>Join Forever You Rewards to receive exclusive member updates and rewards, including birthday voucher and spend and earn.</p>
                        </header>
                    )}
                    newsletterLabel={this.getNewsletterLabel()}
                    slotTwo={this.getSlotTwo()}
                    checkoutConfig={this.props.checkoutConfig}
                />
            </section>
        );
    }

    getTabs() {
        return [
            {
                label: 'Returning User',
                content: this.getReturningUserFormJSX()
            },
            {
                label: 'New User',
                content: this.getSignUpFormJSX()
            }
        ];
    }

    render() {
        const classname = classnames('account-container', { 'section-active': this.props.activeSection === HeaderTabs.CONST__ACCOUNT });

        const socialLoginContent = this.props.checkoutApp.components.checkout.children.authentication.children['social-fields'].linksContent;
        return (
            <section className={classname}>
                <TabbedUiContainer
                    tabs={this.getTabs()}
                >
                    <SocialLogin
                        networks={this.state.socialNetworks}
                        label= {socialLoginContent ? 'Or the social media log in' : ''}
                    >
                        {socialLoginContent}
                    </SocialLogin>
                </TabbedUiContainer>
            </section>
        );
    }

}

Account.propTypes = {
    checkoutConfig: PropTypes.object.isRequired,
    checkoutApp: PropTypes.object.isRequired,
    activeSection: PropTypes.number.isRequired,
    handleUserLoggedIn: PropTypes.func.isRequired,
    handleGuestCheckout: PropTypes.func.isRequired,
    increasePendingRequestsCount: PropTypes.func.isRequired,
    decreasePendingRequestsCount: PropTypes.func.isRequired
};

export default Account;
