import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputContainer from "./InputContainer";
import EmailContainer from "./EmailContainer";
import PasswordContainer from "./PasswordContainer";
import {featureFlags, getSignUpContentFromCountry, isValidEmail} from "../Util/functions";
import { __ } from '../Util/Translator';
const emailRegex = /(.+)@(.+)\.(.+){1,}/;
const telephoneRegex = /^[\d]{1,15}$/;
const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

class SignUpForm extends Component {

    constructor(props) {
        super(props);

        const { fn_lang_preference } = window.checkoutConfig;

        const { is_enabled: isLanguagePreferenceEnabled, preference } =
            fn_lang_preference;
        const defaultLanguagePreference = isLanguagePreferenceEnabled
            ? preference.find(({ is_default }) => is_default === '1') ||
              preference[0]
            : undefined;

        this.state = {
            firstname: '',
            lastname: '',
            email: '',
            language_preference:
                (defaultLanguagePreference &&
                    defaultLanguagePreference.emarsys_value) ||
                '',
            telephone: '',
            dob: '',
            checkout_registration_secret_key: window.checkoutSecretKey,
            password: '',
            passwordConfirm: '',
            isValid: true,
            validationMessage: '',
            signUpNewsletter: false,
            isEmailValid: true,
            telephoneValidationMessage: ''
        };
    }

    handleChange = ({ target }) => {
        this.setState({
            ...this.state,
            [target.name]: target.value
        });
    };

    handleSignUpCheckbox = ({ target }) => {
        this.setState({
            ...this.state,
            signUpNewsletter: target.checked
        });
    };

    createAccount = (e) => {
        e.preventDefault();

        // Reset the isValid flag in case the user has just fixed validation issues
        this.setState({
            ...this.state,
            isValid: true
        });

        // Fetch request
        if (!this.validate()) {
            return;
        }

        // Grab all state except for the isValid, showPassword, passwordConfirm and validationMessage properties, and pass them to the handleContinue prop
        const {
            isValid, showPassword, passwordConfirm, validationMessage, ...personalDetails
        } = this.state;

        this.props.handleContinue(personalDetails);
    };

    guestCheckout = () => {
        // Necessary actions to hide login forms and display shipping details
        this.props.handleContinue();
    };

    /**
     * Simple validation that only checks if fields are set
     *
     * @returns {boolean}
     */
    validate = () => {
        if (!this.validateValuesExist()) {
            this.setState({
                ...this.state,
                isValid: false,
                // validationMessage: 'Please fill in all fields'
            });

            return false;
        }

        if (!this.validatePasswordsMatch()) {
            this.setState({
                ...this.state,
                isValid: false,
                // validationMessage: 'Please make sure passwords match'
            });

            return false;
        }

        return true;
    };

    /**
     * Validate that each of the required fields contain a value
     *
     * @returns {boolean}
     */
    validateValuesExist = () => {
        const {
            firstname,
            lastname,
            email,
            password
        } = this.state;

        return firstname !== ''
            && lastname !== ''
            && email !== ''
            && password !== '';
    };

    /**
     * Validate that the password and password confirmation values are the same
     *
     * @returns {boolean}
     */
    validatePasswordsMatch = () => {
        const {
            password,
            passwordConfirm
        } = this.state;

        return password === passwordConfirm;
    };

    /**
     * If any of the form fields aren't filled in when the validate function is run, display an error message to the
     * user
     *
     * @returns {null}
     */
    renderError = () => {
        return !this.state.isValid ? (
            <div className="sign-up-form__message validation-error">{this.state.validationMessage}</div>
        ) : null;
    };

    /**
     * Set the validation message
     *
     * This function is intended to be used "from the outside" of the component, through a ref
     *
     E.g.
     *   // In the constructor
     *   this.signUpFormRef = React.createRef();
     *   // ...
     *   // In some function/event handler
     *   signUpFormRef.setValidationmessage('Data is wrong');
     *   // ...
     *   // In the render function
     *   <SignUpForm ref={this.signUpFormRef} />
     *
     * @param validationMessage
     */
    setValidationMessage = (validationMessage) => {
        this.setState({
            ...this.state,
            isValid: false,
            validationMessage
        });
    };

    renderPasswordField() {
        return (
            <div className="passwords-container">
                <PasswordContainer
                    id="signup-password"
                    name="password"
                    label="Password"
                    value={this.state.password}
                    isValid={this.state.password !== "" ? passwordRegex.test(this.state.password) : this.state.isValid || passwordRegex.test(this.state.password)}
                    onChange={this.handleChange}
                    isRequired={true}
                    isGuestUserForm={false}
                    errorMessage="Please create a minimum 8 character password including upper case letters, lower case letters, a symbol and a number"
                />
                <PasswordContainer
                    id="signup-password-confirmation"
                    name="passwordConfirm"
                    label="Confirm password"
                    placeholder="Confirm password..."
                    value={this.state.passwordConfirm}
                    isValid={this.state.password !== "" ? this.validatePasswordsMatch() : this.state.isValid || this.validatePasswordsMatch()}
                    onChange={this.handleChange}
                    isRequired={true}
                    isGuestUserForm={false}
                    errorMessage="Passwords don’t match"
                />
            </div>
        );
    }

    renderCheckoutAsGuestButton() {
        // Explicitly check for false, meaning it will default to true
        return this.props.canCheckoutAsGuest !== false
            ? <button type="button" className="sign-up-form__button--guest" onClick={this.props.handleGuest}>Checkout as Guest</button>
            : null;
    }

    render() {
        /**
         * onBlur will call the validate function passed in as a prop, if included, otherwise it will call the default
         * validation function within this container
         */
        const { defaultCountryId, fn_lang_preference } =
            this.props.checkoutConfig;
        const birthdayDiscountContent = getSignUpContentFromCountry(defaultCountryId).BIRTHDAY_CONTENT;
        const newsletterContent = getSignUpContentFromCountry(defaultCountryId).NEWSLETTER_CONTENT;

        const { is_enabled: shouldShowLanguagePreferenceSelect, preference } =
            fn_lang_preference;
        const defaultLanguagePreference = this.state.language_preference;

        return (
            <form className="sign-up-form">
                {this.props.slotOne}

                {this.renderError()}

                <InputContainer
                    id="signup-firstname"
                    name="firstname"
                    label="First name"
                    onChange={this.handleChange}
                    value={this.state.firstname}
                    isValid={this.state.isValid || this.state.firstname !== ''}
                    errorMessage="This is a required field"
                    isRequired={true}
                    isGuestUserForm={false}
                />
                <InputContainer
                    id="signup-lastname"
                    name="lastname"
                    label="Last name"
                    onChange={this.handleChange}
                    value={this.state.lastname}
                    isValid={this.state.isValid || this.state.lastname !== ''}
                    errorMessage="This is a required field"
                    isRequired={true}
                    isGuestUserForm={false}
                />
                <InputContainer
                    id="signup-dob"
                    name="dob"
                    type="date"
                    label="Date of Birth (optional)"
                    onChange={this.handleChange}
                    value={this.state.dob}
                    // isValid={this.state.dob !== '' }
                    isRequired={false}
                    isGuestUserForm={false}
                    maxDate="0"
                />
                <div style={{fontSize: "11px",marginBottom: "-8px"}}>{birthdayDiscountContent}</div>
                <InputContainer
                    id="signup-telephone"
                    name="telephone"
                    label="Mobile number (optional)"
                    onChange={this.handleChange}
                    value={this.state.telephone}
                    isValid={this.state.telephone !== '' ? telephoneRegex.test(this.state.telephone) : true}
                    errorMessage="Please supply a valid phone number"
                    isRequired={false}
                    isGuestUserForm={false}
                    style={{marginTop: '25px'}}
                />
                {this.props.checkoutConfig.defaultCountryId !== 'CA' ? <div style={{fontSize: "11px",marginBottom: "-8px"}}>Provide your mobile number to receive mobile updates</div> : '' }
                <div className="validation-message" style={{color:'#FF4D4B'}}>{this.state.telephoneValidationMessage}</div>
                <EmailContainer
                    id="signup-email"
                    name="email"
                    type="email"
                    label="Email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    onBlur={this.props.onBlurEmail}
                    isValid={this.state.email !== '' ? isValidEmail(this.state.email): this.state.isValid || isValidEmail(this.state.email)}
                    style={{marginTop: '25px'}}
                    isRequired={true}
                    isGuestUserForm={false}
                />

                {shouldShowLanguagePreferenceSelect && (
                    <div className="select-container">
                        <label htmlFor="language_preference" className="input-preferred-language-label">
                            <span>
                                {__('Preferred language of communication')}
                                <em>*</em>
                            </span>
                        </label>
                        <div className="control select-wrapper">
                            <select
                                name="language_preference"
                                id="language_preference"
                                autoComplete="language"
                                title={__('Preferred language of communication')}
                                className="select-input"
                                onChange={this.handleChange}
                                defaultValue={defaultLanguagePreference}
                            >
                                {preference.map(
                                    ({ emarsys_value, language }) => (
                                        <option
                                            key={emarsys_value}
                                            value={emarsys_value}
                                        >
                                            {language}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                    </div>
                )}

                <PasswordContainer
                    id="signup-password"
                    name="password"
                    label="Password"
                    value={this.state.password}
                    isValid={this.state.password !== "" ? passwordRegex.test(this.state.password) : this.state.isValid || passwordRegex.test(this.state.password)}
                    onChange={this.handleChange}
                    isRequired={true}
                    isGuestUserForm={false}
                    errorMessage="Please create a minimum 8 character password including upper case letters, lower case letters, a symbol and a number"
                />
                <PasswordContainer
                    id="signup-password-confirmation"
                    name="passwordConfirm"
                    label="Confirm password"
                    placeholder="Confirm password"
                    value={this.state.passwordConfirm}
                    isValid={this.state.password !== "" ? this.validatePasswordsMatch() : this.state.isValid || this.validatePasswordsMatch()}
                    onChange={this.handleChange}
                    isRequired={true}
                    isGuestUserForm={false}
                    errorMessage="Passwords don’t match"

                />

                <input type="hidden"
                    className="form-input apiSecret"
                    id="checkout_registration_secret_key"
                    name="checkout_registration_secret_key"
                    value={this.state.checkout_registration_secret_key}
                    isValid={this.state.isValid || this.state.checkout_registration_secret_key !== ''}
                />

                {this.props.extraSignupFields}

                {/* {this.renderPasswordField()} */}

                <div className="newsletter-signup-container">
                    <input
                        id="sign-up-newsletter"
                        type="checkbox"
                        onChange={this.handleSignUpCheckbox}
                        checked={this.state.signUpNewsletter}
                        className="checkbox"
                    />
                    {featureFlags(defaultCountryId).SEPARATE_POLICY_CHECKBOX_ENABLED ?
                        <label htmlFor="sign-up-newsletter">{newsletterContent}</label> :
                        <label htmlFor="sign-up-newsletter">{this.props.newsletterLabel}</label>
                    }
                </div>

                {this.props.slotTwo}

                <div className="sign-up-form__actions">
                    <button type="submit" onClick={this.createAccount}  className="sign-up-form__button--continue">Continue</button>

                    {this.renderCheckoutAsGuestButton()}
                </div>
            </form>
        );
    }

}

SignUpForm.propTypes = {
    handleContinue: PropTypes.func.isRequired,
    canCheckoutAsGuest: PropTypes.bool,
    handleGuest: PropTypes.func,
    onBlurEmail: PropTypes.func,
    slotOne: PropTypes.element,
    slotTwo: PropTypes.element,
    extraSignupFields: PropTypes.element
};

SignUpForm.defaultProps = {
    slotOne: null,
    slotTwo: null,
    extraSignupFields: null
};

export default SignUpForm;
