/**
 * Process the address details into a format expected when submitting the checkout
 *
 * @param {Object} customer  The customer details object to process
 * @param {Object} address   The address object to process
 * @param {string} countryId 2 digit country ID
 *
 * @returns {Object}
 */
export default (customer, address, countryId) => {
    const {
        firstname,
        lastname,
        email,
    } = customer;
    const {
        street,
        street2 = '',
        suburb,
        state,
        postcode
    } = address;

    return {
        city: suburb,
        company: '',
        countryId,
        extensionAttributes: {
            // checkoutFields: {},
            atl_signature: 'false',
            atl_instructions: null
        },
        firstname: firstname.value,
        lastname: lastname.value,
        email: email.value,
        postcode: postcode,
        region: state,
        saveInAddressBook: null,
        street: [street, street2],
        telephone: ''
    };
};
