/**
 * ErrorContainer React Component
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorContainer extends Component {
    render() {
        return (
            <section className="error-container">
                The following problems have occured:&nbsp;
                {this.props.errors.map((error, index) => {
                    return (
                        <span className='error-messages'>{error.message}</span>
                    );
                })}
            </section>
        );
    }
}

ErrorContainer.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.shape({
        message: PropTypes.string.isRequired
    }))
};

export default ErrorContainer;
