import PropTypes from 'prop-types';

export default {
    street: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        errorMessage: PropTypes.string,
        valid: PropTypes.bool
    })),
    suburb: PropTypes.shape({
        value: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        errorMessage: PropTypes.string,
        valid: PropTypes.bool
    }),
    postcode: PropTypes.shape({
        value: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        errorMessage: PropTypes.string,
        valid: PropTypes.bool
    }),
    region: PropTypes.shape({
        value: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        errorMessage: PropTypes.string,
        valid: PropTypes.bool
    }),
    singleLine: PropTypes.shape({
        value: PropTypes.string.isRequired,
        placeholder: PropTypes.string
    }),
    atl_signature: PropTypes.bool.isRequired,
    atl_instructions: PropTypes.string.isRequired,
    atl_valid: PropTypes.bool.isRequired,
    invalidAddressError: PropTypes.string.isRequired
};
