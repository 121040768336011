/**
 * Delivery React Component
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import get from 'lodash.get';
import DeliveryTabbedUiContainer from '../../common/DeliveryTabbedUiContainer'
import DeliveryForm from './DeliveryForm';
import Storage from '../../Util/Storage';
import Account from './Account';
import ClickAndCollect from './ClickAndCollect';
import Shipping from './Shipping';
import HeaderTabs from './HeaderTabs';
import { customer, address } from '../../propTypes';
import { isMobileUi } from '../../Util/functions';

class Delivery extends Component {

    static get CONST__TAB_SHIPPING() { return 'shipping'; }
    static get CONST__TAB_CNC() { return 'cnc'; }

    constructor(props) {
        super(props);
        this.tabbedUIContainerRef = React.createRef();
        this.deliverySection = React.createRef();

        this.state = {
            visibleTab: 0, // Merely tracking which tab is active, not controlling it
            errorMessages: {
                billingAddress: '',
                shippingMethods: '',
                clickAndCollect: ''
            },
            regions: [],
            initialTabSwitch: false,
            findInStoreSet: false,
            isBackToLoginClick: false
        };
    }


    hydrateState() {
        Storage.getItem(`fn-checkout-delivery-state-${this.props.checkoutConfig.quoteId}`).then((storedState) => {
            if (storedState !== null) {
                this.setState({
                    ...this.state,
                    ...storedState
                }, () => {
                    if (!this.isShowingLoginSignup()) {
                        this.tabbedUIContainerRef.current.goToTab(storedState.visibleTab);
                    }
                });
            }
        });
    }

    componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling, true);
        this.hydrateState();
        this.updateRegions();
        // MS-3615 : Stop saved address validation on page load
        if (
            typeof this.props.checkoutConfig.customerData.addresses !== 'undefined' &&
            this.props.checkoutConfig.customerData.addresses.length > 0 &&
            typeof this.props.checkoutConfig.customerData.default_shipping !== 'undefined'
        ) {
            const selectedAddress = this.props.checkoutConfig.customerData.addresses
                .find(address => parseInt(address.id, 10) === parseInt(this.props.checkoutConfig.customerData.default_shipping, 10));
            //Retrict change for CA
            if(this.props.checkoutConfig.defaultCountryId == 'CA') {
                const region = get(selectedAddress, 'region.region', false) ? ` ${selectedAddress.region.region}` : ''; // NZ can be funny
                const oneLineAddress = `${selectedAddress.street.join(' ')}, ${selectedAddress.city}${region} ${selectedAddress.postcode}`;
                this.props.onSelectAddressBook(oneLineAddress);
            }
            else {
                this.handleSelectAutosuggest(selectedAddress);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling, true);
    }

    trackScrolling = () => {
        const wrappedElement = document.getElementById('delivery-login-section');
        if(isMobileUi() && wrappedElement.getBoundingClientRect().top < 400 && wrappedElement.getBoundingClientRect().top > 0){
            this.props.handleScrollActivateSection(HeaderTabs.CONST__DELIVERY_LINK);
            document.removeEventListener('scroll', this.trackScrolling);
        }
    };

    componentDidUpdate() {
        // Grab parts of state that should be persisted across checkout reloads, and save them to storage
        const stateToPersist = {
            visibleTab: this.state.visibleTab,
            errorMessages: {
                ...this.state.errorMessages
            }
        };

        Storage.setItem(`fn-checkout-delivery-state-${this.props.checkoutConfig.quoteId}`, JSON.stringify(stateToPersist));

        // user hits checkout for the first time from selecting CNC from PDP Find In Store
        // once user completes login, switch tabs if has a selection
        // single use method
        if (!this.isShowingLoginSignup() && !this.state.initialTabSwitch) {
            this.setState({
                initialTabSwitch: true
            }, () => {
                if (this.props.findInStore.selectedStore !== '') {
                    this.goToTab(1);
                }
            });
        }
    }

    /**
     * Hook into the componentDidMount lifecycle method of the DeliveryForm component
     *
     * @returns {null}
     */
    onDeliveryFormMount = () => {
        const phoneInput = document.querySelector('.personal-details__telephone-container input');

        if (this.props.checkoutConfig.quoteData.is_express && phoneInput) {
            phoneInput.focus();
        }
    };

    /**
     * Update state to set the findInStoreSet property
     * This property is used to determine if the user is entering the checkout for the first time after having kicked
     * off a click and collect from the PDP
     *
     * @param {Boolean} isSet The value to set findInStoreSet
     *
     * @returns {null}
     */
    updateFindInStoreSet = (isSet) => {
        this.setState({
            findInStoreSet: isSet
        });
    };

    /**
     * Load the countries that are available for shipping and update state
     *
     * @returns {null}
     */
    updateRegions = () => {
        const { defaultCountryId } = this.props.checkoutConfig;

        // Regions are used on the NZ site
        if (defaultCountryId === 'NZ') {
            return;
        }

        const regions = this.props.checkoutApp.components.checkoutProvider.dictionaries.region_id;
        const filtered = regions.filter(
            function (region) {
                region.value = region.label;
                return (region.country_id === defaultCountryId)
            }
        );

        this.setState({
            ...this.state,
            regions: [
                {
                    'value' : '',
                    'label' :   ''
                },
                ...filtered
            ]
        });
    };

    transformCompleteRequest = (response) => {
        if (!response) {
            // Request was cancelled and replaced by a new one
            return;
        } else if (response.data.success === false || response.data.data.length === 0) {
            return [{
                value: 'Unable to find matching address',
                selectable: false
            }];
        }

        return response.data.data.map(result => ({ value: result, selectable: true }));
    };

    getTabs = () => {
        const { activeCarriers, quoteData } = this.props.checkoutConfig;

        const shouldShowClickAndCollect = !quoteData.is_virtual && activeCarriers.includes('click_collect');

        return [
            {
                id: Delivery.CONST__TAB_SHIPPING,
                label: this.props.checkoutConfig.quoteData.is_virtual ? 'Billing' : 'Shipping',
                content: this.getShippingComponent()
            },
            ...shouldShowClickAndCollect && [
                {
                    id: Delivery.CONST__TAB_CNC,
                    label: 'Click and Collect',
                    content: this.getClickAndCollectComponent()
                }
            ]
        ];
    };

    handleBackToLogin = (e) => {
        e.preventDefault()
        this.setState({
            isBackToLoginClick: true
        })
        this.renderAccount();
        this.props.handleUserIsGuest();
        e.preventDefault();
    }

    /**
     * Select delivery tab
     *
     * @param {Number} tabId
     */
    goToTab = (tabId) => {
        if (this.isShowingLoginSignup()) return;
        this.tabbedUIContainerRef.current.goToTab(tabId);
    };

    /**
     * Validate fields after suggestion selected
     *
     * @param {String} address
     * @return {Promise<void>}
     */
    handleSelectAutosuggest = async (address) => {
        await this.props.handleSelectAutosuggest(address);
        this.validateFields();
    };

    /**
     * Get the DeliveyrForm component that is re-used in both the Shipping and Click and Collect tabs
     *
     * @returns {*}
     */
    getDeliveryComponent = (label) => {
        const { billingAddress: errorMessage } = this.state.errorMessages;

        let fullFormToggleInfo = '';
        let fullFormToggleLabel = '';

        if (this.props.allowFullAddressForm) {
            fullFormToggleInfo = this.props.displayOneLineAddress ? 'Can\'t find your address' : 'Want to save time?';
            fullFormToggleLabel = this.props.displayOneLineAddress ? 'Try our full form' : 'Use our address lookup for a faster checkout';
        }

        return (
            <Fragment>
                <DeliveryForm
                    id="shipping-search"
                    label={label}
                    handleSearch={this.props.handleSearch}
                    displayOneLineAddress={this.props.displayOneLineAddress}
                    fullFormToggleInfo={fullFormToggleInfo}
                    fullFormToggleLabel={fullFormToggleLabel}
                    onSelectAutosuggest={this.handleSelectAutosuggest}
                    onSelectAddressBook={this.props.onSelectAddressBook}
                    oneLineAddress={this.props.address.singleLine}
                    onManualAddressChange={this.props.handleManualAddressChange}
                    address={this.props.address}
                    regions={this.props.displayOneLineAddress ? this.state.regions : []}
                    transformCompleteRequest={this.transformCompleteRequest}
                    handleToggleFullForm={this.props.toggleFullForm}
                    minCharacters={3}
                    customerAddresses={this.props.checkoutConfig.customerData.addresses}
                    defaultAddresses={{
                        billing: this.props.checkoutConfig.customerData.default_billing,
                        shipping: this.props.checkoutConfig.customerData.default_shipping
                    }}
                    handleChangeAutoCompleteInput={this.props.handleChangeAutoCompleteInput}
                    onComponentDidMount={this.onDeliveryFormMount}
                    autoCompleteSuburb={this.props.autoCompleteSuburb}
                />
                {errorMessage !== '' && (
                    <div className="validation-message validation-error">{errorMessage}</div>
                )}
            </Fragment>
        );
    };

    getShippingComponent = () => {
        if (this.props.selectedShippingMethod !== ''
            && this.state.errorMessages.shippingMethods !== '') {
            this.setValidationMessage('shippingMethods', '');
        }

        return (
            <Shipping
                checkoutConfig={this.props.checkoutConfig}
                deliveryForm={this.getDeliveryComponent(this.props.checkoutConfig.quoteData.is_virtual ? 'Billing Address' : 'Delivery Address')}
                customer={this.props.customer}
                handleChangeCustomer={this.props.handleChangeCustomer}
                address={this.props.address}
                shippingMethods={this.props.shippingMethods}
                selectedShippingMethod={this.props.selectedShippingMethod}
                handleShippingMethodsChange={this.props.handleShippingMethodsChange}
                atlNotes={this.props.atlNotes}
                handleAuthorityToLeaveChange={this.props.handleAuthorityToLeaveChange}
                handleAuthorityToLeaveNotesChange={this.props.handleAuthorityToLeaveNotesChange}
                errorMessage={this.state.errorMessages.shippingMethods}
                validateCustomerDetails={this.props.validateCustomerDetails}
                handleOnBlur={this.props.handleOnBlur}
            />
        );
    };

    getClickAndCollectComponent = () => {
        return (
            <ClickAndCollect
                customer={this.props.customer}
                handleChangeCustomer={this.props.handleChangeCustomer}
                stockists={this.props.stockists}
                handleSearch={this.props.searchClickCollectStores}
                onSelectStore={this.props.handleSelectClickCollectStore}
                deliveryForm={this.getDeliveryComponent('Billing Address')}
                errorMessage={this.state.errorMessages.clickAndCollect}
                findInStore={this.props.findInStore}
                findInStoreSet={this.state.findInStoreSet}
                updateFindInStoreSet={this.updateFindInStoreSet}
                errorSelectedClickCollectStore={this.props.errorSelectedClickCollectStore}
                handleOnBlur={this.props.handleOnBlur}
                cart={this.props.cart}
            />
        );
    };

    /**
     * Return true/false indicating which part of the UI should be visible
     *
     * @returns {boolean}
     */
    isShowingLoginSignup = () => {
        if(RegExp("Forever New").test(navigator.userAgent) || RegExp("ForeverNew").test(navigator.userAgent) && !this.props.isLoggedIn) {
            return false;
        } else {
            return !this.props.isLoggedIn && !this.props.isGuest;
        }
    };

    /**
     * Check that all of the fields required for shipping have been filled in and a shipping method has been selected
     *
     * @returns {boolean}
     */
    validateFields = async () => {
        await this.props.increasePendingRequestsCount();
        try {
            const validAddress = await this.props.validateAddressDetails();
            const validCustomer = await this.props.validateCustomerDetails('address');

            await this.props.decreasePendingRequestsCount();
            this.setValidationMessage('billingAddress', validAddress ? '' : 'Please complete shipping/billing details');

            return validAddress && validCustomer;
        } catch (error) {
            await this.props.decreasePendingRequestsCount();
        }
    };

    /**
     * Use the afterActivate function of TabbedUiContainer to keep track of which tab is currently visible
     *
     * @param {int} visibleTab The ID of the visible tab
     *
     * @returns {null}
     */
    handleAfterActivate = (visibleTab) => {
        this.props.clearGlobalErrorMessages();

        this.setState({
            ...this.state,
            visibleTab
        }, async () => {
            const visibleTabId = this.getTabs()[visibleTab].id;
            this.props.trackVisibleDeliveryTab(visibleTabId);

            if (visibleTabId === 'shipping') {
                this.props.removeClickAndCollectStore();
                await this.props.clearSelectedShippingMethod();
                this.props.fetchShippingMethods();
            }
        });
    };

    /**
     * Check that all the required fields are filled in, and then tell the application
     * to move to the next section of the checkout
     */
    handleNextSection = async () => {
        // Remove any previous validation messages
        await this.setValidationMessage('billingAddress', '');
        await this.setValidationMessage('shippingMethods', '');
        await this.setValidationMessage('clickAndCollect', '');

        const activeTab = this.getTabs().find((tab, index) => index === this.state.visibleTab);
        const valid = await this.validateFields();

        if (activeTab.id === 'shipping') {
            const shippingSelected = this.props.validateShippingMethod();
            if (!valid || (!shippingSelected && this.props.shippingMethods.length > 0)) {
                // Update validation message to either be blank, if shipping method is selected, or show an error message if not selected
                await this.setValidationMessage('shippingMethods', shippingSelected ? '' : 'Please select a shipping method');
                this.props.scrollToFirstError();

                return;
            }
        }

        if (activeTab.id === 'cnc') {
            const clickCollectSelected = this.props.validateClickCollectStoreSelected();
            if (!clickCollectSelected) {
                await this.setValidationMessage('clickAndCollect', 'Please select a store to collect');
                this.props.scrollToFirstError();

                return;
            }
        }

        this.props.handleActivateSection(HeaderTabs.CONST__PAYMENT_LINK);
    };

    /**
     * Set a validation error message
     * Returns a promise so the state can be relied upon having changed before continuing on in the context that this
     * function is called
     *
     * @param {string} property The property that the error message is being set for
     * @param {string} message  The message to set
     *
     * @returns {Promise}
     */
    setValidationMessage = (property, message) => {
        return new Promise((res) => {
            this.setState({
                ...this.state,
                errorMessages: {
                    ...this.state.errorMessages,
                    [property]: message
                }
            }, () => {
                res();
            });
        });
    };

    renderAccount() {
        const {isBackToLoginClick} = this.state;
        if(isBackToLoginClick === false) {
            if (!this.isShowingLoginSignup()) {
                return null;
            }
        }

        return (
            <Account
                checkoutConfig={this.props.checkoutConfig}
                checkoutApp={this.props.checkoutApp}
                activeSection={this.props.activeSection}
                handleUserLoggedIn={this.props.handleUserLoggedIn}
                handleGuestCheckout={this.props.handleGuestCheckout}
                increasePendingRequestsCount={this.props.increasePendingRequestsCount}
                decreasePendingRequestsCount={this.props.decreasePendingRequestsCount}
            />
        );
    }

    renderDelivery() {
        if (this.isShowingLoginSignup()) {
            return null;
        }
        if(this.state.isBackToLoginClick === true) {
            this.setState({
                isBackToLoginClick: false
            })
        }

        return (
            <Fragment>
                <div className="tab-help-text">
                    {this.props.checkoutConfig.activeCarriers.includes('click_collect')
                    ? 'Choose your delivery method'
                    : ''}
                </div>

                <DeliveryTabbedUiContainer
                    ref={this.tabbedUIContainerRef}
                    tabs={this.getTabs()}
                    afterActivate={this.handleAfterActivate}
                    handleBackToLogin={this.handleBackToLogin}
                    isLoggedIn={this.props.isLoggedIn}
                />

                {this.props.checkoutConfig.paymentMethods.length === 0 ? <div className="delivery__button-container">
                    <button className="delivery__button--continue" onClick={this.handleNextSection}>Continue</button>
                </div> : null }

            </Fragment>
        );
    }

    render() {
        const classname = classnames(
            'checkout-section',
            'delivery-login-section',
            { 'section-active': this.props.activeSection === HeaderTabs.CONST__DELIVERY_LINK || this.props.activeSection === HeaderTabs.CONST__ACCOUNT }
        );

        return (
            <section className={classname} id="delivery-login-section" ref={this.deliverySection}>
                {this.renderAccount()}
                {this.renderDelivery()}
            </section>
        );
    }

}

Delivery.propTypes = {
    checkoutConfig: PropTypes.object.isRequired,
    checkoutApp: PropTypes.object.isRequired,
    activeSection: PropTypes.number.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isGuest: PropTypes.bool.isRequired,
    handleUserLoggedIn: PropTypes.func.isRequired,
    handleGuestCheckout: PropTypes.func.isRequired,
    handleChangeCustomer: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    handleManualAddressChange: PropTypes.func.isRequired,
    handleSelectAutosuggest: PropTypes.func.isRequired,
    onSelectAddressBook: PropTypes.func.isRequired,
    customer: PropTypes.shape(customer),
    address: PropTypes.shape(address),
    displayOneLineAddress: PropTypes.bool.isRequired,
    allowFullAddressForm: PropTypes.bool.isRequired,
    toggleFullForm: PropTypes.func.isRequired,
    shippingMethods: PropTypes.array.isRequired,
    selectedShippingMethod: PropTypes.string.isRequired,
    selectedClickCollectStore: PropTypes.string.isRequired,
    handleShippingMethodsChange: PropTypes.func.isRequired,
    stockists: PropTypes.array.isRequired,
    searchClickCollectStores: PropTypes.func.isRequired,
    handleSelectClickCollectStore: PropTypes.func.isRequired,
    atlNotes: PropTypes.string.isRequired,
    handleAuthorityToLeaveChange: PropTypes.func.isRequired,
    handleAuthorityToLeaveNotesChange: PropTypes.func.isRequired,
    handleActivateSection: PropTypes.func.isRequired,
    validateAddressDetails: PropTypes.func.isRequired,
    validateCustomerDetails: PropTypes.func.isRequired,
    validateShippingMethod: PropTypes.func.isRequired,
    validateClickCollectStoreSelected: PropTypes.func.isRequired,
    fetchShippingMethods: PropTypes.func.isRequired,
    clearGlobalErrorMessages: PropTypes.func.isRequired,
    handleChangeAutoCompleteInput: PropTypes.func.isRequired,
    trackVisibleDeliveryTab: PropTypes.func.isRequired,
    increasePendingRequestsCount: PropTypes.func.isRequired,
    decreasePendingRequestsCount: PropTypes.func.isRequired,
    scrollToFirstError: PropTypes.func.isRequired,
    clearSelectedShippingMethod: PropTypes.func.isRequired,
    findInStore: PropTypes.shape({
        selectedStore: PropTypes.string,
        location: PropTypes.string
    }),
    errorSelectedClickCollectStore: PropTypes.string,
    removeClickAndCollectStore: PropTypes.func.isRequired,
    autoCompleteSuburb: PropTypes.shape({
        show: PropTypes.bool,
        handleSearch: PropTypes.func,
        handleSuggestionClick: PropTypes.func,
        transformCompleteRequest: PropTypes.func
    })
};

export default Delivery;
