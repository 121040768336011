/**
 * ZipPayContainer React Component
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import scriptLoader from 'react-async-script-loader';
import AJAX from '../../Util/AJAX';

class ZipMoneyContainer extends Component {

    state = {
        scriptLoaded: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.isScriptLoaded || !nextProps.isScriptLoadSucceed) {
            return null;
        }

        return {
            ...prevState,
            scriptLoaded: true
        };
    }

    submit = async () => {
        const isValid = await this.props.validate();

        if (!isValid) {
            return;
        }
        this.props.clearGlobalErrorMessages();

        // Wait for checkout to tell Magento that the user is paying with ZipPay
        if (this.props.handleAction) {
            await this.props.handleAction();
        }

        Zip.Checkout.init({
            redirect: this.props.config.inContextCheckoutEnabled ? 0 : 1,
            checkoutUri: this.props.config.checkoutUri,
            redirectUri: this.props.config.redirectUri,
            onComplete: this.onComplete,
            onError: this.onError,
            onCheckout: this.onCheckout
        });
    };

    onComplete = (response) => {
        if (response.state === 'approved' || response.state === 'referred') {
            location.href = `${this.props.config.redirectUri}?result=${response.state}&checkoutId=${response.checkoutId}`;
            return;
        }

        this.props.addGlobalErrorMessage({ message: 'Unable to complete ZipPay order' });
    };

    onError = (response) => {
        this.props.addGlobalErrorMessage({ message: 'Unable to complete ZipPay order' });
    };

    onCheckout = async (resolve, reject, args) => {
        try {
            const { data } = await AJAX.get(this.props.config.checkoutUri);

            resolve({
                data: {
                    redirect_uri: data.redirect_uri
                }
            });
        } catch (response) {
            reject();
        }
    };

    render() {
        if (!this.state.scriptLoaded || !this.props.config.inContextCheckoutEnabled) {
            return null;
        }

        return (
            <div className="zippay-container">
                <button type="button" className="zippay-container__button" onClick={this.submit}>
                    Pay with ZipPay
                </button>
            </div>
        );
    }

}

ZipMoneyContainer.propTypes = {
    config: PropTypes.object.isRequired,
    addGlobalErrorMessage: PropTypes.func.isRequired,
    clearGlobalErrorMessages: PropTypes.func.isRequired,
    handleAction: PropTypes.func,
    validate: PropTypes.func
};

ZipMoneyContainer.defaultProps = {
    validate: () => true // Function that will return true, if the validate function isn't provided
};

export default scriptLoader('//static.zipmoney.com.au/checkout/checkout-v1.min.js')(ZipMoneyContainer);
