import AJAX from './AJAX';

export class Translator {
    constructor() {
        this.dictionary = {};
    }

    // CM-382: Magento does not regenerate the js-translations.json file from i18n correctly
    // reactDefaultTranslations are manually added translations, these are overridden by generated file
    async init(translationFileUrl, reactDefaultTranslations) {
        return AJAX.get(translationFileUrl)
            .then(({ data }) => {
                this.dictionary = data;
            })
            .then(() => AJAX.get(reactDefaultTranslations))
            .then(({ data }) => {
                this.dictionary = { ...data, ...this.dictionary };
            });
    }

    translate(term) {
        if (term in this.dictionary) {
            return this.dictionary[term];
        }
        return term;
    }
}

const translator = new Translator();

export const __ = term => translator.translate(term);

export default translator;
