/**
 * HeaderTabs React Component
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class HeaderTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSection: props.activeSection,
            selectedShippingMethod: props.selectedShippingMethod
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps);
    }
    static get CONST__BAG_LINK() { return 1; }
    static get CONST__DELIVERY_LINK() { return 2; }
    static get CONST__PAYMENT_LINK() { return 3; }
    static get CONST__ACCOUNT() { return 4; }

    /**
     * Determine if a user has completed a section
     *
     * @param {int} section Integer that corresponds to one of the constants defined in this file
     * @returns {boolean}
     */
    isSectionCompleted(section) {
        switch (section) {
            case HeaderTabs.CONST__PAYMENT_LINK:
                // The payment tab will get "bold" styling from being the active one, not by being completed
                return false;

            case HeaderTabs.CONST__DELIVERY_LINK:
                return this.state.selectedShippingMethod !== "";

            case HeaderTabs.CONST__BAG_LINK:
                return true;

            default: // The App.CONST__BAG_LINK scenario will hit this, and that is always highlighted
                return true;
        }
    }

    /**
     * Determine which "section" the user is up to in the checkout process
     *
     * @param {int} section Integer that corresponds to one of the constants defined in this file
     *
     * @returns {boolean}
     */
    isSectionActive(section) {
        switch (section) {
            case HeaderTabs.CONST__PAYMENT_LINK:
                return this.state.activeSection === HeaderTabs.CONST__PAYMENT_LINK;

            case HeaderTabs.CONST__DELIVERY_LINK:
                return this.state.activeSection === HeaderTabs.CONST__DELIVERY_LINK;

            case HeaderTabs.CONST__BAG_LINK:
                return this.state.activeSection === HeaderTabs.CONST__BAG_LINK;

            default:
                return false;
        }
    }

    render() {
        const baseLinkClassname = 'header-tab';
        const bagLinkClassnames = classnames(
            baseLinkClassname,
            { completed: this.isSectionCompleted(HeaderTabs.CONST__BAG_LINK) },
            { active: this.isSectionActive(HeaderTabs.CONST__BAG_LINK) }
        );
        const deliveryLinkClassnames = classnames(
            baseLinkClassname,
            { completed: this.isSectionCompleted(HeaderTabs.CONST__DELIVERY_LINK) },
            { active: this.isSectionActive(HeaderTabs.CONST__DELIVERY_LINK) }
        );
        const paymentLinkClassnames = classnames(
            baseLinkClassname,
            { completed: this.isSectionCompleted(HeaderTabs.CONST__PAYMENT_LINK) },
            { active: this.isSectionActive(HeaderTabs.CONST__PAYMENT_LINK) }
        );

        return (
            <ul className="header-tabs">
                <li className={bagLinkClassnames}>
                    <button className="header-tab__button" onClick={() => { this.props.handleChangeActiveSection(HeaderTabs.CONST__BAG_LINK); }}>Bag</button>
                </li>
                <li className={deliveryLinkClassnames}>
                    <button className="header-tab__button" onClick={() => { this.props.handleChangeActiveSection(HeaderTabs.CONST__DELIVERY_LINK); }}>Delivery</button>
                </li>
                <li className={paymentLinkClassnames}>
                    <button className="header-tab__button" onClick={() => { this.props.handleChangeActiveSection(HeaderTabs.CONST__PAYMENT_LINK); }}>Payment</button>
                </li>
            </ul>
        );
    }
}

HeaderTabs.propTypes = {
    activeSection: PropTypes.number.isRequired,
    handleChangeActiveSection: PropTypes.func.isRequired
};

export default HeaderTabs;
