/**
 * TabbedUi React Component
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';


class TabbedUiContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visibleTab: props.visibleTab
        };

        this.handleTabChange = this.handleTabChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.visibleTab !== prevProps.visibleTab) {
            this.setState({
                visibleTab: this.props.visibleTab
            });
        }
    }

    /**
     * Set the visible tab of the component
     *
     * This function is intended to be used outside of TabbedUiContainer, through a ref.
     *
     * E.g.
     *   // In the constructor
     *   this.tabbedUiRef = React.createRef();
     *   // ...
     *   // In some function/event handler
     *   tabbedUiRef.goToTab(1);
     *   // ...
     *   // In the render function
     *   <TabbedUiContainer ref={this.tabbedUiRef} />
     *
     * @param {Number} tabId
     */
    goToTab = async (tabId) => {
        const { visibleTab } = this.state;
        const { beforeActivate, beforeActivateAsync, afterActivate, afterActivateAsync } = this.props;
        if (tabId === visibleTab) return;

        if (beforeActivate) {
            this.beforeActivate(tabId, visibleTab);
        }

        if (beforeActivateAsync) {
            await this.beforeActivateAsync(tabId, visibleTab);
        }

        this.setState({
            ...this.state,
            visibleTab: tabId
        }, async () => {
            if (afterActivate) {
                this.afterActivate(tabId);
            }

            if (afterActivateAsync) {
                await this.afterActivateAsync(tabId);
            }
        });
    };

    /**
     * Synchrounous function that will be called before the tab is changed
     *
     * @param {int} nextTab The tab being changed to
     * @param {int} prevTab The tab being changed from
     */
    beforeActivate = (nextTab, prevTab) => {
        this.props.beforeActivate(nextTab, prevTab);
    };

    /**
     * Asynchrounous function that will be called before the tab is changed
     *
     * @param {int} nextTab The tab being changed to
     * @param {int} prevTab The tab being changed from
     *
     * @returns {Promise}
     */
    beforeActivateAsync = (nextTab, prevTab) => {
        return this.props.beforeActivateAsync(nextTab, prevTab);
    };

    /**
     * Synchrounous function that will be called after the tab is changed
     *
     * @param newTab
     *
     * @returns {null}
     */
    afterActivate = (newTab) => {
        this.props.afterActivate(newTab);
    };

    /**
     * Asynchrounous function that will be called before the tab is changed
     *
     * @param newTab
     *
     * @returns {Promise}
     */
    afterActivateAsync = (newTab) => {
        return this.props.afterActivateAsync(newTab);
    };

    /**
     * Handle the event fired when a tab is clicked
     *
     * @param {object} e The event object
     *
     * @returns {null}
     */
    handleTabChange = async (e) => {
        const key = parseInt(e.currentTarget.dataset.key, 10);
        this.goToTab(key);
    };

    /**
     * Get the tabs passed into the component
     *
     * @returns {node}
     */
    getTabs() {
        return (
            <ul className="tabbed-ui__tabs">
                {this.props.tabs.length > 0 ?
                    this.props.tabs.map((tab, index) => this.getTab(tab, index)) :
                    this.props.noTabsMessage == undefined ? '' :
                    this.getTab({ label: this.props.noTabsMessage || '' }, 0) } {/* Prevents component breaking when tabs array is empty */}
            </ul>
        );
    }

    /**
     * Build tab list item
     *
     * @param {{
     *   iconUrl: string,
     *   label: string
     * }}           tabData The tab data being parsed into list item
     * @param {int} index   Array index for the tab data
     *
     * @returns {*}
     */
    /* MS-2694 - Active class added for tab payment method when gift card applied*/
    getTab({ code, iconUrl, label }, index) {
        const tabContent = (iconUrl) ? <img src={iconUrl} /> : label;
        const classNames = classnames('tabbed-ui__tab','payment_methods__tab', `tabbed-ui__tab--${code}`, { active: this.state.visibleTab === index || code == "free"});

        return (
            <li key={index}
                data-key={index}
                onClick={this.handleTabChange}
                className={classNames}
            >
                {tabContent}
            </li>
        );
    }

    /**
     * Get the tab data for the currently active tab
     *
     * @returns {{ content: node, buttonLabel: string|boolean }}
     */
    /* MS-2694 - Show Pay Now button when gift card applied with active payment method tab*/
    getActiveTabData() {
        let tab = undefined;
        tab = this.props.tabs.filter((tab, index) => tab.code === "free")[0];
        if (tab == undefined) {
            tab = this.props.tabs.filter((tab, index) => this.state.visibleTab === index)[0];
        }
        return tab || {
            content: '',
            buttonLabel: false
        };
    }

    /**
     * Get the content for the currently active tab
     *
     * @returns {node}
     */
    getActiveTabContent() {
        const { content } = this.getActiveTabData();

        return content;
    }

    /**
     * Get the action button for the active tab
     *
     * @returns {node|null}
     */
    getActiveTabAction() {
        const { buttonLabel, button: customButton } = this.getActiveTabData();
        const { payNowEnabled: disabled } = this.props;
        const button = buttonLabel ?
            <button type="button" className="tabbed-ui__action-button" onClick={this.props.handleAction} disabled={!disabled}>{buttonLabel}</button> :
            customButton; // The button passed in as a prop will need to have its own onClick and classname etc.

        return buttonLabel || customButton ?
            (
                <footer className="tabbed-ui__actions">
                    {button}
                </footer>
            ) :
            null;
    }

    render() {
        return (
            <section className="tabbed-ui">
                <header className="tabbed-ui__tabs-header"  style={{marginBottom : this.props.noTabsMessage == undefined ? '0' : '40px'}}>
                    {this.getTabs()}
                </header>
                <div className="tabbed-ui__content">
                    {this.getActiveTabContent()}
                    {this.props.children}
                </div>

                {this.getActiveTabAction()}
            </section>
        );
    }

}

TabbedUiContainer.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.element.isRequired
        ]),
        content: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.string
        ]),
        buttonLabel: PropTypes.string,
        button: PropTypes.element
    })),
    handleAction: PropTypes.func,
    noTabsMessage: PropTypes.string,
    children: PropTypes.element,
    beforeActivate: PropTypes.func,
    afterActivate: PropTypes.func,
    beforeActivateAsync: PropTypes.func,
    afterActivateAsync: PropTypes.func,
    visibleTab: PropTypes.number
};

TabbedUiContainer.defaultProps = {
    visibleTab: 0
}

export default TabbedUiContainer;
