/**
 * Validate that a provided value looks like a phone number
 * The only validation required is that the number is at least 1 number long, and only number
 * are included
 *
 * @param {string} number The number to validate
 *
 * @returns {bool}
 */
export default (number) => {
    // check if it only contains digits
    const isNumber = /^\d+$/.test(number);

    // check if its length not greater than 15
    const maxLength = number.length <= 15;

    const isValid = isNumber && maxLength;

    return isValid;
};
