import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputSlider from 'react-input-slider';


class ReactInputSlider extends Component {
    render() {
        return <InputSlider {...this.props} />
    };
}

ReactInputSlider.propTypes = {
    axis: PropTypes.string.isRequired,
    xstep: PropTypes.number.isRequired,
    xmin: PropTypes.number.isRequired,
    xmax: PropTypes.number.isRequired,
    x: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default ReactInputSlider;
