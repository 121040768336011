import React from 'react';
import ReactDOM from 'react-dom';
import 'idempotent-babel-polyfill';
import translator from '../Util/Translator';
import App from 'StoreLocator/App';

const storeLocatorContainer = document.getElementById('react-storelocator');

if (storeLocatorContainer) {
    translator.init(`${storeLocatorConfiguration.viewFileUrl}/js-translation.json`, `${storeLocatorConfiguration.viewFileUrl}/react-default-translations/translation.json`).then(() => {
        ReactDOM.render(<App configuration={storeLocatorConfiguration} />, storeLocatorContainer);
    });
}
