/**
 * Get the selected configurable option for a product in the cart
 *
 * @param {object} product The product to get the configurable option for
 * @param {string} option  The configurable to load
 *
 * @returns {Object|false}
 */
export default(product, option) => {
    if (!product.configurable_attributes) {
        return false;
    }
    const optionId = Object.keys(product.configurable_attributes).find(optId => product.configurable_attributes[optId].attribute_code === option);
    if (!optionId) {
        return false;
    }
    // attr.option_id is an integer, whereas option is a string. Casting both to int just to be safe
    return product.used_attributes.find(attr => parseInt(attr.option_id, 10) === parseInt(optionId, 10));
}
