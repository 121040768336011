import React, { Fragment } from 'react';

import formatPrice from './format-price';

/**
 * Take an array of shipping methods and return a formatted array that will plug straight into the
 * ShippingMethodsContainer component
 *
 * @param {array}  shippingMethods      Array of shipping methods
 * @param {func}   clickCollectFunction Handling function for click and collect shipping option
 * @param {object} options              Extra options for use in the function
 *
 * @returns {array}
 *  Returns empty array if provided shipping options array is empty, otherwise returns formatted shipping options,
 *  with click and collect option added in
 */
export default (shippingMethods, clickCollectFunction, options) => {
    const { activeCarriers, pattern, defaultCountryId } = options;

    if (shippingMethods.length === 0) {
        return [];
    }
    // Format the returned shipping methods to match the data format expected by ShippingMethodsContainer component
    const formattedShippingMethods = shippingMethods.filter(method => method.method_code !== 'click_collect').map((method) => {
        return {
            id: method.method_code,
            carrier: method.carrier_code,
            label: method.method_title,
            description: defaultCountryId === 'NZ' ? '1-3 business days' : '',
            price: method.amount === 0 ? 'FREE' : formatPrice(method.amount, pattern, true)
        };
    });

    const clickAndCollectMethod = {
        id: 'click_collect',
        carrier: 'click_collect',
        label: 'Click and Collect',
        description: (
            <Fragment>
                <p>Ready in 1-3 business days</p>
                <button
                    className="click-collect__button--select-store"
                    onClick={() => { clickCollectFunction('click_collect'); }}
                >Select Store</button>
            </Fragment>
        ),
        price: 'FREE'
    };

    const shippingMethodsWithClickAndCollect = [
        ...formattedShippingMethods,
        ...(activeCarriers.includes('click_collect') && [clickAndCollectMethod])
        ];

    return shippingMethodsWithClickAndCollect;
};
