/**
 * ItemSummary React Component
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { deepEqual } from 'fast-equals';
import classnames from 'classnames';
import get from 'lodash.get';
import FreeShippingIndicatorContainer from 'aligentreact/build/containers/FreeShippingIndicatorContainer/FreeShippingIndicatorContainer';
import ItemSummaryContainer from 'aligentreact/build/containers/ItemSummaryContainer/ItemSummaryContainer';
import PriceDisplayContainer from 'aligentreact/build/containers/PriceDisplayContainer/PriceDisplayContainer';
import { formatPrice, isMobileUi, getProductOption } from '../../Util/functions';
import HeaderTabs from './HeaderTabs';
import PaypalExpressContainer from './PaypalExpressContainer';
import Storage from '../../Util/Storage';
import DinkusContainer from "./DinkusContainer";

class ItemSummary extends Component {

    constructor(props) {
        super(props);
        this.itemsRef = React.createRef();
        this.subtotalRef = React.createRef();
        this.continueButtonRef = React.createRef();
        this.itemSummaryContainer = React.createRef();
        this.sitePath = (window.BASE_URL && window.BASE_URL!=='') ? window.BASE_URL : '';
        if (this.sitePath.length>0 && this.sitePath.charAt(this.sitePath.length-1)=='/') {
            this.sitePath = this.sitePath.substr(0, this.sitePath.length-1);
        }

        this.state = {
            items: [],
            unavailableClickCollectItems: []
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // If the items haven't changed, then don't update state, so the component doesn't re-render
        if (deepEqual(nextProps.checkoutConfig.quoteItemData, prevState.items)) {
            return {};
        }

        return {
            ...prevState,
            items: nextProps.checkoutConfig.quoteItemData
        };
    }

    componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling, true);
        // Figure out ad set the maximum height that the item summary container can be such that the subtotal and paypal
        // button will always be visible on desktop. This will not be run on tablet/mobile
        // if (window.innerWidth > 768) {
        //     const itemsMaxHeight = this.calculateItemsMaxHeight();
        //     this.itemsRef.current.style.maxHeight = `${itemsMaxHeight}px`;
        // }
    }
    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling, true);
    }

    trackScrolling = () => {
        const wrappedElement = document.getElementById('item-summary-container');
        if(isMobileUi() && wrappedElement.getBoundingClientRect().top < 400 && wrappedElement.getBoundingClientRect().top > 0){
            this.props.handleScrollActivateSection(HeaderTabs.CONST__BAG_LINK);
            document.removeEventListener('scroll', this.trackScrolling);
        }
    };

    /**
     * Calculate the maximum height of the items container.
     * This will ensure that, on desktop, the order subtotal and the paypal express button will both be visible at all times
     *
     * @return {int}
     */
    calculateItemsMaxHeight = () => {
        const continueButtonHeight = isMobileUi() ? this.continueButtonRef.current.getBoundingClientRect().height : 0;
        return (
            window.innerHeight
            - this.itemsRef.current.getBoundingClientRect().top
            - this.subtotalRef.current.getBoundingClientRect().height
            - continueButtonHeight
        );
    };

    /**
     * Handle the event fired when an item is removed from the cart
     * @param target
     */
    handleRemoveItem = ({ target }) => {

        const itemId = parseInt(target.dataset.itemId, 10);

        this.props.handleRemoveItem(itemId);
    };

    /**
     * Format the price remaining for free shipping
     *
     * @param {int} amount The amount remaining
     * @returns {*}
     */
    formatFreeShippingRemainingAmount = (amount) => {
        return formatPrice(amount, this.props.checkoutConfig.basePriceFormat.pattern);
    };

    /**
     * Get other available configurable options for a given products configurable
     *
     * @param {object} product The product to get all configurables for
     * @param {string} option  The configurable to load
     *
     * @returns {array}
     */
    getAvailableConfigurables(product, option) {
        if (!product.configurable_attributes) {
            return false;
        }

        const configurables = Object.values(product.configurable_attributes);
        const configurable = configurables.find(config => config.attribute_code === option);

        if (!configurable) {
            return false;
        }

        const options = Object.values(configurable.options);
        return options.map((opt) => {
            return {
                value: opt.option_title,
                label: opt.option_title,
                disabled: !opt.is_in_stock
            };
        });
    }

    /**
     * Get the regular and sale price, if any, of the provided item
     *
     * @param {Object} item The item to get the prices for
     *
     * @returns {Object}
     */
    getProductPrices(item) {
        const {
            original_price: original,
            base_price_incl_tax: baseInclTax,
            base_price: baseExclTax
        } = item;

        const {
            shopping_cart_display_settings: { price },
            basePriceFormat: { pattern }
        } = this.props.checkoutConfig;

        // 1 = exclude tax, 2 = include tax, 3 = both
        const basePrice = price === '2' ? baseInclTax : baseExclTax;
        const regularPrice = original || basePrice;
        const salePrice = original && basePrice; // original_price is only set on products on special

        return {
            regularPrice: regularPrice && formatPrice(regularPrice, pattern),
            salePrice: salePrice && formatPrice(salePrice, pattern)
        };
    }

    /**
     * Check if an item is available for click and collect
     *
     * @param {string} sku Product SKU to check for click and collect availability
     * @param {number} qty Product quantity to check for click and collect availability
     *
     * @returns {Boolean}
     */
    isItemAvailableForClickCollect = (sku, qty) => {
        if (this.props.selectedClickCollectStore === '') {
            return true; // No error message required
        }

        // Find the selected stockists
        const selectedStockist = this.props.stockists.find(stockist => stockist.id === this.props.selectedClickCollectStore);

        // If no store is selected, then can't check stock data
        if (!selectedStockist || !selectedStockist.product_stock) {
            return true;
        }

        // Return if the stock level for the provided sku is greater than 0, indicating if the product is available for click and collect
        const productStock = selectedStockist.product_stock.find(product => product.product_id.toString() === sku.toString());
        if (!productStock) {
            // This shouldn't really ever be hit, but as our local environment don't have correct data, this will appear
            // while developing, and cause the checkout to crash
            return true;
        }

        return productStock.stock_level >= qty;
    };

    getItemSummaries() {
        return this.state.items.map((item, index) => {
            const itemId = item.item_id;
            const itemColour = getProductOption(item, 'fashion_colour');
            const itemSize = getProductOption(item, 'size');
            const availableSizeConfigurables = this.getAvailableConfigurables(item, 'size');
            const { regularPrice, salePrice } = this.getProductPrices(item);
            const regularPriceValue = regularPrice.replace(/[^\d.-]/g, '');
            var salePriceValue = null;
            if (salePrice) {
                salePriceValue = salePrice.replace(/[^\d.-]/g, '');
            }

            const itemData = {
                uri: this.sitePath + `/${item.product.url_key}`,
                sku: item.sku,
                name: item.name,
                rule_dinkus: item.rule_dinkus,
                rule_dinkus_color: item.rule_dinkus_color,
                price: regularPrice,
                priceSale: (salePrice && (parseFloat(salePriceValue) < parseFloat(regularPriceValue))) ? salePrice : null,
                errorMessage: !this.isItemAvailableForClickCollect(item.sku, item.qty) ? 'Item not available for Click and Collect' : '',
                image: {
                    src: item.thumbnail,
                    alt: item.name
                },
                labelOnly: true,
                configurables: {
                    // Conditionally add the colour configurable, only if some colours exist
                    // This is a process called short-circuit evaluation
                    ...itemColour && itemColour.value && {
                        fashionColour: {
                            id: 'fashion_colour',
                            label: 'Colour',
                            selection: itemColour.value,
                            options: [
                                {
                                    label: itemColour.value.toString(),
                                    value: itemColour.value
                                }
                            ]
                        }
                    },
                    // Conditionally add the size configurable, only if some sizes exist
                    ...itemSize && itemSize.value && {
                        size: {
                            id: 'size',
                            label: 'Size',
                            selection: itemSize.value,
                            options: availableSizeConfigurables || [
                                {
                                    label: itemSize.value.toString(),
                                    value: itemSize.value
                                }
                            ]
                        }
                    },
                    qty: {
                        id: 'qty',
                        label: 'Qty',
                        selection: item.qty,
                        labelOnly: item.is_virtual === '1',
                        options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(qty => ({ label: qty.toString(), value: qty }))
                    }
                }
            };
            return (
                <React.Fragment key={index}>
                    <DinkusContainer
                        item={itemData}
                    />
                    <ItemSummaryContainer
                        itemId={itemId}
                        item={itemData}
                        updateConfigurable={(configurable, value) => { this.props.handleUpdateConfigurable(item, configurable, value); }}
                        removeItem={this.handleRemoveItem}
                    />
                </React.Fragment>
            );
        });
    }

    /**
     * Function to tell the application to change the active section to view delivery details
     * This function will only be run when on mobile, as the Continue button will only be visible when the checkout
     * is "paged"
     *
     * @returns {null}
     */
    handleNextSection = () => {
        // If any of the following is true, then the user has already seen the Account section, so don't show it agains
        if (this.props.isLoggedIn || this.props.isGuest) {
            this.props.handleActivateSection(HeaderTabs.CONST__DELIVERY_LINK);
            return;
        }

        this.props.handleActivateSection(HeaderTabs.CONST__ACCOUNT);
    };

    /**
     * Function to execute before submitting the request to PayPal to start an express checkout
     *
     * @returns Promise
     */
    beforePaypalExpressSubmit = () => {
        return new Promise((res) => {
            // Clear state hydration so that when loading the checkout again, data from PayPal will be pre-populated,
            // and not get overwritten by "empty" values in state storage
            Storage.removeItem(`fn-checkout-app-state-${this.props.checkoutConfig.quoteId}`);
            res();
        });
    };

    /**
     * Render the free shipping indicator if free shipping is available
     *
     * @returns {*}
     */
    renderFreeShippingContainer() {
        if (typeof this.props.checkoutConfig.free_shipping_available === 'undefined') {
            return null;
        }

        return (
            <div className="free-shipping-container">
                <FreeShippingIndicatorContainer
                    progress={this.props.checkoutConfig.free_shipping_percentage}
                    messageAvailable="You have qualified for free shipping"
                    messageUnavailable={`You are ${this.props.checkoutConfig.free_shipping_remaining} away from free shipping`}
                    isAvailable={this.props.checkoutConfig.free_shipping_available}
                />
            </div>
        );
    };

    render() {
        const {
            shopping_cart_display_settings: {
                subtotal
            },
            totalsData: {
                subtotal_incl_tax: subtotalInclTax,
                subtotal: subtotalExclTax
            }
        } = this.props.checkoutConfig;
        // 1 = exclude tax, 2 = include tax, 3 = both
        const priceSubtotal = subtotal === '2' ? subtotalInclTax : subtotalExclTax;

        const classname = classnames(
            'checkout-section',
            'item-summary-container',
            { 'section-active': this.props.activeSection === HeaderTabs.CONST__BAG_LINK }
        );

        return (
            <section className={classname} id="item-summary-container" ref={this.itemSummaryContainer}>
                <div>
                {this.renderFreeShippingContainer()}
                {this.props.reviewItems && (
                    <div className="item-summary__review-items">Please review items</div>
                )}
                <div className="items" ref={this.itemsRef}>
                    {this.getItemSummaries()}
                </div>
                <div className="order-subtotal-container" ref={this.subtotalRef}>
                    <PriceDisplayContainer
                        id="order-subtotal"
                        label="Sub total"
                        price={formatPrice(priceSubtotal, this.props.checkoutConfig.basePriceFormat.pattern, true)}
                    />
                </div>
                {get(this.props.checkoutConfig.quoteData, 'is_express', false) === false && (
                    <PaypalExpressContainer
                        config={this.props.checkoutConfig.payment.paypalExpress}
                        beforeSubmit={this.beforePaypalExpressSubmit}
                        label=""
                        validate={() => true}
                    />
                )}
                </div>
            </section>
        );
    }

}

ItemSummary.propTypes = {
    checkoutConfig: PropTypes.object.isRequired,
    activeSection: PropTypes.number.isRequired,
    handleActivateSection: PropTypes.func.isRequired,
    handleUpdateConfigurable: PropTypes.func.isRequired,
    handleRemoveItem: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isGuest: PropTypes.bool.isRequired,
    selectedClickCollectStore: PropTypes.string.isRequired,
    stockists: PropTypes.array.isRequired,
    reviewItems: PropTypes.bool.isRequired
};

export default ItemSummary;
