import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * AdyenWrapper React Component
 *
 * This is a wrapper component for the Adyen DOM that is passed back from their server.
 */
class AdyenWrapper extends Component {

    render() {
        const { isLoading, isAfterpay, afterpayInstallments } = this.props;
        const className = classnames('adyen-container', { 'adyen-form--loading': isLoading, 'is-afterpay': isAfterpay });

        return (
            <div className={className}>
                <div id="adyen-hosted-form" className="adyen-hosted-form"/>
                <div id="dropin-container"></div>
                <div className="adyen-container__loading-overlay">Updating...</div>
                <div className="afterpay-content">
                    <p>4 equal payments of {afterpayInstallments}. You will be redirected to Afterpay to complete your order.</p>
                    <p>To see Afterpay’s complete terms, visit <a href="http://www.afterpay.com/terms" rel="noreferrer noopener" target="_blank">afterpay.com/terms</a></p>
                </div>
            </div>
        );
    }

}

AdyenWrapper.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isAfterpay: PropTypes.bool.isRequired,
    afterpayInstallments: PropTypes.string.isRequired
};

export default AdyenWrapper;
