import React from 'react';
import ReactDOM from 'react-dom';
import 'idempotent-babel-polyfill';
import { CookiesProvider } from 'react-cookie';
import App from './App';

if (document.getElementById('add-new-card-render')) {

    document.addEventListener('display-cc',function()
        {
            ReactDOM.unmountComponentAtNode(document.getElementById('add-new-card-render'))
            ReactDOM.render(
                (
                    <CookiesProvider>
                        <App cardPaymentConfig={window.cardPaymentConfig} />
                    </CookiesProvider>
                ),
                document.getElementById('add-new-card-render')
            );

         }
    );
}

