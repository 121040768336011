import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import validate from 'validate.js/validate';
import Input from './Input';

class InputContainer extends Component {

    /**
     * Return either the default constraints, or the constraints passed in as a prop
     *
     * @returns {Object}
     */
    getConstraints() {
        const { constraints = false } = this.props;

        return constraints ||
            {
                val: {
                    presence: true,
                    length: {
                        minimum: 1
                    }
                }
            };
    }

    componentDidMount() {
        this.constraints = this.getConstraints();
    }

    validate = () => {
        const isValid = validate({ val: this.props.value }, this.constraints) || { errors: false };
        this.props.validate(isValid && isValid.errors === false);
    };

    render() {
        const className = classnames('input-container', `${this.props.id}-container`, { 'validation-error': !this.props.isValid });
        /**
         * onBlur will call the validate function passed in as a prop, if included, otherwise it will call the default
         * validation function within this container
         */
        return (
            <div className={className}>
                <Input
                    onBlur={this.props.validate ? this.validate : undefined}
                    autoComplete="off"
                    { ...this.props }
                />
            </div>
        );
    }

}

InputContainer.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired
    ]),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    validate: PropTypes.func,
    isValid: PropTypes.bool,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
    isRequired: PropTypes.bool,
    constraints: PropTypes.object,
    disabled: PropTypes.bool
};

export default InputContainer;
