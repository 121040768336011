/**
 * LaybuyContainer React Component
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AJAX from '../../Util/AJAX';

class LaybuyContainer extends Component {
    submit = async () => {
        const isValid = await this.props.validate();

        if (!isValid) {
            return;
        }

        // Wait for checkout to tell Magento that the user is paying with Laybuy
        if (this.props.handleAction) {
            await this.props.handleAction();
        }

        const isValidQuote = await this.props.validateQuote('laybuy_payment');

        if (!isValidQuote) {
            return;
        }

        this.props.clearGlobalErrorMessages();

        const { data } = await AJAX.get(this.props.config.url);

        if (data.success) {
            location.href = data.redirect_url;
        } else {
            this.props.addGlobalErrorMessage({ message: 'Unable to complete Laybuy order' });
        }
    };

    render() {
        return (
            <div className="laybuy-container">
                <button type="button" className="laybuy-container__button" onClick={this.submit}>
                    Pay with Laybuy
                </button>
            </div>
        );
    }

}

LaybuyContainer.propTypes = {
    config: PropTypes.object.isRequired,
    addGlobalErrorMessage: PropTypes.func.isRequired,
    clearGlobalErrorMessages: PropTypes.func.isRequired,
    handleAction: PropTypes.func,
    validate: PropTypes.func,
    validateQuote: PropTypes.func
};

export default LaybuyContainer;
