/**
 * ErrorContainer React Component
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DinkusContainer extends Component {
    render() {
        return (
            <section className="dinkus-container">
                <div style={{color:'#'+this.props.item.rule_dinkus_color}} className="rule_dinkus">{this.props.item.rule_dinkus}</div>
            </section>
        );
    }
}
export default DinkusContainer;
