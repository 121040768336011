import React from 'react';
import ReactDOM from 'react-dom';
import 'idempotent-babel-polyfill';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { CookiesProvider } from 'react-cookie';
import translator from '../Util/Translator';
import App from './App';

if (document.getElementById('react-checkout')) {
    LogRocket.init('okkv8w/forever-new-m2', {
        network: {
            requestSanitizer: request => {
                // if the url contains 'ignore'
                if (request.url.toLowerCase().indexOf('login') !== -1) {
                    // ignore the request response pair
                    return null;
                }

                // otherwise log the request normally
                return request;
            },
        }
    });
    setupLogRocketReact(LogRocket);

    if (window.checkoutConfig.customerData && window.checkoutConfig.customerData.email) {
        LogRocket.identify(window.checkoutConfig.customerData.email);
    }

    translator.init(`${checkoutConfig.viewFileUrl}/js-translation.json`, `${checkoutConfig.viewFileUrl}/react-default-translations/translation.json`).then(() => {
        ReactDOM.render(
            (
                <CookiesProvider>
                    <App checkoutConfig={window.checkoutConfig} checkoutApp={window.checkoutApp} staticBlocks={window.staticBlocks} />
                </CookiesProvider>
            ),
            document.getElementById('react-checkout')
        );
    });
}
