/* eslint-disable prefer-destructuring */

/**
 * implements Google Analytics 4 GTM datalayer.push code
 */
 export default() => {
    // dataLayer is a global variable, used by Google Tag Manager.
    // Initialise it here if it has not already been initialised.
    const dataLayer = window.dataLayer || [];
    const gta4React = window.gta4React || {};
    gta4React.version = "0.29r";

    if (!window.gta4Versions) { window.gta4Versions = {}; }
    window.gta4Versions.getgoogleanalytics4 = '0.29gg';

    //add additional gta4App functions here for react
    gta4React.test = (text) => {
        console.log('gta4React.test() called with ' + test);
    };

    return gta4React;
};
