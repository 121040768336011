/**
 * Shipping React Component
 */
import React, {Component, createRef} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import InputContainer from './../../common/InputContainer';
import PersonalDetails from './../../common/PersonalDetails';
import AuthorityToLeave from 'aligentreact/build/containers/AuthorityToLeave/AuthorityToLeave';
import ShippingMethodsContainer from 'aligentreact/build/containers/ShippingMethodsContainer/ShippingMethodsContainer';
import { customer, address } from '../../propTypes';
import showInvalidPhoneNumberMessage from '../../Util/functions/show_invalid_phone_number_message';

class Shipping extends Component {
    constructor(props) {
        super(props);
        this.personalDetailsRef = createRef();
    }

    componentDidMount() {
    // const personalDetailsRef = this.personalDetailsRef;
    //     document.addEventListener('mousedown', (event) => {if (personalDetailsRef && !personalDetailsRef.current.contains(event.target) ) {
    //         this.props.validateCustomerDetails('address');
    //     }}, true);
    }
    componentWillUnmount() {
        // const personalDetailsRef = this.personalDetailsRef;
        // document.removeEventListener('mousedown', (event) => {if (personalDetailsRef && !personalDetailsRef.current.contains(event.target)) {
        //     this.props.validateCustomerDetails('address');
        // }}, true);
    }

    renderShippingMethodsContainer = () => {
        if (this.props.checkoutConfig.quoteData.is_virtual) {
            return null;
        }

        return (
            <ShippingMethodsContainer
                label="Shipping Options"
                shippingMethods={this.props.shippingMethods}
                selected={this.props.selectedShippingMethod}
                onChange={this.props.handleShippingMethodsChange}
                slotOne={(
                    this.props.errorMessage !== '' ?
                        <div className="validation-message validation-error">{this.props.errorMessage}</div> :
                        null // Required to satisfy PropTypes, can't be boolean
                )}
            />
        );
    };

    renderAuthorityToLeave() {
        return null; // DR-5177 ATL is disabled for launch, leaving code as it will be switched back on afterwards

        if (this.props.checkoutConfig.quoteData.is_virtual) {
            return null;
        }

        return (
            <div className="authority-to-leave-container">
                <AuthorityToLeave
                    options={[
                        {
                            label: 'Authority to leave',
                            value: 'true',
                            showTextarea: true
                        },
                        {
                            label: 'I must sign for my package (return to post office)',
                            value: 'false'
                        }
                    ]}
                    onChange={this.props.handleAuthorityToLeaveChange}
                    selectedOption={this.props.address.atl_signature ? 'false' : 'true'}
                    notes={this.props.atlNotes}
                    notesLabel="Delivery Instructions"
                    handleNotesChange={this.props.handleAuthorityToLeaveNotesChange}
                    disclaimer="Please note that if you grant us authority to leave your parcel, you release us and our couriers
                        from any responsibility or liability if your parcel is lost, stolen or damaged once it has been delivered."
                    isValid={this.props.address.atl_valid}
                />
            </div>
        );
    }

    render() {
        return (
            <section className="shipping-container">
                <div ref={this.personalDetailsRef}>
                    <PersonalDetails
                        firstname={this.props.customer.firstname}
                        lastname={this.props.customer.lastname}
                        email={this.props.customer.email}
                        onChange={this.props.handleChangeCustomer}
                        onBlur={this.props.handleOnBlur}
                    >
                        <InputContainer
                            id="personal-details__telephone"
                            name="telephone"
                            type="tel"
                            label="Telephone"
                            placeholder="Phone number..."
                            value={this.props.customer.telephone.value}
                            isValid={this.props.customer.telephone.valid}
                            onChange={this.props.handleChangeCustomer}
                            onBlur={this.props.handleOnBlur}
                            isRequired={true}
                            isGuestUserForm={true}
                            errorMessage={'Invalid phone number. Please enter a valid number with no more than 15 digits'}
                        />

                        <InputContainer
                            id="personal-details__company"
                            name="company"
                            label={<span>Company name <span className="sublabel">(Optional)</span></span>}
                            placeholder="Enter company name"
                            value={this.props.customer.company.value}
                            isValid={this.props.customer.company.valid}
                            onChange={this.props.handleChangeCustomer}
                            isRequired={false}
                        />
                    </PersonalDetails>
                </div>

                {this.props.deliveryForm}

                {this.renderShippingMethodsContainer()}

                {this.renderAuthorityToLeave()}
            </section>
        );
    }

}

Shipping.propTypes = {
    checkoutConfig: PropTypes.object.isRequired,
    deliveryForm: PropTypes.element.isRequired,
    handleChangeCustomer: PropTypes.func.isRequired,
    customer: PropTypes.shape(customer),
    address: PropTypes.shape(address),
    shippingMethods: PropTypes.array.isRequired,
    selectedShippingMethod: PropTypes.string.isRequired,
    handleShippingMethodsChange: PropTypes.func.isRequired,
    atlNotes: PropTypes.string.isRequired,
    handleAuthorityToLeaveChange: PropTypes.func.isRequired,
    handleAuthorityToLeaveNotesChange: PropTypes.func.isRequired,
    errorMessage: PropTypes.string.isRequired
};

export default Shipping;
