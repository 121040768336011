import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TabbedUiContainer from 'aligentreact/build/containers/TabbedUiContainer/TabbedUiContainer';
import Search from './components/Search';
import Locations from './components/Locations';
import Map from './components/Map';
import { __ } from '../Util/Translator';
import {
    propShapeConfiguration
} from '../propTypes/store-locator';


class App extends Component {

    constructor(props) {
        super(props);
        this.id = 'store-locator';
        this.isMobile = false;
        this.state = {
            locations: null,
            locHash: window.location.hash,
            activeLocation: null
        };

        this.tabbedUiRef = React.createRef();

        window.onresize = () => {
            this.handleResize();
        };
    }

    componentDidMount() {
        this.handleResize();
    }

    /**
     * Handles displaying store on load based on location hash
     *
     * @param {string} locHash - location hash
     * @return {null}
     */
    handleJumpToLocation = (locHash) => {
        const locKey = this.state.locations.findIndex(loc => loc.url_key === locHash.substr(1));
        if (locKey > -1) {
            this.handleShowLocation(locKey);
        }
    };

    /**
     * Handles successful search with results
     * Clears location hash and detects initial hash to jump to store
     *
     * @param {array} results - store locations
     * @return {null}
     */
    handleSearchSuccess = (results) => {
        window.location.hash = '';
        const { locHash } = this.state;

        this.setState({ locations: results, activeLocation: null, locHash: null });
        if (locHash) this.handleJumpToLocation(locHash);
    };

    handleSearchFailure = (error) => {
        this.setState({ locations: null, activeLocation: null });
    };

    handleShowLocation = (id) => {
        window.location.hash = this.state.locations[parseInt(id)].url_key;
        this.setState({ activeLocation: parseInt(id) });
        if (this.isMobile) this.tabbedUiRef.current.goToTab(1);
    };

    handleShowDirections = (lat, lng) => {
        navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
            window.open(`https://www.google.com/maps/dir/${latitude},${longitude}/${lat},${lng}`, '_blank');
        });
    };

    /**
     * Determines if mobile display
     * 768 = mobile breakpoint
     */
    handleResize = () => {
        this.isMobile = window.innerWidth < 768;
        this.tabbedUiRef.current.goToTab( this.isMobile ? 0 : 1 );
    };

    renderSearch = () => {
        return <Search
            configuration={this.props.configuration}
            onSearchSuccess={this.handleSearchSuccess}
            onSearchFailure={this.handleSearchFailure}
            placeholder={__('Enter city, town, postcode or suburb')}
        />;
    };

    renderLocations = () => {
        return <Locations
            locations={this.state.locations}
            onSelectLocation={this.handleShowLocation}
            onSelectDirections={this.handleShowDirections}
        />;
    };

    renderMap = () => {
        return <Map
            configuration={this.props.configuration}
            locations={this.state.locations}
            activeLocation={this.state.activeLocation}
            onSelectLocation={this.handleShowLocation}
            onSelectDirections={this.handleShowDirections}
        />;
    };

    render() {
        return (
            <div className={this.id}>
                <div className={`${this.id}-search`}>
                    {this.renderSearch()}
                    <div className={`${this.id}-search__locations locations`}>
                        {this.renderLocations()}
                    </div>
                </div>

                <div className={`${this.id}-map`}>
                    <TabbedUiContainer
                        ref={this.tabbedUiRef}
                        tabs={[
                            {
                                label: 'List',
                                content: (
                                    <div className={`${this.id}-map__locations locations`}>
                                        {this.renderLocations()}
                                    </div>
                                )
                            },
                            {
                                label: 'Map',
                                content: this.renderMap()
                            }
                        ]}
                    />
                </div>
            </div>
        );
    }
}

App.propTypes = {
    configuration: propShapeConfiguration
};

export default App;
