/**
 * A simplified representation of the checkoutConfig object that Magento makes available in the checkout
 * The properties contained in this object are all the properties that the React Checkout requires
 */
export default {
    payment: {
        paypalExpress: {
            paymentAcceptanceMarkHref:
                'https://www.paypal.com/nz/cgi-bin/webscr?cmd=xpt/Marketing/popup/OLCWhatIsPayPal-outside',
            paymentAcceptanceMarkSrc:
                'https://www.paypalobjects.com/webstatic/en_US/i/buttons/pp-acceptance-medium.png',
            isContextCheckout: false,
            inContextConfig: []
        },
        paypalBillingAgreement: {
            agreements: [],
            transportName: 'ba_agreement_id'
        },
        aligent_adyen: {
            title: 'Credit Card',
            mode: 'test',
            paymentSessionUrl:
                'https://fn2.local/aligent_adyen/payment/session/',
            paymentResponseUrl:
                'https://fn2.local/aligent_adyen/payment/response/'
        }
    },
    formKey: 'FkERZA1iRAuoyFs6',
    customerData: [],
    quoteData: {
        entity_id: 'd0d80d645a774a436ca01cca3ab57a80',
        store_id: 2,
        created_at: '2018-08-11 04:07:31',
        updated_at: '2018-08-11 04:07:31',
        converted_at: null,
        is_active: '1',
        is_virtual: 0,
        is_multi_shipping: 0,
        items_count: 1,
        items_qty: 1,
        orig_order_id: '0',
        store_to_base_rate: '0.0000',
        store_to_quote_rate: '0.0000',
        base_currency_code: 'NZD',
        store_currency_code: 'NZD',
        quote_currency_code: 'NZD',
        grand_total: 65,
        base_grand_total: 65,
        checkout_method: null,
        customer_id: null,
        customer_tax_class_id: '3',
        customer_group_id: '0',
        customer_email: null,
        customer_prefix: null,
        customer_firstname: null,
        customer_middlename: null,
        customer_lastname: null,
        customer_suffix: null,
        customer_dob: null,
        customer_note: null,
        customer_note_notify: '1',
        customer_is_guest: '0',
        remote_ip: '172.22.0.1',
        applied_rule_ids: '',
        reserved_order_id: null,
        password_hash: null,
        coupon_code: null,
        global_currency_code: 'NZD',
        base_to_global_rate: '1.0000',
        base_to_quote_rate: '1.0000',
        customer_taxvat: null,
        customer_gender: null,
        subtotal: 56.52,
        base_subtotal: 56.52,
        subtotal_with_discount: 56.52,
        base_subtotal_with_discount: 56.52,
        is_changed: '1',
        trigger_recollect: '0',
        ext_shipping_info: null,
        is_persistent: '0',
        customer_balance_amount_used: 0,
        base_customer_bal_amount_used: 0,
        use_customer_balance: null,
        gift_cards: null,
        gift_cards_amount: 0,
        base_gift_cards_amount: 0,
        gift_cards_amount_used: 0,
        base_gift_cards_amount_used: 0,
        gift_message_id: null,
        gw_id: null,
        gw_allow_gift_receipt: null,
        gw_add_card: null,
        gw_base_price: 0,
        gw_price: 0,
        gw_items_base_price: 0,
        gw_items_price: 0,
        gw_card_base_price: 0,
        gw_card_price: 0,
        gw_base_tax_amount: null,
        gw_tax_amount: null,
        gw_items_base_tax_amount: null,
        gw_items_tax_amount: null,
        gw_card_base_tax_amount: null,
        gw_card_tax_amount: null,
        gw_base_price_incl_tax: null,
        gw_price_incl_tax: null,
        gw_items_base_price_incl_tax: null,
        gw_items_price_incl_tax: null,
        gw_card_base_price_incl_tax: null,
        gw_card_price_incl_tax: null,
        use_reward_points: null,
        reward_points_balance: 0,
        base_reward_currency_amount: 0,
        reward_currency_amount: 0,
        items_qtys: null,
        zipmoney_token: null,
        shippedfrom_storename: null,
        zippay_txn_id: null,
        location_code: null,
        mobile_number: null,
        locationid: null,
        sms_me: null,
        sfs_issue: null,
        webpos_cart_discount_type: null,
        webpos_cart_discount_value: null,
        webpos_cart_discount_name: null,
        base_gift_voucher_discount: null,
        gift_voucher_discount: null,
        giftvoucher_base_hidden_tax_amount: null,
        giftvoucher_hidden_tax_amount: null,
        giftvoucher_base_shipping_hidden_tax_amount: null,
        giftvoucher_shipping_hidden_tax_amount: null,
        rewardpoints_earn: null,
        rewardpoints_spent: null,
        rewardpoints_base_discount: '0.0000',
        rewardpoints_base_amount: '0.0000',
        rewardpoints_amount: '0.0000',
        rewardpoints_discount: '0.0000',
        fulfill_online: '0',
        click_collect_location_id: null,
        atl_signature: null,
        atl_instructions: null,
        event_initialized: true,
        items: [{}],
        extension_attributes: {},
        x_forwarded_for: null,
        totals_collected_flag: true,
        virtual_items_qty: 0,
        shipping_amount: 0,
        base_shipping_amount: 0,
        shipping_description: null
    },
    quoteItemData: [
        {
            item_id: '5934',
            quote_id: '3826',
            created_at: '2018-08-11 04:07:31',
            updated_at: '0000-00-00 00:00:00',
            product_id: '702',
            store_id: 2,
            parent_item_id: null,
            is_virtual: '0',
            sku: '2024719603121',
            name: 'Charlotte Drape Maxi Dress ',
            description: null,
            applied_rule_ids: '',
            additional_data: null,
            is_qty_decimal: false,
            no_discount: '0',
            weight: '8.0000',
            qty: 1,
            price: 56.52,
            base_price: 56.52,
            custom_price: null,
            discount_percent: 0,
            discount_amount: 0,
            base_discount_amount: 0,
            tax_percent: 15,
            tax_amount: 8.48,
            base_tax_amount: 8.48,
            row_total: 56.52,
            base_row_total: 56.52,
            row_total_with_discount: '0.0000',
            row_weight: 8,
            product_type: 'configurable',
            base_tax_before_discount: null,
            tax_before_discount: null,
            original_custom_price: null,
            redirect_url: null,
            base_cost: null,
            price_incl_tax: 65,
            base_price_incl_tax: 65,
            row_total_incl_tax: 65,
            base_row_total_incl_tax: 65,
            discount_tax_compensation_amount: 0,
            base_discount_tax_compensation_amount: 0,
            free_shipping: false,
            gift_message_id: null,
            weee_tax_applied: null,
            weee_tax_applied_amount: null,
            weee_tax_applied_row_amount: null,
            weee_tax_disposition: null,
            weee_tax_row_disposition: null,
            base_weee_tax_applied_amount: null,
            base_weee_tax_applied_row_amnt: null,
            base_weee_tax_disposition: null,
            base_weee_tax_row_disposition: null,
            gw_id: null,
            gw_base_price: null,
            gw_price: null,
            gw_base_tax_amount: null,
            gw_tax_amount: null,
            event_id: null,
            giftregistry_item_id: null,
            stock_id: null,
            is_free_product: null,
            is_excluded_product: '0',
            has_children: true,
            qty_options: {
                693: {}
            },
            product: {
                status: '1',
                entity_id: '702',
                attribute_set_id: '4',
                type_id: 'configurable',
                created_at: '2018-07-26 02:56:29',
                updated_at: '2018-07-26 07:19:18',
                sku: '202471960',
                msrp: null,
                msrp_display_actual_price_type: '0',
                name: 'Charlotte Drape Maxi Dress ',
                visibility: '2',
                price: null,
                weight: '8.0000',
                url_path: null,
                url_key: 'charlotte-drape-maxi-dress-202471960',
                thumbnail: null,
                small_image: null,
                tax_class_id: '2',
                special_from_date: null,
                special_to_date: null,
                special_price: null,
                cost: null,
                gift_message_available: '2',
                giftcard_type: null,
                giftcard_amounts: null,
                allow_open_amount: null,
                open_amount_min: null,
                open_amount_max: null,
                use_config_lifetime: null,
                lifetime: null,
                use_config_is_redeemable: null,
                is_redeemable: null,
                use_config_email_template: null,
                email_template: null,
                price_view: null,
                price_type: null,
                weight_type: null,
                sku_type: null,
                links_purchased_separately: null,
                links_title: null,
                gift_wrapping_available: '2',
                gift_wrapping_price: null,
                row_id: '678',
                category_ids: ['465', '486', '573'],
                event: false,
                request_path: 'charlotte-drape-maxi-dress-202471960',
                tier_price: [],
                tier_price_changed: 0,
                store_id: 2,
                customer_group_id: '0',
                final_price: null,
                extension_attributes: {}
            },
            tax_class_id: '2',
            has_error: false,
            base_gift_voucher_discount: 0,
            gift_voucher_discount: 0,
            base_use_gift_credit_amount: 0,
            magestore_base_discount: 0,
            use_gift_credit_amount: 0,
            calculation_price: null,
            converted_price: 56.52,
            base_original_price: '65.0000',
            base_calculation_price: 65,
            tax_calculation_item_id: 'sequence-1',
            tax_calculation_price: 65,
            base_tax_calculation_price: 65,
            discount_calculation_price: 65,
            base_discount_calculation_price: 65,
            applied_taxes: [
                {
                    amount: 8.48,
                    base_amount: 8.48,
                    percent: 15,
                    id: 'GST',
                    rates: [
                        {
                            percent: 15,
                            code: 'GST',
                            title: 'GST'
                        }
                    ],
                    item_id: '5934',
                    item_type: 'product',
                    associated_item_id: null
                }
            ],
            product_option: {},
            options: [
                {
                    value: 'Navy',
                    label: 'Colour'
                },
                {
                    value: '12',
                    label: 'Size'
                }
            ],
            thumbnail:
                'https://fn2.local/media/catalog/product/placeholder/stores/2/missing-product-image-thumb.jpg',
            configurable_attributes: {
                769: {
                    attribute_code: 'size',
                    attribute_label: 'Size',
                    options: {
                        69: {
                            sku: '2024719603046',
                            product_id: '702',
                            value_index: '69',
                            option_title: '4'
                        },
                        72: {
                            sku: '2024719602063',
                            product_id: '702',
                            value_index: '72',
                            option_title: '6'
                        },
                        75: {
                            sku: '2024719602087',
                            product_id: '702',
                            value_index: '75',
                            option_title: '8'
                        },
                        105: {
                            sku: '2024719602100',
                            product_id: '702',
                            value_index: '105',
                            option_title: '10'
                        },
                        108: {
                            sku: '2024719602124',
                            product_id: '702',
                            value_index: '108',
                            option_title: '12'
                        },
                        111: {
                            sku: '2024719602148',
                            product_id: '702',
                            value_index: '111',
                            option_title: '14'
                        },
                        114: {
                            sku: '2024719602162',
                            product_id: '702',
                            value_index: '114',
                            option_title: '16'
                        }
                    }
                },
                825: {
                    attribute_code: 'fashion_colour',
                    attribute_label: 'Fashion Colour',
                    options: {
                        144: {
                            sku: '2024719603060',
                            product_id: '702',
                            value_index: '144',
                            option_title: 'Navy'
                        },
                        207: {
                            sku: '2024719602162',
                            product_id: '702',
                            value_index: '207',
                            option_title: 'Nude'
                        }
                    }
                }
            },
            used_attributes: [
                {
                    label: 'Colour',
                    value: 'Navy',
                    option_id: 825,
                    option_value: '144'
                },
                {
                    label: 'Size',
                    value: '12',
                    option_id: 769,
                    option_value: '108'
                }
            ]
        }
    ],
    isCustomerLoggedIn: false,
    selectedShippingMethod: null,
    storeCode: 'fn_nz_en',
    isGuestCheckoutAllowed: true,
    registerUrl: 'https://fn2.local/customer/account/create/',
    checkoutUrl: 'https://fn2.local/checkout/',
    defaultSuccessPageUrl: 'https://fn2.local/checkout/onepage/success/',
    pageNotFoundUrl: 'https://fn2.local/checkout/noroute/',
    forgotPasswordUrl: 'https://fn2.local/customer/account/forgotpassword/',
    staticBaseUrl: 'https://fn2.local/static/version1533857217/',
    priceFormat: {
        pattern: '$%s',
        precision: 2,
        requiredPrecision: 2,
        decimalSymbol: '.',
        groupSymbol: ',',
        groupLength: 3,
        integerRequired: 1
    },
    basePriceFormat: {
        pattern: '$%s',
        precision: 2,
        requiredPrecision: 2,
        decimalSymbol: '.',
        groupSymbol: ',',
        groupLength: 3,
        integerRequired: 1
    },
    postCodes: {
        AU: {
            pattern_1: {
                example: '1234',
                pattern: '^[0-9]{4}$'
            }
        },
        US: {
            pattern_1: {
                example: '12345-6789',
                pattern: '^[0-9]{5}\\-[0-9]{4}$'
            },
            pattern_2: {
                example: '12345',
                pattern: '^[0-9]{5}$'
            }
        }
    },
    imageData: {
        5934: {
            src:
                'https://fn2.local/media/catalog/product/cache/fd4c882ce4b945a790b629f572e4ef93/c/h/charlottedrape_navy_4_1_3.jpg',
            alt: 'Charlotte Drape Maxi Dress-Navy-12',
            width: 75,
            height: 75
        }
    },
    totalsData: {
        subtotal: 56.52,
        base_subtotal: 56.52,
        subtotal_with_discount: 56.52,
        base_subtotal_with_discount: 56.52,
        tax_amount: 8.48,
        base_tax_amount: 8.48,
        shipping_amount: 0,
        base_shipping_amount: 0,
        shipping_tax_amount: 0,
        base_shipping_tax_amount: 0,
        discount_amount: 0,
        base_discount_amount: 0,
        grand_total: 56.52,
        base_grand_total: 65,
        shipping_discount_amount: 0,
        base_shipping_discount_amount: 0,
        subtotal_incl_tax: 65,
        shipping_incl_tax: 0,
        base_shipping_incl_tax: 0,
        entity_id: '8594',
        base_subtotal_incl_tax: 65,
        total_segments: [
            {
                code: 'subtotal',
                title: 'Subtotal',
                value: 65,
                area: null
            },
            {
                code: 'giftwrapping',
                title: 'Gift Wrapping',
                value: null,
                area: null,
                extension_attributes: {
                    gw_item_ids: [],
                    gw_order_id: null,
                    gw_price: false,
                    gw_base_price: false,
                    gw_items_price: false,
                    gw_items_base_price: false,
                    gw_allow_gift_receipt: null,
                    gw_add_card: null,
                    gw_card_price: false,
                    gw_card_base_price: false,
                    gw_tax_amount: null,
                    gw_base_tax_amount: null,
                    gw_items_tax_amount: null,
                    gw_items_base_tax_amount: null,
                    gw_card_tax_amount: null,
                    gw_card_base_tax_amount: null,
                    gw_price_incl_tax: null,
                    gw_base_price_incl_tax: null,
                    gw_card_price_incl_tax: null,
                    gw_card_base_price_incl_tax: null,
                    gw_items_price_incl_tax: null,
                    gw_items_base_price_incl_tax: null
                }
            },
            {
                code: 'shipping',
                title: 'Shipping & Handling',
                value: 0,
                area: null
            },
            {
                code: 'tax',
                title: 'Tax',
                value: 8.48,
                area: null,
                extension_attributes: {
                    tax_grandtotal_details: [
                        {
                            amount: 8.48,
                            rates: [
                                {
                                    percent: 15,
                                    title: 'GST'
                                }
                            ],
                            group_id: 1
                        }
                    ]
                }
            },
            {
                code: 'grand_total',
                title: 'Grand Total',
                value: 65,
                area: 'footer'
            }
        ],
        coupon_code: null,
        items: [
            {
                item_id: '5934',
                name: 'Charlotte Drape Maxi Dress ',
                qty: 1,
                price: 56.52,
                base_price: 56.52,
                discount_percent: 0,
                discount_amount: 0,
                base_discount_amount: 0,
                tax_percent: 15,
                tax_amount: 8.48,
                base_tax_amount: 8.48,
                row_total: 56.52,
                base_row_total: 56.52,
                row_total_with_discount: '0.0000',
                price_incl_tax: 65,
                base_price_incl_tax: 65,
                row_total_incl_tax: 65,
                base_row_total_incl_tax: 65,
                weee_tax_applied: null,
                weee_tax_applied_amount: null,
                options:
                    "[{'value':'Navy','label':'Colour'},{'value':'12','label':'Size'}]"
            }
        ],
        items_qty: 1,
        base_currency_code: 'NZD',
        quote_currency_code: 'NZD',
        extension_attributes: {
            reward_points_balance: 0,
            reward_currency_amount: 0,
            base_reward_currency_amount: 0
        },
        reward_points_balance: 0,
        reward_currency_amount: 0,
        base_reward_currency_amount: 0
    },
    shippingPolicy: {
        isEnabled: false,
        shippingPolicyContent: ''
    },
    activeCarriers: [
        'click_collect',
        'flatrate',
        'tablerate',
        'freeshipping',
        'webpos_shipping'
    ],
    originCountryCode: 'NZ',
    paymentMethods: [],
    autocomplete: 'off',
    displayBillingOnPaymentMethod: true,
    maxCartItemsToDisplay: 10,
    cartUrl: 'https://fn2.local/checkout/cart/',
    vault: [],
    isDisplayShippingPriceExclTax: false,
    isDisplayShippingBothPrices: false,
    reviewShippingDisplayMode: 'including',
    reviewItemPriceDisplayMode: 'including',
    reviewTotalsDisplayMode: 'including',
    includeTaxInGrandTotal: false,
    isFullTaxSummaryDisplayed: false,
    isZeroTaxDisplayed: false,
    reloadOnBillingAddress: false,
    defaultCountryId: 'NZ',
    defaultRegionId: null,
    defaultPostcode: null,
    isDisplayPriceWithWeeeDetails: false,
    isDisplayFinalPrice: false,
    isIncludedInSubtotal: false,
    quoteId: 'd0d80d645a774a436ca01cca3ab57a80',
    enabledSocialLoginProviders: ['google', 'facebook'],
    free_shipping_available: false,
    free_shipping_remaining: '$18.48',
    free_shipping_percentage: 75.36,
    countries: []
};
