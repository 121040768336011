/**
 * AfterpayButtonContainer React Component
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AJAX from '../../Util/AJAX';

class AfterpayButtonContainer extends Component {
    /**
     * Action that will handle actually triggering the Afterpay payment flow
     *
     * @returns {null}
     */
    submit = async () => {
        const {
            submitUrl, customerEmail, addGlobalError, clearGlobalErrors, defaultCountryId, validate
        } = this.props;

        const valid = await validate();
        if (!valid) {
            return;
        }
        await clearGlobalErrors();

        window.AfterPay.initialize({ countryCode: defaultCountryId });

        try {
            const formData = new FormData();
            formData.append('email', customerEmail);
            const { data: response } = await AJAX.post(submitUrl, formData);

            if (!response.success) {
                throw response;
            }

            window.AfterPay.redirect({ token: response.token });
        } catch (e) {
            addGlobalError({ message: 'There was an error processing the Afterpay order' });
        }
    };

    render() {
        const { buttonLabel } = this.props;

        return (
            <div className="afterpay-button-container">
                <button className="afterpay-button" type="button" onClick={this.submit}>
                    {buttonLabel}
                </button>
            </div>
        );
    }
}

AfterpayButtonContainer.propTypes = {
    customerEmail: PropTypes.string.isRequired,
    defaultCountryId: PropTypes.string.isRequired,
    addGlobalError: PropTypes.func.isRequired,
    clearGlobalErrors: PropTypes.func.isRequired,
    submitUrl: PropTypes.string,
    buttonLabel: PropTypes.string
};

AfterpayButtonContainer.defaultProps = {
    submitUrl: '/afterpay/payment/process',
    buttonLabel: 'Pay now with Afterpay'
};

export default AfterpayButtonContainer;
