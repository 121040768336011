import React from 'react';
import ReactDOM from 'react-dom';
import 'idempotent-babel-polyfill';
import App from './App';

if (document.getElementById('webpos-search-mount')) {

    const mountData = document.querySelector('#webpos-search-mount').dataset.params;

    ReactDOM.render(
        <App mountData={mountData}/>,
        document.getElementById('webpos-search-mount')
    );
}
