/**
 * Gets the appropriate region field label for the country
 *
 * @param {string}      defaultCountryId    ID of the country
 * @returns {string}                        Label for the country
 */
export default function getRegionLabelForCountry(defaultCountryId) {
    switch (defaultCountryId) {
        case 'AU':
            return 'State';
        case 'NZ':
        case 'CA':
        default:
            return 'Region';
    }
}
