/**
 * Format a provided price according to the pattern provided
 *
 * @param {int}     price        The number of the price
 * @param {string}  pattern      The pattern for prices
 * @param {boolean} showDecimals Flag to indicate if the decimals should be shown regardless of value
 */
export default (price, pattern, showDecimals = false) => {
    if (showDecimals || Math.round(price) !== price) {
        var result = pattern.replace('%s', parseFloat(price).toFixed(2));
    } else {
        var result = pattern.replace('%s', price);
    }

    if (result.indexOf('-') !== -1) {
        result = '-' + result.replace('-','');
    }
    return result;
};
