/**
 * @typedef {Object} FeatureFlags
 * @property {boolean} REWARD_POINTS_ENABLED is rewards points enabled
 * @property {boolean} SEPARATE_POLICY_CHECKBOX_ENABLED is separate policy checkbox enabled
 */

/**
 * Returns the checkout's feature flags based on the country code of the site
 *
 * @param {string} countryCode
 * @return {FeatureFlags}
 */
export default (countryCode) => {
    switch (countryCode) {
        case 'GB':
            return {
                REWARD_POINTS_ENABLED: false,
                SEPARATE_POLICY_CHECKBOX_ENABLED: true,
                SHOULD_SHOW_REGION: false,
            };
        case 'CA':
            return {
                REWARD_POINTS_ENABLED: false,
                SEPARATE_POLICY_CHECKBOX_ENABLED: true,
                SHOULD_SHOW_REGION: true,
            };
        case 'AU':
        case 'NZ':
        default:
            return {
                REWARD_POINTS_ENABLED: true,
                SEPARATE_POLICY_CHECKBOX_ENABLED: false,
                SHOULD_SHOW_REGION: true,
            };
    }
}
