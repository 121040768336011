import React from 'react';
import PropTypes from 'prop-types';
import {
    propShapeLocations
} from '../../propTypes/store-locator';


const Location = ({
    name, street, street2, city, state, postcode,
    building_name, phone_number, distance, units,
    latitude, longitude
}, key, onSelectLocation, onSelectDirections) => {
    const id = 'location';

    return (
        <div key={key} className={id}>
            <div className={`${id}__pin`}>{(key + 1)}</div>
            <div className={`${id}__distance`}>{`${parseFloat(distance).toFixed(2)}${units}s`}</div>
            <h4 className={`${id}__title`}>{name}</h4>
            <address className={`${id}__address`}>
                <p className={`${id}__address--building-name`}>{building_name}</p>
                <p className={`${id}__address--street`}>{street}</p>
                <p className={`${id}__address--street-2`}>{street2}</p>
                <p className={`${id}__address--city-state-postcode`}>{`${city}, ${state}, ${postcode}`}</p>
            </address>
            <p className={`${id}__phone`}>
                {phone_number !== undefined &&
                    <a href={`tel:${phone_number}`}>{`Phone: ${phone_number}`}</a>
                }
            </p>
            <p className={`${id}__actions`}>
                <button onClick={() => onSelectLocation(key)} className={`action--store-details`}>View store details</button>
                <button onClick={() => onSelectDirections(latitude, longitude)} className={`action--directions`}>Get directions</button>
            </p>
        </div>
    )
};

const Locations = ({ locations, onSelectLocation, onSelectDirections }) => {
    return locations ? locations.map((location, key) => Location(location, key, onSelectLocation, onSelectDirections)) : <h3>0 stores found</h3>;
};

Locations.propTypes = {
    locations: propShapeLocations,
    onSelectLocation: PropTypes.func.isRequired,
    onSelectDirections: PropTypes.func.isRequired
};

export default Locations;
