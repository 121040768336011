/**
 * localStorage abstraction
 */

export default class Storage {

    /**
     * Get item from local storage. Will parse JSON before resolving Promise
     *
     * @param {string} key The key of the item to be loaded from storage
     *
     * @returns {Promise}
     */
    static getItem(key) {
        return new Promise((resolve) => {
            resolve(localStorage.getItem(key));
        }).then((res) => {
            return JSON.parse(res);
        });
    }

    /**
     * Save data to storage
     *
     * @param {string}        key   The key of the item to be saved
     * @param {string|object} value The value being saved
     *
     * @returns {null}
     */
    static setItem(key, value) {
        localStorage.setItem(key, value);
    }

    /**
     * Remove an item from storage
     *
     * @param {string} key The key of the item to be removed
     */
    static removeItem(key) {
        localStorage.removeItem(key);
    }

}
