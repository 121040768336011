/**
 * A simplified representation of the checkoutApp object that is passed to the React component
 * This object contains the one and only property that is used in the checkout at the time of writing the tests
 */
export default {
    components: {
        checkout: {
            children: {
                authentication: {
                    children: {
                        'social-fields': {
                            linksContent: 'Social login data'
                        }
                    }
                }
            }
        }
    }
};
