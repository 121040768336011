import React from 'react'
import PropTypes from 'prop-types'
import { compose, withStateHandlers } from 'recompose'
import { Marker, InfoWindow } from 'react-google-maps'
import {
    propShapeLocations
} from '../../propTypes/store-locator';


const getInfoWindow = ({
    name, building_name, trading_hours, extended_hours,
    street, street2, city, state, postcode, phone_number,
    latitude, longitude
}, onSelectDirections) => {
    const id = "infowindow-store-info";
    const tradingHours = JSON.parse(trading_hours);

    return (
        <InfoWindow>
            <div className={id}>
                <h2>{name}</h2>

                <div className={`${id}__content`}>
                    <address className={`${id}__address`}>
                        <h4>Address</h4>
                        <p className={`${id}__address--building-name`}>{building_name}</p>
                        <p className={`${id}__address--street`}>{street}</p>
                        <p className={`${id}__address--street-2`}>{street2}</p>
                        <p className={`${id}__address--city-state-postcode`}>{`${city}, ${state}, ${postcode}`}</p>
                    </address>

                    <div className={`${id}__phone`}>
                        {phone_number !== undefined &&
                            <a href={`tel:${phone_number}`}>{`Phone: ${phone_number}`}</a>
                        }
                    </div>

                    <div className={`${id}__actions`}>
                        <button onClick={() => onSelectDirections(latitude, longitude)} className={`${id}__actions--directions`}>Directions</button>
                    </div>

                    <div className={`${id}__trading-hours`}>
                        <h4>Trading Hours</h4>
                        <table>
                            <tbody>
                                {Object.keys(tradingHours).map((day) => {
                                    return (
                                        <tr key={day}>
                                            <td>{day}</td>
                                            <td>{tradingHours[day]}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    {extended_hours && extended_hours.length > 0 &&
                    <div className={`${id}__extended-hours`}>
                        <h4>Extended Hours</h4>
                        <table>
                            <tbody>
                                {extended_hours.map(( extended_hour, key ) => {
                                    return <tr key={key}><td>{extended_hour.date}</td><td>{extended_hour.trading_hours}</td></tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    }
                </div>
            </div>
        </InfoWindow>
    )
};

const getMarker = (
    location , key, active,
    onSelectLocation, onSelectDirections
) => {
    const LocationMarker = compose(
        withStateHandlers(() => ({
            location: location,
            position: {
                lat: parseFloat(location.latitude),
                lng: parseFloat(location.longitude)
            }
        })),
    ) (props =>
        <Marker
            key={key}
            position={props.position}
            // icon='../images/store_locator/pin.svg'
            onClick={() => onSelectLocation(key)}
        >
            {active && getInfoWindow(props.location, onSelectDirections)}
        </Marker>
    );

    return <LocationMarker location={location} key={key} />
};


const Markers = ({
    locations, activeLocation, onSelectLocation, onSelectDirections
}) => {
    return locations ? (
        locations.map((location, key) => {
            return getMarker(location, key, key === activeLocation, onSelectLocation, onSelectDirections);
        })
    ) : null;
};

Markers.propTypes = {
    locations: propShapeLocations,
    onSelectLocation: PropTypes.func.isRequired,
    onSelectDirections: PropTypes.func.isRequired,
    activeLocation: PropTypes.number
};

export default Markers
