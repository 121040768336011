import axios from 'axios';

/**
 * Abstraction wrapper around the module being used to handle AJAX requests
 */
class Ajax {

    /**
     * Perform a POST request
     *
     * @param {string} url        URL to send the POST request to
     * @param {object} data       Data to be POSTed to the server with the request
     * @param {object} additional [Optional] Additional params, data, headers to be attached to request
     *
     * @returns {AxiosPromise}
     */
    static post(url, data, additional = {}) {
        return Ajax.request({
            method: 'post',
            url,
            data,
            ...additional
        });
    }

    /**
     * Perform a GET request
     *
     * @param {string} url        The URL to send the request to
     * @param {object} params     [Optional] Query parameters to attach to the request
     * @param {object} additional [Optional] Additional params, data, headers to be attached to request
     *
     * @returns {AxiosPromise}
     */
    static get(url, params = {}, additional = {}) {
        return Ajax.request({
            method: 'get',
            url,
            params,
            ...additional
        });
    }

    /**
     * Perform a PUT request
     *
     * @param {string} url        The URL to send the request to
     * @param {object} params     [Optional] Query parameters to attach to the request
     * @param {object} additional [Optional] Additional params, data, headers to be attached to request
     *
     * @returns {AxiosPromise}
     */
    static put(url, params = {}, additional = {}) {
        return Ajax.request({
            method: 'put',
            url,
            params,
            ...additional
        });
    }

    /**
     * Perform a DELETE request
     *
     * @param {string} url        The URL to send the request to
     * @param {object} params     [Optional] Query parameters to attach to the request
     * @param {object} additional [Optional] Additional params, data, headers to be attached to request
     *
     * @returns {AxiosPromise}
     */
    static delete(url, params = {}, additional = {}) {
        return Ajax.request({
            method: 'delete',
            url,
            params,
            ...additional
        });
    }

    /**
     * Perform a simple request
     * Capture 401 request to:
     * - change the error message
     * - get rid of stack trace
     * Note: the error message part is not ideal because this can be used in other places. However currently for FN we only use
     * react in Checkout. A possible todo in the future is to have a separate axios wrapper for Checkout only
     * 
     * @param {object} options Options for the request, including, for example; type, url, data etc
     *
     * @returns {AxiosPromise}
     */
    static request(options) {
        return axios({
            ...options,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                ...options.headers // Just in case a usage needs to overwrite the above header value
            }
        }).catch(function (error) {
            if (error.response.status === 401) {
                error.response.data = {
                    'message' : 'Your checkout session has expired. Please refresh your page to continue'
                };
            }
            throw error;
        });
    }

}

export default Ajax;

