/**
 * StaticBlock React Component
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const StaticBlock = ({ staticBlocks, identifier, className }) => {
    const classNames = classnames('static-block', className);
    const { content } = staticBlocks.find(staticBlock => staticBlock.identifier === identifier) || { content: false };

    if (!content) {
        return null;
    }

    return (
        <div className={classNames} dangerouslySetInnerHTML={{ __html: content }}></div>
    );
};

StaticBlock.propTypes = {
    staticBlocks: PropTypes.arrayOf(PropTypes.shape({
        identifier: PropTypes.string,
        content: PropTypes.string
    })),
    className: PropTypes.string,
    identifier: PropTypes.string
};

export default StaticBlock;
