import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputContainer from './InputContainer';
import StreetAddress from 'aligentreact/build/components/StreetAddress/StreetAddress';
import PostalCodeInputContainer from 'aligentreact/build/containers/PostalCodeInputContainer/PostalCodeInputContainer';
import SelectContainer from 'aligentreact/build/containers/SelectContainer/SelectContainer';
import AutoCompleteContainer from './AutoCompleteContainer';

class ManualAddressContainer extends Component {

    /**
     * Map the value provided to this function by the react-select component, to a format that the
     * handleChange prop can manage, without requiring custom code in the parent
     *
     * @param {object} regionSelector The value/label object for the selected option
     *
     * @returns {null}
     */
    handleRegionChange = ({ value }) => {
        this.setState({
            ...this.state,
            region: {
                value,
                valid: true
            }
        }, () => {
            this.props.handleChange({
                target: {
                    name: 'region',
                    value
                }
            });
        });
    };

    renderAutoCompleteSuburb() {
        return (
            <AutoCompleteContainer
                id={`suburb-automatic-${this.props.id}`}
                name="suburb"
                label={this.props.address.suburb.label}
                placeholder={this.props.address.suburb.placeholder}
                errorMessage={this.props.address.suburb.errorMessage || 'Please enter a valid suburb'}
                showMessage={this.props.address.suburb.showMessage}
                value={this.props.address.suburb.value}
                isValid={this.props.address.suburb.valid}
                disabled={this.props.address.suburb.disabled}
                handleChange={this.props.handleChange}
                handleSearch={this.props.autoCompleteSuburb.handleSearch}
                handleClick={this.props.autoCompleteSuburb.handleSuggestionClick}
                minCharacters={3}
                transformCompleteRequest={this.props.autoCompleteSuburb.transformCompleteRequest}
            />
        );
    }

    renderManualSuburb() {
        return (
            <InputContainer
                id={`suburb-manual-${this.props.id}`}
                name="suburb"
                label={this.props.address.suburb.label}
                placeholder={this.props.address.suburb.placeholder}
                errorMessage={this.props.address.suburb.errorMessage || 'Please enter a valid suburb'}
                showMessage={this.props.address.suburb.showMessage}
                value={this.props.address.suburb.value}
                isValid={this.props.address.suburb.valid}
                onChange={this.props.handleChange}
                disabled={this.props.address.suburb.disabled}
            />
        );
    }

    renderRegionSelect() {
        if (!this.props.address.region) {
            return null;
        }

        if (this.props.regions && this.props.regions.length > 0) {
            return (
                <SelectContainer
                    id={`region-manual-${this.props.id}`}
                    name="region"
                    label={this.props.address.region.label}
                    options={this.props.regions}
                    handleChange={this.props.handleChange}
                    value={this.props.address.region.value}
                    isValid={this.props.address.region.valid}
                    errorMessage={this.props.address.region.errorMessage || 'Please enter a valid region'}
                    placeholder={this.props.address.region.placeholder}
                />
            );
        }

        return (
            <InputContainer
                id={`region-manual-${this.props.id}`}
                name="region"
                label={this.props.address.region.label}
                value={this.props.address.region.value}
                onChange={this.props.handleChange}
                placeholder={this.props.address.region.placeholder}
                isValid={this.props.address.region.valid}
                errorMessage={this.props.address.region.errorMessage || 'Please enter a valid region'}
                showMessage={this.props.address.region.showMessage}
                disabled={this.props.address.region.disabled}
            />
        );
    }

    renderCityInput() {
        if (!this.props.address.city) {
            return null;
        }

        return (
            <InputContainer
                id={`city-manual-${this.props.id}`}
                name="city"
                label={this.props.address.city.label}
                value={this.props.address.city.value}
                isValid={this.props.address.city.valid}
                errorMessage={this.props.address.city.errorMessage || 'Please enter a valid city'}
                showMessage={this.props.address.city.showMessage}
                onChange={this.props.handleChange}
                placeholder={this.props.address.city.placeholder}
                disabled={this.props.address.city.disabled}
            />
        );
    }

    renderProvinceInput() {
        if (!this.props.address.province) {
            return null;
        }

        return (
            <InputContainer
                id={`province-manual-${this.props.id}`}
                name="city"
                label={this.props.address.province.label}
                value={this.props.address.province.value}
                isValid={this.props.address.province.valid}
                errorMessage={this.props.address.province.errorMessage || 'Please enter a valid province'}
                showMessage={this.props.address.province.showMessage}
                onChange={this.props.handleChange}
                placeholder={this.props.address.province.placeholder}
                disabled={this.props.address.province.disabled}
            />
        );
    }

    renderCountryInput() {
        if (!this.props.address.country) {
            return null;
        }

        return (
            <InputContainer
                id={`coutry-manual-${this.props.id}`}
                name="coutry"
                label={this.props.address.country.label}
                value={this.props.address.country.value}
                isValid={this.props.address.country.valid}
                errorMessage={this.props.address.country.errorMessage || 'Please enter a valid country'}
                showMessage={this.props.address.country.showMessage}
                onChange={this.props.handleChange}
                placeholder={this.props.address.country.placeholder}
                disabled={this.props.address.country.disabled}
            />
        );
    }

    render() {
        return (
            <div className="manual-address-container">

                <StreetAddress
                    id={this.props.id}
                    street={this.props.address.street}
                    onChange={this.props.handleChange}
                />

                {this.props.autoCompleteSuburb && this.props.autoCompleteSuburb.show ?
                    this.renderAutoCompleteSuburb() :
                    this.renderManualSuburb()
                }

                {this.renderRegionSelect()}

                {this.renderCityInput()}

                {this.renderProvinceInput()}

                <PostalCodeInputContainer
                    id="postcode-manual"
                    name="postcode"
                    label={this.props.address.postcode.label}
                    placeholder={this.props.address.postcode.placeholder}
                    errorMessage={this.props.address.postcode.errorMessage || 'Please enter a valid postal code'}
                    showMessage={this.props.address.postcode.showMessage}
                    value={this.props.address.postcode.value}
                    isValid={this.props.address.postcode.valid}
                    isRequired={true}
                    onChange={this.props.handleChange}
                    disabled={this.props.address.postcode.disabled}
                />

                {this.renderCountryInput()}
            </div>
        );
    }

}

ManualAddressContainer.propTypes = {
    address: PropTypes.shape({
        street: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
            placeholder: PropTypes.string,
            errorMessage: PropTypes.string,
            showMessage: PropTypes.bool,
            valid: PropTypes.bool,
            disabled: PropTypes.bool
        })),
        suburb: PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
            placeholder: PropTypes.string,
            errorMessage: PropTypes.string,
            showMessage: PropTypes.bool,
            valid: PropTypes.bool,
            disabled: PropTypes.bool
        }),
        postcode: PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.string.isRequired,
            placeholder: PropTypes.string,
            errorMessage: PropTypes.string,
            showMessage: PropTypes.bool,
            valid: PropTypes.bool,
            disabled: PropTypes.bool
        }),
        region: PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string,
            placeholder: PropTypes.string,
            errorMessage: PropTypes.string,
            showMessage: PropTypes.bool,
            valid: PropTypes.bool,
            disabled: PropTypes.bool
        }),
        city: PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string,
            placeholder: PropTypes.string,
            errorMessage: PropTypes.string,
            showMessage: PropTypes.bool,
            valid: PropTypes.bool,
            disabled: PropTypes.bool
        }),
        province: PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string,
            placeholder: PropTypes.string,
            errorMessage: PropTypes.string,
            showMessage: PropTypes.bool,
            valid: PropTypes.bool,
            disabled: PropTypes.bool
        }),
        country: PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.string,
            placeholder: PropTypes.string,
            errorMessage: PropTypes.string,
            showMessage: PropTypes.bool,
            valid: PropTypes.bool,
            disabled: PropTypes.bool
        })
    }),
    autoCompleteSuburb: PropTypes.shape({
        show: PropTypes.bool,
        suburbSuggestions: PropTypes.array,
        handleSearch: PropTypes.func,
        handleSuggestionClick: PropTypes.func,
        transformCompleteRequest: PropTypes.func
    }),
    handleChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    regions: PropTypes.array
};

export default ManualAddressContainer;
