import React, {Component} from 'react';
import PropTypes from 'prop-types';
import debounce from 'debounce-promise';
import SelectContainer from 'aligentreact/build/containers/SelectContainer/SelectContainer';
import InputContainer from 'aligentreact/build/containers/InputContainer/InputContainer';
import GeolocateButton from 'aligentreact/build/components/GeolocateButton/GeolocateButton';
import AJAX from 'Util/AJAX';
import {
    propShapeConfiguration
} from '../../propTypes/store-locator';
import ReactInputSlider from './ReactInputSlider';

/*
    It is believed that "default_geolocation" will not be used anywhere else
    hence, it is put in Search component, where "default_geolocation" is used
*/
const default_geolocation = {
    "au": {
        "lat" : -37.8136276,
        "lng" : 144.9630576
    },
    "nz": {
        "lat" : -36.8484597,
        "lng" : 174.7633315
    }
}

class Search extends Component {
    constructor(props) {
        super(props);
        this.id = 'store-locator-search';

        const urlParams = new URLSearchParams(window.location.search);

        this.defaultCenter = props.configuration.defaultCenter;
        this.allowedCountry = props.configuration.allowedCountry;
        this.rangeType = props.configuration.rangeType;
        this.storeType = props.configuration.storeType;
        this.searchRequestUrl = props.configuration.searchRequestUrl;
        this.showCurveFilter = props.configuration.showCurveFilter;
        if (this.showCurveFilter) {
            this.curveFilterDisplay = 'inherit';
        } else {
            this.curveFilterDisplay = 'none';
        }
        this.state = {
            country: props.configuration.defaultCountry,
            location: null,
            radius: 30,
            message: null,
            type: 'all'
        };

        if (urlParams.get('curve_only') === 'true') {
            this.state.type = 'curve';
        }

        this.debouncedHandleSearch = debounce(this.handleSearch, 250);
    };

    componentDidMount(){
        // get a list of all stores when the page is initially loaded
        this.getAllStores();
    }

    /*
        Get all stores in Australia or New Zealand
        - This is just a workaround because we want to re-use
        the existing Aligent-based API
        - Make a request to Aligent-based API with lat, long, and radius:
            * lat, long are set to Melbourne's or New Zealand's lat and long (depends on defaultContry)
            * radius is set 100,000
    */
    getAllStores = () => {
        try{
            const { lat, lng } = this.defaultCenter;

            this.handleGeolocation({
                coords: {
                    latitude: parseFloat(lat),
                    longitude: parseFloat(lng)
                },
                custom_radius: 100000
            });
        }catch(e){
            // do nothing for now when throws error
            // console.log(e);
        }
    }

    handleSearch = (req) => {
        AJAX.get(`${this.searchRequestUrl}?${req}`)
            .then(({ data }) => this.handleSearchResponse(data));
    };

    handleSearchResponse = (data) => {
        if (data.results && data.results.total > 0){
            /**
             * Updates country based on location (geo or address)
             */
            const { country } = data.results.results[0];
            if (country !== this.state.country) {
                this.setState({ country: country, region: country });
            }
            this.props.onSearchSuccess(data.results.results);
        }else{
            this.props.onSearchFailure('No results found!');
        }
    }

    handleStoreTypeChange = ({ target: { name, value } }) => {
        this.setState({'type': value});
    }

    handleInputChange = ({ target: { name, value } }) => {
        const property = (name === 'region' ? 'country' : name);
        this.setState({ [property]: value });
    };

    handleRadiusChange = ({ x: radius }) => {
        this.setState({
            radius: parseFloat(radius)
        }, () => {
            this.findStoreLocation();
        });
    };

    /**
     * Applies lat/lng to location field
     * Country is updated from response
     */
    handleGeolocation = ({ coords: { latitude, longitude }, custom_radius }) => {

        this.setState({
            message: null
        });

        const _radius = custom_radius ? custom_radius : (this.state.radius+1);

        // if custom_radius parameter is defined, use it. Otherwise, use radius from the state
        if(!custom_radius){
            // only set latitude & longitude when custom_radius is undefined
             this.setState({
                location: `${latitude}, ${longitude}`,
            });
        }

        this.debouncedHandleSearch(`longitude=${longitude}&latitude=${latitude}&radius=${_radius}&type=${this.state.type}`);
    };

    handleSubmitSearch = (e) => {
        e.preventDefault();
        this.findStoreLocation();
    };

    findStoreLocation = () => {
        const { location, radius } = this.state;

        /**
         * if incomplete
         */
        if (location === null || location === '') {
            this.setState({ message: 'Please complete form' });
            this.props.onSearchFailure();
            return;
        } else {
            this.setState({ message: null });
        }

        /**
         * if geolocation
         */
        const arrLoc = location.split(' ').join('').split(',');
        if (arrLoc.length === 2) {
            if (!isNaN(parseFloat(arrLoc[0])) && !isNaN(parseFloat(arrLoc[1]))){
                this.handleGeolocation({
                    coords: {
                        latitude: parseFloat(arrLoc[0]),
                        longitude: parseFloat(arrLoc[1])
                    }
                });

                return;
            }
        }

        /**
         * if address
         */
        this.debouncedHandleSearch(`address=${this.state.location}&components[country]=${this.state.country}&radius=${radius+1}&type=${this.state.type}`);
    };

    renderMessage = () => {
        const { message } = this.state;

        return message !== null ? (
            <div className={`${this.id}__message`}>
                <p>{message}</p>
            </div>
        ) : null;
    };

    render() {
        return (
            <div className={`${this.id}__form`}>
                <h2>Stores</h2>

                <div className={`${this.id}__country`}>
                    <SelectContainer
                        id="select-country"
                        name="country"
                        label="Country"
                        value={this.state.country}
                        options={this.allowedCountry}
                        handleChange={this.handleInputChange}
                    />
                </div>

                <div className={`${this.id}__stores`} style={{display: this.curveFilterDisplay}}>
                    <SelectContainer
                        id="select-store-type"
                        name="store-type"
                        label="Store Type"
                        value={this.state.type}
                        options={this.storeType}
                        handleChange={this.handleStoreTypeChange}
                    />
                </div>

                <form className={`${this.id}__location-submit`} onSubmit={this.handleSubmitSearch}>
                    <InputContainer
                        id="search-location"
                        name="location"
                        label="Location"
                        placeholder={this.props.placeholder}
                        value={this.state.location || ''}
                        onChange={this.handleInputChange}
                    />
                    <button type="submit" className={`${this.id}__submit`}>Find</button>
                </form>

                <div className={`${this.id}__radius`}>
                    <label className={`${this.id}__radius--label`}>Radius</label>
                    <ReactInputSlider
                        className="radius-slider"
                        axis='x'
                        xstep={10}
                        xmin={0}
                        xmax={100}
                        x={this.state.radius}
                        onChange={this.handleRadiusChange}
                    />
                    <div className={`${this.id}__radius--value`}>{`${this.state.radius}kms`}</div>
                </div>

                <div className={`${this.id}__geolocation`}>
                    <GeolocateButton handleGeolocation={this.handleGeolocation}>Use my location</GeolocateButton>
                </div>

                {this.renderMessage()}
            </div>
        );
    }
}

Search.propTypes = {
    configuration: propShapeConfiguration,
    onSearchSuccess: PropTypes.func.isRequired,
    onSearchFailure: PropTypes.func.isRequired,
    placeholder: PropTypes.string
};

Search.defaultProps = {
    placeholder: 'Enter city, town, postcode or suburb'
};

export default Search;
