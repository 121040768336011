/**
 * Parse an address into its various parts, accounting for when the passed address is undefined
 *
 * @param {Object|undefined} address The default address set for a user, or undefined if no address exists
 *
 * @returns {Object}
 */
export default (address) => {

    let street = [address && address.street ? address.street[0] : ''];

    if (address && address.street && address.street[1]) {
        street.push(address.street[1]);
    }
    
    return {
        street: street,
        suburb: address && address.city ? address.city : '',
        region: address && address.region.region ? address.region.region : '',
        city: address && address.city ? address.city : (address && address.region ? address.region : ''),
        postcode: address && address.postcode ? address.postcode : '',
        telephone: address && address.telephone ? address.telephone : '',
        company: address && address.company ? address.company : ''
    };
};
