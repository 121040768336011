/**
 * Simple function to validate postcode NZ and AU postcode
 *
 * @param {string} country
 * @param {string} postcode
 *
 * @returns {Boolean}
 */
export default (country, postcode) => {
    if (country === 'AU' || country === 'NZ') {
        return /^[0-9]{3,4}$/.test(postcode);
    }
    return postcode !== '';
};
