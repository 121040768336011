/**
 * Delivery Form React Component
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AutoCompleteContainer from './AutoCompleteContainer';
import ManualAddressContainer from './ManualAddressContainer';
import SelectContainer from 'aligentreact/build/containers/SelectContainer/SelectContainer';
import addressPropShape from 'aligentreact/build/utils/default-prop-types';
import { address } from '../../propTypes';

class DeliveryForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            displayAddressBook: this.props.customerAddresses && this.props.customerAddresses.length > 0,
        };
    }

    componentDidMount() {
        if (this.props.onComponentDidMount) {
            this.props.onComponentDidMount();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.onComponentDidUpdate) {
            this.props.onComponentDidUpdate(prevProps, prevState, snapshot);
        }
    }

    /**
     * Get the options for the address book select
     * This will include the "New Address" option, that will be used to trigger an input field
     *
     * @returns {object}
     */
    getOptions = () => {
        const addresses = this.props.customerAddresses.map((address) => {
            const region = this.extractRegion(address);

            return {
                label: `${address.street.join(' ')}, ${address.city} ${region || ''}${address.postcode}`,
                value: address.id
            };
        });

        // Add the extra "New Address" option, which will re-render the component with a blank input field
        return [
            ...addresses,
            {
                label: 'New Address',
                value: 'new'
            }
        ];
    };

    /**
     * Get the default shipping address
     *
     * If no default shipping address is found, then the function will return an empty string
     *
     * @returns { {label: string, value: *} | string }
     */
    getSelectedAddress = () => {
        if (!this.props.defaultAddresses) {
            return '';
        }

        const selectedAddress = this.props.customerAddresses
            .find(address => parseInt(address.id, 10) === parseInt(this.props.defaultAddresses.shipping, 10));

        if (!selectedAddress) {
            return '';
        }

        return selectedAddress.id;
    };

    /**
     * Handle the event fired when the user selects a different saved address
     *
     * @param {object} selected The selected object
     *
     * @returns {void}
     */
    handleAddressBookChange = (selected) => {
        const { value } = selected.target;

        if (value === 'new') {
            // trigger event handler if set
            const { handleSelectNewAddress } = this.props;
            if (handleSelectNewAddress) handleSelectNewAddress();

            this.setState({
                ...this.state,
                displayAddressBook: false
            });

            return;
        }

        //Retrict change for CA
        const selectedAddress = this.props.customerAddresses.filter(address => address.id === value)[0];
        if(selectedAddress.country_id == 'CA') {
            const region = this.extractRegion(selectedAddress);
            const oneLineAddress = `${selectedAddress.street.join(' ')}, ${selectedAddress.city} ${selectedAddress.postcode}`;
            this.props.onSelectAddressBook(oneLineAddress);
        }
        else {
            this.props.onSelectAutosuggest(selectedAddress);
        }

    };

    /**
     * Extract the region from the provided address data.
     *
     * @param {object} addressData An object containing all of the address data
     *
     * @returns {boolean|string}
     */
    extractRegion = (addressData) => {
        return addressData.region && addressData.region.region && `${addressData.region.region} `;
    };

    /**
     * Extract the region from the provided address data.
     *
     * @param {string} shippingLocation The city or suburb of the current shipping location
     *
     * @returns {boolean}
     */
    isInvalidShippingLocation = (region, postcode) => {
        if (!window.shippingAddressFilter) {
            return false;
        }

        const invalidRegions = window.shippingAddressFilter.invalidRegions || [];
        const invalidPostcodeDistricts = window.shippingAddressFilter.invalidPostcodeDistricts || [];

        if (region && postcode) {
            if (invalidRegions.includes(region)) {
                if (postcode.length>1) {
                    let match = postcode.trim();   //match entire postcode by default
                    //check for space or - in postcode
                    const splitSpace = match.split(' ');
                    const splitHypen = match.split('-');
                    if (splitSpace.length>1) {
                        //match postal district only
                        match = splitSpace[0];
                    } else if (splitHypen.length>1) {
                        //match postal district only
                        match = splitHypen[0];
                    }
                    if (invalidPostcodeDistricts.includes(match)) { return true };
                }
            }
        }

        return false;
    };

    /**
     * Render a SelectContainer with pre-filled addresses from a users address book
     *
     * @returns {Element|null}
     */
    renderAddressBook() {
        if (!this.state.displayAddressBook) {
            return null;
        }

        const { invalidAddressError } = this.props.address;

        return (
            <Fragment>
                <SelectContainer
                    id={`delivery-form-address_book-${this.props.id}`}
                    className="delivery-form__address-book"
                    label=""
                    options={this.getOptions()}
                    value={this.getSelectedAddress()}
                    handleChange={this.handleAddressBookChange}
                    isValid={true}
                />
                {invalidAddressError && <div className="invalid-address-error">{invalidAddressError}</div>}
            </Fragment>
        );
    };

    /**
     * Render the AutoCompleteContainer
     *
     * @returns {Element|null}
     */
    renderAutoCompleteAddress() {
        if (!this.props.displayOneLineAddress || this.state.displayAddressBook) {
            return null;
        }

        return (
            <Fragment>
                <AutoCompleteContainer
                    id={`delivery-address-${this.props.id}`}
                    name="oneLineAddress"
                    label="Address"
                    handleSearch={this.props.handleSearch}
                    handleClick={this.props.onSelectAutosuggest}
                    value={this.props.oneLineAddress.value}
                    isValid={true}
                    transformCompleteRequest={this.props.transformCompleteRequest}
                    minCharacters={this.props.minCharacters}
                    customerAddresses={this.props.customerAddresses}
                    defaultAddresses={this.props.defaultAddresses}
                    placeholder={this.props.oneLineAddress.placeholder}
                    handleChange={this.props.handleChangeAutoCompleteInput}
                />
            </Fragment>
        );
    };

    /**
     * Render the ManualAddressContainer input
     *
     * @returns {Element|null}
     */
    renderManualAddressForm() {
        if (this.props.displayOneLineAddress || this.state.displayAddressBook) {
            return null;
        }

        const { invalidAddressError } = this.props.address;

        return (
            <Fragment>
                <ManualAddressContainer
                    id={`delivery-address-${this.props.id}`}
                    handleChange={this.props.onManualAddressChange}
                    address={this.props.address}
                    regions={this.props.regions}
                    autoCompleteSuburb={this.props.autoCompleteSuburb}
                />
                {invalidAddressError && <div className="invalid-address-error">{invalidAddressError}</div>}
            </Fragment>
        );
    };

    renderAddressToggle() {
        if (this.state.displayAddressBook) {
            return null;
        }

        return this.props.fullFormToggleLabel && (
            <div className="delivery-form__toggle-address-input">
                <p className="delivery-form__label">{this.props.fullFormToggleInfo}</p>
                <button className="delivery-form__button--toggle-full-address" onClick={this.props.handleToggleFullForm}>
                    {this.props.fullFormToggleLabel}
                </button>
            </div>
        );
    }

    renderInvalidDeliveryAddress() {
        return this.props.address && this.props.address.suburb && this.props.address.suburb.value && this.isInvalidShippingLocation(this.props.address.suburb.value, this.props.address.postcode.value) ?
            (
                <div className="invalid-delivery-address" style={{ color: '#ff7473' }}>Sorry, We do not ship to this location.</div>
            ) : null;
    }

    render() {
        return (
            <section className="delivery-form-container">
                <div className="address-container">
                    {this.renderAddressBook()}
                    {this.renderAutoCompleteAddress()}
                    {this.renderManualAddressForm()}
                    {this.renderInvalidDeliveryAddress()}
                </div>
                {this.renderAddressToggle()}
            </section>
        );
    }

}

DeliveryForm.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    handleChangeAutoCompleteInput: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    displayOneLineAddress: PropTypes.bool.isRequired,
    onSelectAutosuggest: PropTypes.func.isRequired,
    onSelectAddressBook: PropTypes.func.isRequired,
    oneLineAddress: PropTypes.shape({
        value: PropTypes.string.isRequired,
        placeholder: PropTypes.string
    }),
    onManualAddressChange: PropTypes.func.isRequired,
    regions: PropTypes.array.isRequired,

    address: PropTypes.shape(address),
    autoCompleteSuburb: PropTypes.shape({
        show: PropTypes.bool,
        suburbSuggestions: PropTypes.array,
        handleSearch: PropTypes.func,
        handleSuggestionClick: PropTypes.func,
        transformCompleteRequest: PropTypes.func
    }),
    customerAddresses: PropTypes.array,
    defaultAddresses: PropTypes.shape({
        billing: PropTypes.string,
        shipping: PropTypes.string
    }),
    fullFormToggleInfo: PropTypes.string,
    fullFormToggleLabel: PropTypes.string,
    handleSelectNewAddress: PropTypes.func,
    handleToggleFullForm: PropTypes.func,
    minCharacters: PropTypes.number,
    onComponentDidMount: PropTypes.func,
    onComponentDidUpdate: PropTypes.func,
    transformCompleteRequest: PropTypes.func
};

DeliveryForm.defaultProps = {
    label: 'Delivery Form',
    fullFormToggleInfo: 'Can\'t find your address?',
    fullFormToggleLabel: 'Try full form'
};

export default DeliveryForm;
