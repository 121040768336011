import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';
import Markers from "./Markers";
import {
    propShapeConfiguration,
    propShapeLocations
} from '../../propTypes/store-locator';


const Map = ({
    configuration, locations, activeLocation,
    onSelectLocation, onSelectDirections
}) => {
    const defaultCenter = locations ? {lat: parseFloat(locations[0].latitude), lng: parseFloat(locations[0].longitude)} : configuration.defaultCenter;
    const defaultZoom = configuration.defaultZoom;

    const MapComponent = compose(
        withProps({
            googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${configuration.apiKey}`,
            loadingElement: <div className="loader" style={{ height: `100%` }} />,
            containerElement: <div className="container" />,
            mapElement: <div className="map" />,
            defaultCenter: defaultCenter,
            defaultZoom: defaultZoom
        }),
        withScriptjs,
        withGoogleMap
    ) (props =>
        <GoogleMap defaultZoom={props.defaultZoom}
                   defaultCenter={props.defaultCenter}
        >
            <Markers
                locations={locations}
                activeLocation={activeLocation}
                onSelectLocation={onSelectLocation}
                onSelectDirections={onSelectDirections}
            />
        </GoogleMap>
    );

    return <MapComponent/>
};

Map.propTypes = {
    configuration: propShapeConfiguration,
    locations: propShapeLocations,
    onSelectLocation: PropTypes.func.isRequired,
    onSelectDirections: PropTypes.func.isRequired,
    activeLocation: PropTypes.number
};

export default Map;
