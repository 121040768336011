import PropTypes from 'prop-types';

export default {
    firstname: PropTypes.shape({
        value: PropTypes.string.isRequired,
        valid: PropTypes.bool
    }),
    lastname: PropTypes.shape({
        value: PropTypes.string.isRequired,
        valid: PropTypes.bool
    }),
    email: PropTypes.shape({
        value: PropTypes.string.isRequired,
        valid: PropTypes.bool
    }),
    telephone: PropTypes.shape({
        value: PropTypes.string.isRequired,
        valid: PropTypes.bool
    }),
    company: PropTypes.shape({
        value: PropTypes.string,
        valid: PropTypes.bool
    })
};
