/**
 * @typedef {Object} SignUpContent
 * @property {string} BIRTHDAY_CONTENT
 * @property {string} NEWSLETTER_CONTENT
 * @property {string} TERMS_AND_CONDITIONS_CONTENT
 */

/**
 * Gets the appropriate SignUp content for the country
 *
 * @param {string}          defaultCountryId        ID of the country
 * @returns {SignUpContent}                         Content of the checkbox in the sign up form
 */
export default function getSignUpContentFromCountry(defaultCountryId) {
    switch (defaultCountryId) {
        case 'CA':
            return {
                BIRTHDAY_CONTENT: 'So we can give you your 15% gift voucher on your birthday',
                NEWSLETTER_CONTENT: 'Sign up to our newsletter',
                TERMS_AND_CONDITIONS_CONTENT: 'I have read and accept the Ever New'
            }
        case 'GB':
            return {
                BIRTHDAY_CONTENT: 'So we can give you your £25 gift voucher on your birthday',
                NEWSLETTER_CONTENT: 'I agree to receive email marketing communications from Forever New',
                TERMS_AND_CONDITIONS_CONTENT: 'I have read and accept the'
            }
        case 'NZ':
        case 'AU':
        default:
            return {
                BIRTHDAY_CONTENT: 'So we can send you your $25 off birthday discount code',
                NEWSLETTER_CONTENT: '',
                TERMS_AND_CONDITIONS_CONTENT: ''
            };
    }
}
