import PropTypes from 'prop-types';


export const propTypeStringOrNumber = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
]);

export const propShapeValueLabel = PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
});

export const propShapeConfiguration = PropTypes.shape({
    allowedCountry: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
        is_region_visible: PropTypes.bool
    })),
    apiKey: PropTypes.string,
    defaultCenter: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    }),
    defaultCountry: PropTypes.string,
    defaultZoom: PropTypes.number,
    rangeType: PropTypes.arrayOf(propShapeValueLabel),
    searchRequestUrl: PropTypes.string,
    storeType: PropTypes.arrayOf(propShapeValueLabel)
});

export const propShapeLocations = PropTypes.arrayOf(PropTypes.shape({
    administrative_area: PropTypes.string,
    building_name: PropTypes.string,
    city: PropTypes.string,
    click_collect_available: PropTypes.string,
    country: PropTypes.string,
    created_at: PropTypes.string,
    distance: PropTypes.string,
    entity_id: PropTypes.string,
    extended_hours: PropTypes.array,
    id: PropTypes.string,
    identifier: PropTypes.string,
    increment_id: PropTypes.string,
    is_active: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    name: PropTypes.string,
    phone_number: PropTypes.string,
    postcode: PropTypes.string,
    state: PropTypes.string,
    store_ids: PropTypes.string,
    street: PropTypes.string,
    street2: PropTypes.string,
    trading_hours: PropTypes.string,
    units: PropTypes.string,
    updated_at: PropTypes.string,
    url_key: PropTypes.string,
    zoom: PropTypes.string,
}));