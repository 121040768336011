/**
 * Google Tag Manager React Component
 */

 import React, { Component } from 'react';
 import PropTypes from 'prop-types';
 import { deepEqual } from 'fast-equals';
 import { getProductOption } from '../../Util/functions';
 import { getGoogleAnalytics4 } from '../../Util/functions';
 
 class Gtm extends Component {
 
     shouldComponentUpdate(nextProps, nextState) {
         // Sort the quoteItemData so conditionals further on in the function are comparing equally
         const curSortedItems = this.props.quoteItemData.sort((itemId1, itemId2) => itemId1 - itemId2);
         const nextSortedItems = nextProps.quoteItemData.sort((itemId1, itemId2) => itemId1 - itemId2);
 
         const curItemIds = curSortedItems.map(item => item.item_id).map(itemId => parseInt(itemId, 10));
         const nextItemIds = nextSortedItems.map(item => item.item_id).map(itemId => parseInt(itemId, 10));
 
         // If the item ids aren't the same, then the data has been updated and therefore so should the dataLayer be
         if (!deepEqual(curItemIds, nextItemIds)) {
             return true;
         }
 
         // At this point, the itemIds are the same, and in the same order, so each corresponding qty in both arrays below are for the same product
         const curItemQtys = curSortedItems.map(item => item.qty).map(itemQty => parseInt(itemQty, 10));
         const nextItemQtys = nextSortedItems.map(item => item.qty).map(itemQty => parseInt(itemQty, 10));
 
         if (!deepEqual(curItemQtys, nextItemQtys)) {
             return true;
         }
 
         // I am commenting out the following conditional, but leaving it here, as I'm not 100% certain it isn't going to be needed
         // Having this here will cause the data layer to be updated twice when the size configurable is changed.
         // This is due to the datalayer being updated due to this condional, but then also being updated when the Magento
         // request resolves, as the Item ID's will then be different (each size corresponds to a different item ID)
         // Removing this conditional means that the data layer will theoretically only be updated once that request is resolved, and the
         // item ID's no longer match.
         // The following code may be removed in the future, once it is confirmed that it isn't required, and solely relying
         // on Item ID's changing is enough
 
         // const curConfiguratbles = curSortedItems.map(item => item.used_attributes);
         // const nextConfiguratbles = nextSortedItems.map(item => item.used_attributes);
         //
         // if (!deepEqual(curConfiguratbles, nextConfiguratbles)) {
         //     return true;
         // }
 
         return false;
     }
 
     /**
      * Push product data to the dataLayer ecommerce checkout products object
      * Each push will create a unique Gtm object (gtm.uniqueEventId) so each
      * item change will be registered for the checkout funnel analysis
      *
      * @returns {null}
      */
     pushGtmDatalayerProducts() {
        let sessionID = '';
        let email = '';
        let phone = '';
        if (window.dataEmarsys && window.dataEmarsys.customer) {
            sessionID = window.dataEmarsys.customer.data_id || '';
            email = window.dataEmarsys.customer.email || '';
            phone = window.dataEmarsys.customer.phone || '';
        }

        const legacyProductList = this.getItemDataForGtm();
        const ga4ProductList = this.getItemDataForGA4();
        window.dataLayer.push({
            event: 'checkout',

            //enhanced conversion tracking
            sessionID: sessionID,
            email: email,
            phone: phone,

            ecommerce: {
                //ga4 version
                actionField: { step: "1", decription: "cart" },
                products: ga4ProductList,

                //ga 360 version (keep for legacy data)
                checkout: {
                    actionField: { step: '1', description: 'cart' },
                    products: legacyProductList,
                },

                //both
                currencyCode: this.props.totalsData.base_currency_code
            }
        });
     };

     getItemCategory = function(productID) {
        let output = '';
        if (window.productList && window.productList.length>0) {
            for (let i=0, len=window.productList.length; i<len; i++) {
                if (window.productList[i].productID==productID) {
                    output = window.productList[i].productCategory;
                    break;
                }
            }
        }
        return output;
    }
 
     /**
      * Populate dataLayer products with quoteItems formatted for legacy GA360
      *
      * @returns {Object} - product data
      */
     getItemDataForGtm() {
         return this.props.quoteItemData.map((item) => {
             const { name,
                 sku: id,
                 base_price_incl_tax, // same for regular and sales price
                 qty: quantity
             } = item;
             let itemColour = getProductOption(item, 'fashion_colour');
             const itemSize = getProductOption(item, 'size');
             const formattedPrice = parseFloat(base_price_incl_tax).toFixed(2);
             const categoryIds = item.product.category_ids;
             const catResult = this.setProductCategory(categoryIds);
             const categoryName = this.getItemCategory(id);

             if (itemColour && itemColour.length>0 && itemColour.charAt(itemColour.length-1)==='.') {
                itemColour = itemColour.substring(0, itemColour.length-1);
             }
 
             return {
                 name,
                 id,
                 price: formattedPrice,
                 brand: 'Forever New',
                 category: catResult && catResult!=='' ? catResult : categoryName,
                 variant: `${itemColour ? itemColour.value : 'No-Colour'}_${itemSize ? itemSize.value : ''}`,
                 quantity,
                 color_level_id:item.color_level_id?item.color_level_id:'',
                 style_level_id:item.product.sku
             };
         });
     }

     /**
      * Populate dataLayer products with quoteItems formatted for GA4
      *
      * @returns {Object} - product data
      */
     getItemDataForGA4() {
        return this.props.quoteItemData.map((item) => {
            const { name,
                sku: id,
                base_price_incl_tax, // same for regular and sales price
                qty: quantity
            } = item;
            let itemColour = getProductOption(item, 'fashion_colour');
            const itemSize = getProductOption(item, 'size');
            const formattedPrice = parseFloat(base_price_incl_tax).toFixed(2);
            const categoryIds = item.product.category_ids;
            const catResult = this.setProductCategory(categoryIds);
            const categoryName = this.getItemCategory(id);

            if (itemColour && itemColour.length>0 && itemColour.charAt(itemColour.length-1)==='.') {
               itemColour = itemColour.substring(0, itemColour.length-1);
            }

            return {
                item_id: id || '',
                item_name: name || '',
                affiliation: "",
                coupon: "",
                currency: this.props.totalsData.base_currency_code || '',
                discount: 0,
                index: 0,
                item_brand: "Forever New",
                item_category: catResult && catResult!=='' ? catResult : categoryName,
                item_category2: "",
                item_category3: "",
                item_category4: "",
                item_category5: "",
                item_list_id: "",
                item_list_name: "",
                item_variant: `${itemColour ? itemColour.value : 'No-Colour'}_${itemSize ? itemSize.value : ''}`,
                location_id: "",
                price: formattedPrice,
                quantity,
                magento_id: "",
                style_level_id: item.product.sku,
                color_level_id: item.color_level_id ? item.color_level_id : '',
                sku_id: id || '',
            };
        });
    }
 
     /**
      * Return only the parent categories from
      * the current store since product category_ids
      * contains parent categories from all stores
      *
      * @param {Object} categoryIds
      *
      * @returns {Object} categories
      */
     setProductCategory(categoryIds) {
         // retrieving from window directly
         // or better to pass as prop instead?
         const storeCategoriesJson = window.storeCategories;
         const storeCategories = [];
         // turn JSON into array
         for (const category in storeCategoriesJson) {
             storeCategories.push(category);
         }
         // only return DL categories that exist in store
         return storeCategories
                 .filter(category => categoryIds.includes(category))
                 .toString();
     }
 
     render() {
         // if window.dataLayer, push right away
         if (window.dataLayer) {
             this.pushGtmDatalayerProducts();
         } else {
         // else wait for custom gtm_loaded event
             window.addEventListener('gtm_loaded', () => {
                if (window.dataLayer && window.dataLayer.length>0) {
                    if (window.dataLayer[window.dataLayer.length-1].event!=='checkout' ||
                        window.dataLayer[window.dataLayer.length-1][1]==='checkout') {
                        this.pushGtmDatalayerProducts();
                    }
                }
             });
         }
         return (
             null
         );
     }
 }
 
 Gtm.propTypes = {
     quoteItemData: PropTypes.array,
     totalsData: PropTypes.shape({
         base_currency_code: PropTypes.string
     })
 };
 
 export default Gtm;
 