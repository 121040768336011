/**
 * Process the address details into a format expected when submitting the checkout
 *
 * @param {Object} customer  The customer details object to process
 * @param {Object} address   The address object to process
 * @param {string} countryId 2 digit country ID
 *
 * @returns {Object}
 */
export default (customer, address, countryId) => {
    const {
        firstname,
        lastname,
        company,
        email,
        telephone
    } = customer;
    const {
        street,
        suburb,
        region = false,
        city = false,
        postcode
    } = address;

    let formattedStreet = street.value;

    if (typeof street.value === 'string') {
        if (street.value.length >= 40) {
            let splitAtPos = street.value.lastIndexOf(' ', 39);
            formattedStreet = [
                street.value.substr(0, splitAtPos),
                street.value.substr(splitAtPos, street.value.length)
            ];
        } else {
            formattedStreet = [
                street.value
            ];
        }
    } else if (Array.isArray(street) && typeof street[0].value === 'string') {
        formattedStreet = street.filter(element => (element.value !== '')).map(element => element.value);
    }

    return {
        city: suburb.value,
        company: company.value,
        countryId,
        extensionAttributes: {
            // checkoutFields: {},
            atl_signature: address.atl_signature ? 'true' : 'false',
            atl_instructions: address.atl_instructions
        },
        firstname: firstname.value,
        lastname: lastname.value,
        email: email.value,
        postcode: postcode.value,
        ...region && { region: region.value },
        ...city && { city: city.value },
        saveInAddressBook: null,
        street: formattedStreet,
        telephone: telephone.value
    };
};
