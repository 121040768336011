/**
 * Gets the appropriate suburb field label for the country
 *
 * @param {string}      defaultCountryId    ID of the country
 * @returns {string}                        Label for the country
 */
export default function getSuburbLabelForCountry(defaultCountryId) {
    switch (defaultCountryId) {
        case 'NZ':
            return 'city';
        case 'GB':
            return 'city';
        case 'AU':
        case 'CA':
        default:
            return 'suburb';
    }
}
