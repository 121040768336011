import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'debounce-promise';
import AutoComplete from 'aligentreact/build/components/AutoComplete/AutoComplete';
import InputContainer from './InputContainer';

class AutoCompleteContainer extends Component {

    constructor(props) {
        super(props);

        this.transformCompleteRequest = this.props.transformCompleteRequest || (response => response);

        this.state = {
            suggestions: [],
            minCharacters: this.props.minCharacters || 0
        };

        this.getResultsDebounced = debounce(this.getResults, 400);
    }

    clickResult = (selectedResult) => {
        const selectedSuggestion = this.state.suggestions.find((suggestion, index) => selectedResult === index);

        this.props.handleClick(selectedSuggestion);
        this.clearResults();
    };

    getResults = async () => {
        this.setState({
            suggestions: [{
                value: 'Searching...',
                selectable: false
            }]
        });

        const response = await this.props.handleSearch(this.props.value);

        this.setState({
            suggestions: this.transformCompleteRequest(response)
        });
    };

    clearResults = () => {
        this.setState({
            suggestions: []
        });
    };

    handleChange = (event) => {
        this.props.handleChange(event);

        if (this.props.value.length >= this.state.minCharacters) {
            this.getResultsDebounced();
        } else {
            this.clearResults();
        }
    };

    /**
     * Render with a text input that will be auto completed
     *
     * @returns {object}
     */
    renderInput() {
        return (
            <InputContainer
                id={this.props.id}
                name={this.props.name}
                label={this.props.label}
                placeholder={this.props.placeholder}
                autoComplete="never"
                errorMessage={this.props.errorMessage || `Please enter a valid ${this.props.name}`}
                showMessage={this.props.showMessage || true}
                value={this.props.value}
                isValid={this.props.isValid}
                onChange={this.handleChange}
                disabled={this.props.disabled}
                isRequired={true}
            />
        );
    }

    render() {
        return (
            <div className="autocomplete-container">
                {this.renderInput()}
                <AutoComplete
                    suggestions={this.state.suggestions}
                    onClick={this.clickResult}
                />
            </div>
        );
    }

}

AutoCompleteContainer.propTypes = {
    id: PropTypes.string.isRequired,
    isValid: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    handleSearch: PropTypes.func.isRequired,
    handleClick: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,

    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
    label: PropTypes.string,
    minCharacters: PropTypes.number,
    placeholder: PropTypes.string,
    showMessage: PropTypes.bool,
    transformCompleteRequest: PropTypes.func
};

export default AutoCompleteContainer;
