/**
 * WebPOS Customer Search Component
 */
import React, { Component } from 'react';
import debounce from 'debounce-promise';
import InputContainer from 'aligentreact/build/containers/InputContainer/InputContainer';
import AJAX from '../Util/AJAX';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
            results: [],
            ...(JSON.parse(props.mountData))
        };
    }

    handleChange = ({ target }) => {
        this.setState({
            searchTerm: target.value
        }, () => {
            this.searchCustomer();
        });
    };

    /**
     * Search for a specified customer
     */
    searchCustomer = debounce(async () => {
        // If there is a non-numeric character in the search term, then treat as email
        const searchAction = (/[a-zA-Z]/.test(this.state.searchTerm)) ? 'email' : 'mobile';

        try {
            const formData = new FormData();
            formData.append(searchAction, this.state.searchTerm);
            const { data: { data: results } } = await AJAX.post(`/loginas/search/${searchAction}`, formData);

            this.setState({
                results: results.sort((resultA, resultB) => {
                    // Simply sort results alphabetically by first name
                    // Using lowercase ensures no issues comparing upper and lower case names
                    const nameA = resultA.customer_firstname.toLowerCase();
                    const nameB = resultB.customer_firstname.toLowerCase();

                    if (nameA < nameB) {
                        return -1;
                    }

                    if (nameA > nameB) {
                        return 1;
                    }

                    return 0;
                })
            });

        } catch ({ response }) {
            // @TODO Implement a proper error reporting framework
            //alert('There was an error while searching');
        }
    }, 250);

    /**
     * Send request to login as a selected user
     *
     * @param {int} customerId The ID of the customer to login as
     *
     * @returns {null}
     */
    loginAs = async (customerId) => {
        try {
            const formData = new FormData();
            formData.append('id', customerId);
            const { data: { success, message, data } } = await AJAX.post('/loginas/customer/login', formData);

            if (!success) {
                // @TODO Implement a proper error reporting framework
                alert(message);
                return;
            }

            // Navigate the user to the home page so they can start browsing as the user they logged in as
            window.location.replace('/');

        } catch (response) {
            // @TODO Implement a proper error reporting framework
           // alert('There was an error while searching');
        }
    };

    render() {
        const isManifestEnabled = this.state.isManifestLinkEnabled === "1";
        const manifestSection = isManifestEnabled ? (
            <section className="webpos-customer__manifest-link">
                <a href="/webpos/manifest/info/">
                    Go to Australia Post Manifest Page >>
                </a>
            </section>
            ) : null;

        return (
            <div className="webpos-customer">

                {manifestSection}

                <section className="webpos-customer__container">
                    <header className="webpos-customer__header">
                        <h1 className="webpos-customer__heading webpos-customer__heading--guest">
                            Customer not registered?
                        </h1>
                        <p className="webpos-customer__sub-heading webpos-customer__sub-heading--guest">
                            If the customer doesn't have an online account registered, you can place an order as a guest checkout here.
                        </p>
                    </header>
                    <section className="webpos-customer__content webpos-customer__content--guest">
                        <a href="/?guest" className="webpos-customer__guest-button">
                            Guest Checkout
                        </a>
                    </section>
                </section>

                <section className="webpos-customer__container">
                    <header className="webpos-customer__header">
                        <h1 className="webpos-customer__heading">
                            Is the customer registered?
                        </h1>
                        <p className="webpos-customer__sub-heading">
                            If the customer already has an online account registered, you can search here and login to their account.
                        </p>
                    </header>
                    <section className="webpos-customer__content webpos-customer__content--search">
                        <InputContainer
                            id="customer-search-input"
                            name="searchTerm"
                            placeholder="Enter customers email or mobile number"
                            value={this.state.searchTerm}
                            onChange={this.handleChange}
                        />
                        <ul className="customer-results__list">
                            {!this.state.results.length && (
                                <li className="customer-result">No customers matching search term</li>
                            )}
                            {this.state.results.map((result) => {
                                const {
                                    customer_id: id,
                                    customer_firstname: firstName,
                                    customer_lastname: lastName,
                                    customer_email: email,
                                    customer_mobile: mobile
                                } = result;

                                return (
                                    <li key={id} className="customer-result">
                                        <span className="customer-result__name">{firstName} {lastName}</span>
                                        <span className="customer-result__email">{email}</span>
                                        <span className="customer-result__mobile">{mobile}</span>
                                        <button className="customer-result-button--login-as" onClick={() => { this.loginAs(id); }}>Login As {firstName}</button>
                                    </li>
                                );
                            })}
                        </ul>
                    </section>
                </section>
            </div>
        );
    }

}

App.propTypes = {};

export default App;
