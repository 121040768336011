import React from 'react';

export default (isTelephoneValid) => {
  if(!isTelephoneValid){
    return (
      <div className="validation-error">
        Invalid phone number. Please enter a valid number with no more than 15 digits
      </div>
    );
  }
  return '';
};
