/**
 * Validate an individual name field
 * @param value {string} value of the field
 * @return {{ isValid: boolean, errorMessage: string }}
 */
export const validateNameField = (value) => {
    return {
        isValid: !!value,
        errorMessage: ''
    };
}

/**
 * Validates the name fields from customer state
 *
 * @param customerState {object} customer state
 * @param countryId {string} Country ID (since the validation depends on country)
 * @return {{
 *   isFirstnameValid: boolean,
 *   isLastnameValid: boolean,
 *   firstnameErrorMessage: string,
 *   lastnameErrorMessage: string
 * }}
 */
export const validateNameFields = (customerState, countryId) => {
    const firstname = customerState.firstname.value;
    const lastname = customerState.lastname.value;

    const maxCombinedLengthMap = { 'AU': 40, 'NZ': 40, 'CA': 40, 'GB': 40 };
    const maxCombinedLength = maxCombinedLengthMap[countryId] || Infinity;
    const isTooLong = `${firstname} ${lastname}`.length > maxCombinedLength;

    if (isTooLong) {
        return {
            isFirstnameValid: false,
            isLastnameValid: false,
            firstnameErrorMessage: `Combined name fields must be less than ${maxCombinedLength} characters`,
            lastnameErrorMessage: `Combined name fields must be less than ${maxCombinedLength} characters`
        };
    }

    const firstnameValidity = validateNameField(firstname);
    const lastnameValidity = validateNameField(lastname);

    return {
        isFirstnameValid: firstnameValidity.isValid,
        isLastnameValid: lastnameValidity.isValid,
        firstnameErrorMessage: firstnameValidity.errorMessage,
        lastnameErrorMessage: lastnameValidity.errorMessage
    };
};
