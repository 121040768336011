import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * Display an error message if the data isn't valid, and the user is ready to accept errors
 *
 * @param {string}  errorMessage The error message to display
 * @param {boolean} showMessage  Boolean indicating if the user is ready to accept errors (e.g. a form has been submitted)
 *
 * @returns {*}
 */
function getErrorDiv(errorMessage, showMessage) {
    return (showMessage) ?
        <div className="validation-message">{errorMessage || 'Please enter a value'}</div> :
        <Fragment />;
}

const Input = ({
    autoComplete = "off",
    id,
    label,
    value,
    onChange,
    name = null,
    onBlur = undefined,
    type = 'text',
    placeholder = false,
    errorMessage = null,
    isRequired = false,
    isValid = true,
    showMessage = false,
    disabled = false
}) => {
    return (
        <Fragment>
            <label className="input-label" htmlFor={id}>
                {label}
                {isRequired && <span className="input-label-required" title="Required">*</span>}
            </label>
            <input
                type={type}
                id={id}
                name={name !== null ? name : id}
                value={value}
                placeholder={placeholder || label}
                onChange={onChange}
                required={isRequired}
                onBlur={onBlur}
                disabled={disabled}
                className="form-input"
                autoComplete={autoComplete}
            />
            {getErrorDiv(errorMessage, (!isValid && showMessage))}
        </Fragment>

    );
};

Input.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.bool
    ]),
    onBlur: PropTypes.func,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
    isRequired: PropTypes.bool,
    isValid: PropTypes.bool,
    showMessage: PropTypes.bool,
    disabled: PropTypes.bool
};

export default Input;
