/**
 /**
 * Payment React Component
 */
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {deepEqual} from 'fast-equals';
import {withCookies, Cookies} from 'react-cookie';
import debounce from 'debounce-promise';
import axios from 'axios/index';
import get from 'lodash.get';
import CheckboxRadio from 'aligentreact/build/components/CheckboxRadio/CheckboxRadio';
import InputContainer from 'aligentreact/build/containers/InputContainer/InputContainer';
import StaticBlock from './StaticBlock';
import TabbedUiContainer from './../../common/TabbedUiContainer';
import OrderSummaryPriceContainer
    from 'aligentreact/build/containers/OrderSummaryPriceContainer/OrderSummaryPriceContainer';
import PaymentModifierContainer from 'aligentreact/build/containers/PaymentModifierContainer/PaymentModifierContainer';
import SelectContainer from 'aligentreact/build/containers/SelectContainer/SelectContainer';
import AdyenWrapper from './AdyenWrapper';
import PaypalExpressContainer from './PaypalExpressContainer';
import ZipMoneyContainer from './ZipMoneyContainer';
import LaybuyContainer from './LaybuyContainer';
import AfterpayContent from './AfterpayContent';
import AfterpayButtonContainer from './AfterpayButtonContainer';
import ErrorContainer from './ErrorContainer';
import AJAX from '../../Util/AJAX';
import {checkCardBalance} from '../../Util/API';
import HeaderTabs from './HeaderTabs';
import Storage from '../../Util/Storage';
import {featureFlags, formatPrice} from '../../Util/functions';
import {customer} from '../../propTypes';
import AdyenCheckout from '@adyen/adyen-web';
import {isMobileUi} from '../../Util/functions';
import {FaAngleDown, FaAngleUp} from "react-icons/fa";


// import '@adyen/adyen-web/dist/adyen.css';

class Payment extends Component {
    constructor(props) {
        super(props);
        this.promoCodeRef = React.createRef();
        this.paymentSection = React.createRef();
        this.fetchAdyenFormDebounced = debounce(this.fetchAdyenForm, 500);
    }

    state = {
        totals: [],
        cardBalance: false,
        adyenError: false,
        adyenCardError: false,
        activeTabId: 0,
        paymentMethods: [],
        promoCodeFeedbackMessage: null,
        giftCardFeedbackMessage: null,
        noPaymentRequired: false,
        modifiercode: '',
        blackhawkPin: '',
        displayGiftcardPin: false,
        staticBlocks: window.staticBlocks,
        payNowEnabled: this.props.payNowEnabled,
        dropinComponent: '',
        clicked: 0
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (deepEqual(nextProps.checkoutConfig.totalsData, prevState.totals) && deepEqual(nextProps.checkoutConfig.paymentMethods, prevState.paymentMethods)) {
            return null;
        }
        return {
            ...prevState,
            totals: nextProps.checkoutConfig.totalsData,
            paymentMethods: nextProps.checkoutConfig.paymentMethods,
            // Check if the "free" payment method is available - which likely means the user has applied a gift card
            noPaymentRequired: nextProps.checkoutConfig.paymentMethods.find(method => method.code === 'free'),
            payNowEnabled: nextProps.payNowEnabled
        };
    }

    componentDidMount() {
        document.addEventListener('scroll', this.trackScrolling, true);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling, true);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.payNowEnabled !== prevState.payNowEnabled) {
            this.setState({
                payNowEnabled: this.props.payNowEnabled
            })
        }
    }

    trackScrolling = () => {
        const wrappedElement = document.getElementById('payment-container');
        if (isMobileUi() && wrappedElement.getBoundingClientRect().top < 400 && wrappedElement.getBoundingClientRect().top > 0) {
            this.props.handleScrollActivateSection(HeaderTabs.CONST__PAYMENT_LINK);
            document.removeEventListener('scroll', this.trackScrolling);
        }
    };

    choosePaypalExpressMethod = () => {
        const paypalExpressMethodId = this.getPaypalExpressMethodId();

        if (paypalExpressMethodId) {
            this.setState(
                {
                    ...this.state,
                    activeTabId: paypalExpressMethodId,
                    clicked: paypalExpressMethodId
                }
            );
            // document.querySelector('.tabbed-ui__tab--paypal_express').click();
            return true;
        }
        return false;
    };

    getPaypalExpressMethodId = () => {
        return this.props.checkoutConfig.paymentMethods.findIndex((method) => method.code === 'paypal_express');
    };

    /**
     * Get the active tab object
     *
     * @returns {Object}
     */
    getActivePaymentMethod = () => {
        return this.props.checkoutConfig.paymentMethods.find((method, index) => index === this.state.clicked);
    };

    getPricesJSX() {
        // Filter out prices with falsy values first
        return this.state.totals.total_segments.filter(price => price.value).map((price, index) => {
            return {
                id: index,
                label: price.title,
                price: parseFloat(price.value),
                currency: '$'
            };
        });
    }

    getLoyaltyRewards() {
        return this.props.checkoutConfig.loyaltyVouchers;
    }

    /**
     * Determine if there is a promo code set in the users quote
     *
     * @returns {boolean}
     */
    promoCodeExists() {
        return this.props.checkoutConfig.totalsData.coupon_code !== null;
    }

    /**
     * Get the applied discount codes
     *
     * @returns {array}
     */
    getAppliedPromoCodes = () => {
        const discountSegment = this.props.checkoutConfig.totalsData.total_segments
            .find(total => total.code === 'discount');
        if(discountSegment) {
           const couponCodes = discountSegment.extension_attributes.discount_details
            if (couponCodes && couponCodes.length) {
                return couponCodes;
            }
        }
        return [];
    };

    /**
     * Get applied giftcard or exchange cards
     *
     * @returns {array}
     */
    getAppliedCards = () => {
        // Filter the array items that makes up the final cart total, only grabbing the giftcardaccount (if one exists)
        return this.props.checkoutConfig.totalsData.total_segments
            .filter(total => total.code === 'giftcardaccount')
            .map((cards) => { // For the cards found, parse the JSON string and return the code for that card
                return JSON.parse(cards.extension_attributes.gift_cards).map(card => card.c);
            })[0] || [];
        // Using the filter/map functions will result in an array with 1 item, or no array. This last line will either return
        // the first element in that array, or an empty array if there are no gift cards to return
    };

    handleApplyGiftExchangeCard = async (state) => {
        const {storeCode, quoteId} = this.props.checkoutConfig;

        // Adyen is going to have to reload the form fields, so set the loading indicator now
        if (!this.props.checkoutConfig.quoteData.is_express) {
            await this.props.setAdyenIsLoading(true);
        }
        this.setState({
            cardBalance: 0,
            giftCardFeedbackMessage: ''
        });

        const {displayGiftcardPin, blackhawkPin} = this.state;

        if (displayGiftcardPin) {
            const PIN_REGEX = /^([0-9]{4,})$/;
            const validPin = blackhawkPin.match(PIN_REGEX);

            if (!validPin) {
                this.setState({
                    giftCardFeedbackMessage: 'Please enter a valid pin'
                });

                return;
            }
        }

        try {
            const {success, cardId, message} = await this.props.handleGiftExchangeCard({
                ...state,
                displayGiftcardPin: this.state.displayGiftcardPin,
                blackhawkPin: this.state.blackhawkPin
            });

            if (!success) {
                const errorObj = {message: message || 'Unable to apply card'};
                throw errorObj;
            }
            const cardBalance = await checkCardBalance(storeCode, quoteId, cardId, this.props.isLoggedIn);
            this.setState({
                ...this.state,
                cardBalance,
                giftCardFeedbackMessage: `Card balance $${cardBalance} - Card has been applied`,
                blackhawkPin: ''
            });

            return new Promise((res) => {
                res({
                    success: true
                });
            });
        } catch (error) {
            this.setState({
                giftCardFeedbackMessage: get(error, 'message', '')
            });
            return new Promise((res) => {
                res({
                    success: false,
                    message: error.message
                });
            });
        }
    };

    /**
     * Call the prop to handle removing the card, but also remove the card balance being displayed, as it could
     * no longer be required
     *
     * @param {string} cardId The ID of the card being removed
     *
     * @returns {null}
     */
    handleRemoveGiftExchangeCard = (cardId) => {
        this.props.handleRemoveGiftExchangeCard(cardId);

        this.setState({
            ...this.state,
            cardBalance: false,
            giftCardFeedbackMessage: ''
        });
    };

    /**
     * Handle the event fired when the apply button is click for the promo payment modifier
     *
     * This function will call the prop function that actually applies the promo, and then display a confirmation message
     *
     * @param {object} state The state of the promo payment modifier
     *
     * @returns {Promise}
     */
    handleApplyPromoCode = async (state) => {
        // Adyen is going to have to reload the form fields, so set the loading indicator now
        if (!this.props.checkoutConfig.quoteData.is_express) {
            this.state.dropinComponent.unmount();
            await this.props.setAdyenIsLoading(true);
        }

        const {success: applied, message} = await this.props.handlePromoCode(state);

        return new Promise((res) => {
            // If a promo code is being removed, make sure there is no feedback message still showing, and then return
            if (state.action === PaymentModifierContainer.CONST__PROMO_REMOVE) {
                this.setState({
                    ...this.state,
                    promoCodeFeedbackMessage: applied ? '' : message
                }, () => {
                    if (!this.props.checkoutConfig.quoteData.is_express) {
                        this.updateAdyenForm();
                    }
                    res({success: applied});
                });
                return;
            }

            const couponLabel = this.props.checkoutConfig.totalsData.extension_attributes.coupon_label;
            let successMessage = message;
            if (couponLabel) {
                successMessage = applied ? `${couponLabel} has been applied to your order` : message;
            }

            this.setState({
                ...this.state,
                promoCodeFeedbackMessage: successMessage
            }, () => {
                setTimeout(() => {
                    this.setState({
                        ...this.state,
                        promoCodeFeedbackMessage: ''
                    });
                }, 5000);
                if (!this.props.checkoutConfig.quoteData.is_express) {
                    this.updateAdyenForm();
                }
                res({success: applied});
            });
        });
    };

    handleRemovePromoCode = (modifiercode) => {
        this.props.handlePromoCode({action: 'remove', modifiercode});
    }

    /**
     * Get the label to display on the action button in the Promo Code payment modifier
     *
     * @returns {string}
     */
    getPromoCodeButtonLabel() {
        return 'Apply';
    }

    getStoreEnable() {
        const {storeCode, quoteId} = this.props.checkoutConfig;
        if (storeCode === 'fn_au_aisle') {
            if (document.querySelector('.tabbed-ui__tab--afterpay') != null) {
                document.querySelector('.tabbed-ui__tab--afterpay').remove();
            }

        }
    }

    renderAdyenFormFields = (afterpayEnabled) => {
        if (this.state.adyenError) {
            if (this.state.dropinComponent !== '') {
                this.state.dropinComponent.unmount();
            }
            return <button type="button" className="adyen-try-again__button" onClick={this.fetchAdyenFormDebounced}>Try
                Again</button>;
        }

        const grandTotalSegment = this.state.totals.total_segments.find(totalSegment => totalSegment.code === 'grand_total');
        const grandTotal = grandTotalSegment.value;

        return <AdyenWrapper
            isLoading={this.props.adyenIsLoading}
            isAfterpay={afterpayEnabled}
            afterpayInstallments={
                formatPrice(
                    (grandTotal / 4),
                    this.props.checkoutConfig.basePriceFormat.pattern,
                    true
                )
            }
        />;
    };

    renderPaymentSection() {
        return this.props.checkoutConfig.paymentMethods.map((method) => {
            if (method.code === 'adyen_oneclick' || method.code === 'adyen_cc' || method.code === 'adyen_hpp') {
                {
                    this.renderAdyenFormFields(false)
                }
            }
        })

    }

    getPaymentMethods() {
        if (this.state.noPaymentRequired) {
            return this.props.checkoutConfig.paymentMethods.filter(method => method.code === 'free').map((method) => {
                return {
                    code: method.code,
                    label: method.title,
                    content: null,
                    buttonLabel: 'Pay Now'
                };
            });
        }

        if (this.props.checkoutConfig.quoteData.is_express) {
            return [{
                code: 'prepaid_paypal_express',
                label: <span>&nbsp;</span>,
                content: (
                    <div className="paypal-express-tab-content">
                        <a className="choose-different-method" onClick={this.props.cancelExpressCheckout}>Choose a
                            different payment method</a>
                    </div>
                ),
                button: (
                    <button type="button" className="tabbed-ui__action-button" onClick={() => {
                        this.props.handlePaypalAction(true);
                    }} disabled={this.state.payNowEnabled ? null : 'disabled'}>
                        Pay Now
                    </button>
                )
            }];
        }

        return this.props.checkoutConfig.paymentMethods.map((method) => {
            if (method.code === 'adyen_oneclick' || method.code === 'adyen_cc') {
                return {
                    code: method.code,
                    label: <span>&nbsp;</span>, // Ensure the height of the button is still based on line height
                    content: this.renderAdyenFormFields(false),
                    button: (
                        <button type="button" className="tabbed-ui__action-button" onClick={this.handleAction}
                                disabled={this.state.payNowEnabled ? null : 'disabled'}>
                            Pay Now with {method.title}
                        </button>
                    )
                };
            }

            if (method.code === 'afterpay') {
                return {
                    code: method.code,
                    label: <span>&nbsp;</span>, // Ensure the height of the button is still based on line height
                    content: this.renderAdyenFormFields(true),
                    button: (
                        <button type="button" className="tabbed-ui__action-button" onClick={this.handleAction}
                                disabled={this.state.payNowEnabled ? null : 'disabled'}>
                            Pay Now with AfterPay
                        </button>
                    )
                };
            }


            if (method.code === 'paypal_express') {
                return {
                    code: method.code,
                    label: <span>&nbsp;</span>, // Ensure the height of the button is still based on line height
                    content: 'Click "Pay with PayPal" button below to finalise your payment.',
                    button: (
                        <PaypalExpressContainer
                            config={this.props.checkoutConfig.payment.paypalExpress}
                            handleAction={this.props.handlePaypalAction}
                            validate={this.props.checkIsValidCheckout}
                        />
                    )
                };
            }

            if (method.code === 'zipmoneypayment') {
                return {
                    code: method.code,
                    label: <span>&nbsp;</span>, // Ensure the height of the button is still based on line height
                    content: (
                        <div className="zippay-tab-content">
                            <p className="zippay-slogan-container"><span
                                className="zippay-slogan">Shop Now Pay Later</span> with <span
                                className="zip-logo">{/** Populated using CSS */}</span></p>
                            <p className="zippay-description">Zip lets you buy the items you love today & pay off over
                                time - always interest free.
                                You will be redirected to Zip to complete your order.</p>
                        </div>
                    ),
                    button: (
                        <ZipMoneyContainer
                            config={this.props.checkoutConfig.payment.zipmoneypayment}
                            handleAction={this.props.handleZippayAction}
                            validate={this.props.checkIsValidCheckout}
                            addGlobalErrorMessage={this.props.addGlobalErrorMessage}
                            clearGlobalErrorMessages={this.props.clearGlobalErrorMessages}
                        />
                    )
                };
            }

            if (method.code === 'laybuy_payment') {
                const grandTotalSegment = this.state.totals.total_segments.find(totalSegment => totalSegment.code === 'grand_total');
                const grandTotal = grandTotalSegment.value;
                const laybuyConfig = {
                    url: `/laybuy/payment/process${this.props.isGuest ? `?guest-email=${this.props.customer.email.value}` : ''}`
                }
                return {
                    code: method.code,
                    label: <span>&nbsp;</span>, // Ensure the height of the button is still based on line height
                    content: (
                        <div className="laybuy-tab-content">
                            <p className="laybuy-description">Laybuy lets you receive your purchase now and
                                spread the total cost over 6 weekly automatic payments of {
                                    formatPrice(
                                        (grandTotal / 6),
                                        this.props.checkoutConfig.basePriceFormat.pattern,
                                        true
                                    )} . Interest Free.</p>

                        </div>
                    ),
                    button: (
                        <LaybuyContainer
                            config={laybuyConfig}
                            handleAction={this.props.handleLaybuyAction}
                            validate={this.props.checkIsValidCheckout}
                            validateQuote={this.props.validateQuote}
                            addGlobalErrorMessage={this.props.addGlobalErrorMessage}
                            clearGlobalErrorMessages={this.props.clearGlobalErrorMessages}
                        />
                    )
                };
            }

            if (method.code === 'afterpaypayovertime') {
                const {basePriceFormat, defaultCountryId} = this.props.checkoutConfig;
                const {email} = this.props.customer;
                const {addGlobalErrorMessage, clearGlobalErrorMessages} = this.props;
                const totalPrice = this.state.totals.total_segments.find(total => total.code === 'grand_total');

                return {
                    code: method.code,
                    label: <span>&nbsp;</span>, // Ensure the height of the button is still based on line height
                    content: <AfterpayContent total={parseFloat(totalPrice.value)}
                                              pricePattern={basePriceFormat.pattern}
                                              defaultCountryId={defaultCountryId}/>,
                    button: (
                        <AfterpayButtonContainer
                            customerEmail={email.value}
                            defaultCountryId={defaultCountryId}
                            handleAction={this.props.handleAfterpay}
                            validate={this.props.checkIsValidCheckout}
                            addGlobalError={addGlobalErrorMessage}
                            clearGlobalErrors={clearGlobalErrorMessages}
                        />
                    )
                };
            }

            return {
                code: method.code,
                label: method.title,
                content: <div className="payment-method-container">Content for {method.title}</div>,
                buttonLabel: `Pay Now with ${method.title}`
            };
        });
    }

    /**
     * Function that can be called by a parent component to re-fetch the Adyen form
     * This will happen when the data in the checkout changes, as the Adyen form is directly related to the
     * data at the time that the form is requested. If the address data in the checkout changes, then the
     * Adyen form needs to be re-requested
     *
     * @returns {null|false}
     */
    updateAdyenForm = async () => {
        if (!this.props.checkoutConfig.paymentMethods.length || this.state.noPaymentRequired || this.props.checkoutConfig.quoteData.is_express) {
            return false;
        }
        if (this.state.dropinComponent !== '') {
            this.state.dropinComponent.unmount();
        }
        await this.props.setAdyenIsLoading(true);
        this.fetchAdyenFormDebounced();
    };

    adyenFetchCreator = () => {
        let call;

        return (data) => {
            if (call) {
                call.cancel();
            }
            call = axios.CancelToken.source();
            //return AJAX.post('/aligent_adyen/payment/session', data, { cancelToken: call.token });
            return AJAX.post(this.props.checkoutConfig.payment.forevernew_adyen.paymentSessionUrl, data, {cancelToken: call.token});
        };
    };

    adyenFetch = this.adyenFetchCreator();


    adyenResponseCreator = () => {
        let call;

        return (data) => {
            if (call) {
                call.cancel();
            }
            call = axios.CancelToken.source();

            return AJAX.post(this.props.checkoutConfig.payment.aligent_adyen.paymentResponseUrl, data, {cancelToken: call.token});
        };
    };

    adyenResponse = this.adyenResponseCreator();
    authResponseCreator = () => {
        let call;

        return (data) => {
            if (call) {
                call.cancel();
            }
            call = axios.CancelToken.source();

            return AJAX.post(this.props.checkoutConfig.payment.forevernew_adyen.authResponseUrl, data);
        };
    };
    authResponse = this.authResponseCreator();
    cancelCardResponseCreator = () => {
        let call;

        return (data) => {
            if (call) {
                call.cancel();
            }
            call = axios.CancelToken.source();

            return AJAX.post(this.props.checkoutConfig.payment.forevernew_adyen.cancelCardResponseUrl, data);
        };
    };
    cancelCardResponse = this.cancelCardResponseCreator();
    handlePaymentResponse = (formKey, paymentData) => {
        const responseData = new FormData();
        responseData.append('payload', paymentData);
        responseData.append('form_key', formKey);
        this.setState({
            payNowEnabled: true
        })
        return this.adyenResponse(responseData).then((response) => {
            if (response.data.success) {
                this.props.addGlobalErrorMessage({message: response.data.message});
            } else {
                this.props.addGlobalErrorMessage({
                    message: 'There was an error submitting your order. Please check card details and try again'
                });
            }
            return false;
        });
    };


    /**
     * Checks to see if the current tab required adyen form and returns result
     * @return {boolean}
     */
    isAnAdyenFormTab = () => {
        const {code} = this.getActivePaymentMethod();
        if (code === 'aligent_adyen' || code === 'afterpaypayovertime' || code === "adyen_oneclick" || code === "adyen_cc" || code === "adyen_hpp") {
            return true;
        }

        // no longer on CC or AP tab -- bail adyen form
        this.props.setAdyenIsLoading(false);
        return false;
    };

    /**
     * Fetch the Adyen CC form fields
     *
     * @returns {Promise}
     */
    fetchAdyenForm = async () => {
        // checks at each stage if still on CC or AP tab to continue executing
        if (!this.isAnAdyenFormTab()) return;

        await this.props.setAdyenIsLoading(true);
        return new Promise((res) => {
            this.setState({
                ...this.state,
                adyenError: false,
                adyenCardError: false,
                activeTabId: this.state.activeTabId
            }, async () => {
                if (!this.isAnAdyenFormTab()) return;
                const {cookies} = this.props;
                const formKey = cookies.get('form_key');

                const formData = new FormData();
                formData.append('quote_id', this.props.checkoutConfig.quoteData.entity_id);
                formData.append('form_key', formKey);

                try {
                    const {
                        data: {
                            data: {paymentSession, quotePaymentToken,shopperEmail},
                            success,
                            message
                        }
                    } = await this.adyenFetch(formData);

                    if (!this.isAnAdyenFormTab()) return;

                    this.props.checkoutConfig.payment.forevernew_adyen.paymentSession = paymentSession;
                    this.props.checkoutConfig.payment.forevernew_adyen.quotePaymentToken = quotePaymentToken;
                    this.props.checkoutConfig.payment.forevernew_adyen.shopperEmail = shopperEmail;
                    if (!success) {
                        throw {data: {message}};
                    }
                    //Configuration for Adyen Checkout
                    const configuration = {
                        environment: this.props.checkoutConfig.payment.forevernew_adyen.environment, // Change to 'live' for the live environment.
                        clientKey: this.props.checkoutConfig.payment.forevernew_adyen.clientkey, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
                        session: {
                            id: quotePaymentToken,
                            sessionData: paymentSession
                        },


                        instantPaymentTypes: ['applepay'], //added for apple pay test along with adyen_hpp

                        //To check validity of CC card
                        onChange: (state, component) => {
                            this.setState({
                                adyenCardError: false
                            });
                            if (state.isValid === false) {
                                this.setState({
                                    adyenCardError: true
                                });
                            }

                        },
                        beforeSubmit: (data, component, actions) => {
                            console.log("before submit")
                        },
                        //Get state information for payment verification
                        onSubmit: async (state, dropin) => {
                            let applePayButton;
                            let ccPayButton;
                            if (state.data.paymentMethod.type === "scheme") {
                                ccPayButton = document.querySelector('.adyen-checkout__button--pay');
                                if(ccPayButton)
                                {
                                    ccPayButton.disabled = true;
                                }
                            }
                            if (state.data.paymentMethod.type === "applepay") {
                                applePayButton = document.querySelector('.adyen-checkout__applepay__button');
                                if(applePayButton)
                                {
                                    applePayButton.disabled = true;
                                }
                            }
                            const responseData = new FormData();
                            responseData.append('stateData', JSON.stringify(state.data));
                            responseData.append('quote_id', this.props.checkoutConfig.quoteData.entity_id);
                            responseData.append('form_key', formKey);
                            this.setState({
                                payNowEnabled: false
                            })
                            await this.props.clearGlobalErrorMessages(); // Wait for any previous error messages to clear
                            const checkoutValid = await this.props.checkIsValidCheckout();
                            if (!checkoutValid) {
                                if (state.data.paymentMethod.type === "scheme") {
                                    if(ccPayButton)
                                    {
                                        ccPayButton.disabled = false;
                                    }
                                }
                                if (state.data.paymentMethod.type === "applepay") {
                                    if(applePayButton)
                                    {
                                        applePayButton.disabled = false;
                                    }
                                }
                                return false;
                            }
                            if (!await this.props.validateQuote("adyen_cc")) {
                                if(ccPayButton){
                                    ccPayButton.disabled = false;
                                    return false;
                                }
                            }
                            if (state.data.paymentMethod.type === "applepay") {
                                this.props.handlePayNowAction({
                                    additional_data: {'stateData': JSON.stringify(state.data)},
                                    method: 'adyen_cc',
                                    po_number: null
                                }, dropin);
                            } else if (state.data.paymentMethod.type === "scheme") {
                                this.authResponse(responseData).then((response) => {
                                        const result = response.data.data;
                                        if (response.data.success) {
                                            if (result.action) {
                                                dropin.handleAction(result.action);
                                            } else {
                                                this.props.handlePayNowAction({
                                                    additional_data: {'stateData': JSON.stringify(state.data),'ref_token':result.ref_token},
                                                    method: 'adyen_cc',
                                                    po_number: null
                                                }, dropin);
                                            }
                                        } else {
                                            if (response.data.message) {
                                                this.props.addGlobalErrorMessage({message: response.data.message});
                                            } else {
                                                this.props.addGlobalErrorMessage({
                                                    message: 'There was an error submitting your order. Please check card details and try again'
                                                });
                                            }
                                            this.setState({
                                                adyenError: true
                                            });
                                            return false;
                                        }
                                    }
                                );
                            }
                        },
                        onAdditionalDetails: (state, component) => {
                            state.data // Provides the data that you need to pass in the `/payments/details` call.
                            component // Provides the active component instance that called this event.
                        },
                        onError: (error, component) => {
                            console.log(error.name, error.message, error.stack, component);
                        },
                        showPayButton: true,
                        // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
                        // For example, this is 3D Secure configuration for cards:
                        paymentMethodsConfiguration: {
                            card: {
                                clickToPayConfiguration: {
                                    merchantDisplayName: 'Forever New',
                                    shopperEmail:  this.props.checkoutConfig.payment.forevernew_adyen.shopperEmail
                                },
                                hasHolderName: true,
                                holderNameRequired: true,
                                billingAddressRequired: false,
                                enableStoreDetails: (this.props.isLoggedIn && this.props.checkoutConfig.payment.saved_cards_enabled),

                            }
                        }
                    };

                    await this.props.setAdyenIsLoading(false);

                    if (!this.isAnAdyenFormTab()) return;
                    // Create an instance of AdyenCheckout using the configuration object.
                    const checkout = await AdyenCheckout(configuration);
                    // Create an instance of Drop-in and mount it to the container you created.
                    const dropinComponent = checkout.create('dropin', {
                        showRemovePaymentMethodButton: true,
                        onDisableStoredPaymentMethod: (storedPaymentMethodId, resolve, reject) => {
                            const responseData = new FormData();
                            responseData.append('isCheckout', true);
                            responseData.append('token', storedPaymentMethodId);
                            responseData.append('form_key', formKey);

                            this.cancelCardResponse(responseData).then((response) => {
                                    const result = response.data.data;
                                    if (response.data.success) {
                                        resolve();
                                        this.props.addGlobalSuccessMessage({message: response.data.message});
                                        setTimeout(() => {this.props.clearGlobalSuccessMessages()}, 5000);
                                    } else {
                                        reject();
                                        this.props.addGlobalErrorMessage({message: response.data.message});
                                    }
                                }
                            );
                        }
                  }).mount(document.getElementById('dropin-container'));
                    this.setState({
                        dropinComponent: dropinComponent
                    })

                } catch (response) {
                    const message = get(response, 'data.message', false);
                    // Need to check for an actual error response otherwise a message would be displayed for the cancelled requests
                    if (message !== false) {
                        this.props.addGlobalErrorMessage({message});
                    }
                }
            });
        });
    };

    /**
     * Handles auto selecting Adyen form radio button based on payment tab
     *
     * @param {string} code - current payment method
     *
     * returns {null}
     */
    handleSelectAdyenPaymentMethod = (code) => {
        if (!this.isAnAdyenFormTab()) return;
        const pm = code === 'aligent_adyen' ? 'card' : 'afterpaytouch';
        const pmButton = document.querySelector(`.chckt-pm-${pm} .js-chckt-pm__header`);
        if (pmButton) pmButton.click();
    };

    /**
     * If the currently active tab is the Adyen one, then send a request to Adyen to get
     * the hosted fields
     *
     * @param {int} activeTab The index of the active tab
     *
     * @returns {null}
     */
    handleAfterActivateAsync = (activeTab) => {
        return new Promise((res) => {
            // Check if the current active tab is the Adyen payment one, and if so, kick of requesting the hosted fields
            if (['aligent_adyen', 'adyen_oneclick', 'adyen_cc', 'afterpay', 'adyen_hpp'].indexOf(this.getPaymentMethods().filter((method, index) => index === activeTab)[0].code) === -1) {
                this.setState({
                    ...this.state,
                    activeTabId: activeTab
                }, () => {
                    res();
                });

                return;
            }

            this.setState({
                ...this.state,
                activeTabId: activeTab
            }, async () => {
                await this.fetchAdyenFormDebounced();
                this.handleSelectAdyenPaymentMethod(this.getPaymentMethods()[activeTab].code);
                res();
            });
        });
    };

    handleAction = async () => {
        await this.props.clearGlobalErrorMessages(); // Wait for any previous error messages to clear
        const activePaymentMethod = this.getActivePaymentMethod();

        const checkoutValid = await this.props.checkIsValidCheckout();
        if (!checkoutValid) {
            this.setState({payNowEnabled: true});
            return false;
        }
        this.setState({payNowEnabled: false});
        // Getting to this point, the user is completing checkout, so they hopefully won't be reloading the page again!
        Storage.removeItem(`fn-checkout-app-state-${this.props.checkoutConfig.quoteId}`);
        Storage.removeItem(`fn-checkout-delivery-state-${this.props.checkoutConfig.quoteId}`);
        Storage.removeItem('find-in-store__search-location');
        Storage.removeItem('find-in-store__pickup-location');

        const noPayment = this.props.checkoutConfig.paymentMethods.find(method => method.code === 'free');

        if (noPayment) {
            this.props.handlePayNowAction({
                additional_data: null,
                method: noPayment.code,
                po_number: null
            }, null);
            return;
        }

        if (this.props.checkoutConfig.quoteData.is_express) {
            this.props.handlePayNowAction({
                additional_data: null,
                method: 'paypal_express',
                po_number: null
            }, null);
            return;
        }

        // If the payment method currently selected isn't Adyen, then allow the action to submit
        if (activePaymentMethod.code === 'aligent_adyen') {
            if (this.props.adyenIsLoading) {
                this.props.addGlobalErrorMessage({message: 'Please wait for the payment form to finish loading.'});
                // Don't allow the user to submit checkout while the Adyen form is reloading
                return;
            }
            this.props.clearGlobalErrorMessages();
            const checkoutButton = document.querySelector('.chckt-button--submit');

            if (checkoutButton.disabled) {
                this.setState({payNowEnabled: true});
                this.props.addGlobalErrorMessage({message: 'Please fill in valid credit card details'});
                return;
            }

            if (!await this.props.validateQuote(activePaymentMethod.code)) {
                return;
            }

            // If the payment method currently selected is Adyen, then need to click on the hidden form button to trigger submission
            checkoutButton.click();
            return;
        }

        if (activePaymentMethod.code === 'adyen_cc') {
            if (this.props.adyenIsLoading) {
                this.props.addGlobalErrorMessage({message: 'Please wait for the payment form to finish loading.'});
                // Don't allow the user to submit checkout while the Adyen form is reloading
                return;
            }
            this.props.clearGlobalErrorMessages()
            const checkoutButton = document.querySelector('.adyen-checkout__button--pay');

            if (this.state.adyenCardError || checkoutButton === null) {
                this.setState({payNowEnabled: true});
                this.props.addGlobalErrorMessage({message: 'Please fill in all credit card details'});
                this.updateAdyenForm();
                return;
            }
            if (!await this.props.validateQuote(activePaymentMethod.code)) {
                return;
            }

            // If the payment method currently selected is Adyen, then need to click on the hidden form button to trigger submission
            checkoutButton.click();

            return;
        }

        if (activePaymentMethod.code === 'paypal_express') {
            this.props.handlePaypalAction({
                additional_data: null,
                method: activePaymentMethod.code,
                po_number: null
            });
            return;
        }

        if (activePaymentMethod.code === 'zipmoneypayment' || activePaymentMethod.code === 'zippayment'  ) {
            this.props.handleZippayAction({
                additional_data: null,
                method: activePaymentMethod.code,
                po_number: null
            });
            return;
        }

        if (activePaymentMethod.code === 'laybuy_payment') {
            this.props.handleLaybuyAction({
                additional_data: null,
                method: activePaymentMethod.code,
                po_number: null
            });
        }

        // if (activePaymentMethod.code === 'afterpay') {
        //     if (this.props.adyenIsLoading) {
        //         this.props.addGlobalErrorMessage({message: 'Please wait for the payment form to finish loading.'});
        //         // Don't allow the user to submit checkout while the Adyen form is reloading
        //         return;
        //     }
        //     if (!await this.props.validateQuote('aligent_adyen')) {
        //         return;
        //     }
        //
        //     const checkoutButton = document.querySelector('.chckt-button--submit');
        //     // If the payment method currently selected is Adyen, then need to click on the hidden form button to trigger submission
        //     checkoutButton.click();
        //     return;
        // }

        if (activePaymentMethod.code === 'afterpay') {
            this.props.handleAfterpay({
                additional_data: null,
                method: activePaymentMethod.code,
                po_number: null
            });
            return;
        }

        this.props.handlePayNowAction({
            additional_data: null,
            method: activePaymentMethod.code,
            po_number: null
        }, null);
    };

    renderErrorContainer() {
        if (this.props.globalErrorMessages.length === 0) {
            return null;
        }

        return (
            <div className="error-container-wrapper">
                <ErrorContainer
                    errors={this.props.globalErrorMessages}
                />
            </div>
        );
    }

    renderLoyaltyRewards() {
        if (!this.props.isLoggedIn) {
            return null;
        }

        const loyaltyRewards = this.getLoyaltyRewards();

        if (!loyaltyRewards || !loyaltyRewards.length) {
            return null;
        }

        const initialValue = {
            label: 'Please select',
            value: false
        };

        return (
            <div className="loyalty-rewards-container">
                <SelectContainer
                    id='select-loyalty-reward'
                    label='Redeem Forever You Rewards'
                    options={[
                        initialValue,
                        ...loyaltyRewards
                    ]}
                    value={initialValue}
                    isValid={true}
                    handleChange={this.handleLoyalty}
                />
                <StaticBlock
                    staticBlocks={this.state.staticBlocks}
                    identifier="loyalty-rewards-msg"
                    className="loyalty-rewards-msg"
                />
            </div>
        );
    }

    handleLoyalty = async (e) => {
        await this.promoCodeRef.current.setModifierCode(e.target.value);
        this.promoCodeRef.current.submit();
    };

    renderPromoModifier() {
        return (
            <div className="promotion-code-container">
                <StaticBlock
                    staticBlocks={this.state.staticBlocks}
                    identifier="checkout-coupon-message"
                    className="checkout-coupon-message"
                />
                <PaymentModifierContainer
                    id="promo-code"
                    ref={this.promoCodeRef}
                    modifiers={[
                        {
                            id: 'promo-code',
                            label: 'Promotion Code'
                        }
                    ]}
                    label="Promotion Code"
                    buttonLabel={this.getPromoCodeButtonLabel()}
                    appliedDiscounts={this.getAppliedPromoCodes()}
                    submit={this.handleApplyPromoCode}
                    handleRemove={this.handleRemovePromoCode}
                    isSingleton={false}
                    submitResponse={this.state.promoCodeFeedbackMessage}
                />
            </div>
        );
    }

    handleInputChange = ({target}) => {
        this.setState({
            [target.name]: target.value
        });
    };

    /**
     * Test if a code being entered matches the blackhawk prefix
     *
     * @param code
     *
     * @returns {boolean}
     */
    isBlackHawkGiftCard = (code) => {
        const blackhawkPrefix = get(this.props.checkoutConfig, 'giftcards.blackhawk_prefix', false);
        if (!blackhawkPrefix) {
            return false;
        }

        const regex = new RegExp(`^${blackhawkPrefix}`);
        return regex.test(code);
    };

    /**
     * Handle the function called when the modifier code is modified inside the PaymentModifierContainer
     *
     * @param modifiercode
     *
     * @returns {null}
     */
    handleReportModifierCode = (modifiercode) => {
        this.setState({
            modifiercode,
            displayGiftcardPin: this.isBlackHawkGiftCard(modifiercode)
        });
    };

    renderGiftCardModifier() {
        const paymentModifierClassname = classnames(
            {
                'message-is-error': !this.state.cardBalance && this.state.giftCardFeedbackMessage !== ''
            }
        );
        return (
            <div className="gift-exchange-card-container">
                <PaymentModifierContainer
                    id="gift-exchange-card"
                    modifiers={[
                        {
                            id: 'giftcard',
                            label: 'Gift Card'
                        },
                        {
                            id: 'exchange',
                            label: 'Exchange Card'
                        }
                    ]}
                    label="Enter Code"
                    buttonLabel="Redeem"
                    className={paymentModifierClassname}
                    appliedDiscounts={this.getAppliedCards()}
                    submit={this.handleApplyGiftExchangeCard}
                    handleRemove={this.handleRemoveGiftExchangeCard}
                    canCheckBalance={true}
                    submitResponse={this.state.giftCardFeedbackMessage}
                    handleReportModifierCode={this.handleReportModifierCode}
                    slots={{
                        afterFormContent: (this.state.displayGiftcardPin && (
                            <div className="blackhawk-gift-card-pin-container">
                                <InputContainer
                                    id="blackhawk-pin"
                                    value={this.state.blackhawkPin}
                                    name="blackhawkPin"
                                    onChange={this.handleInputChange}
                                    isValid={true}
                                    placeholder="Enter PIN"
                                />
                            </div>
                        )) || null
                    }}
                />
            </div>
        );
    }

    renderOrderSummary() {
        const {
            shopping_cart_display_settings: {
                shipping,
                subtotal
            },
            totalsData: {
                shipping_amount: shippingExclTax,
                subtotal: subtotalExclTax
            }
        } = this.props.checkoutConfig;

        let arrangedTotals = [];
        const orderIds = ['subtotal', 'giftcardaccount', 'discount', 'shipping', 'tax', 'grand_total'];
        this.state.totals.total_segments.forEach((price) => {
            arrangedTotals[orderIds.indexOf(price.code)] = price;
        });

        return (
            <section className="order-summary">
                <div className="order-summary__title">Order Summary</div>
                <OrderSummaryPriceContainer
                    prices={
                        arrangedTotals.filter(price => price.value !== null).map((price, index) => {
                            // 1 = exclude tax, 2 = include tax, 3 = both
                            if (price.code === 'subtotal' && subtotal !== '2') price.value = subtotalExclTax;
                            if (price.code === 'shipping' && shipping !== '2') price.value = shippingExclTax;
                            if (price.code === 'discount' &&
                                typeof this.state.totals.extension_attributes.coupon_label != 'undefined' &&
                                price.title.indexOf(this.state.totals.extension_attributes.coupon_label) === -1) {
                                price.title = price.title + ' (' + this.state.totals.extension_attributes.coupon_label + ')';
                            }

                            return {
                                id: index,
                                code: price.code,
                                label: price.title,
                                price: formatPrice(price.value, this.props.checkoutConfig.basePriceFormat.pattern, true)
                            };
                        })
                    }
                    handleRemove={() => {
                    }}
                />
            </section>
        );
    }

    renderTermsAndConditions() {
        const {
            toggleTermsAndConditions,
            showTermsAndConditionsRequiredError,
            checkoutConfig: {defaultCountryId}
        } = this.props;

        return defaultCountryId === 'CA' && (
            <div className="payment-terms-and-conditions checkout-checkbox">
                <CheckboxRadio
                    type="checkbox"
                    id="payment-terms-and-conditions"
                    name="termsAndConditionsChecked"
                    label={
                        <span>I verify the above information is correct and accept Ever New terms and conditions.</span>}
                    onChange={toggleTermsAndConditions}
                />
                <a className="terms-and-conditions-link" href="/terms" target="_blank">View T's & C's here</a>
                {showTermsAndConditionsRequiredError &&
                <div className="checkout-checkbox__error">This is a required field</div>}
            </div>
        );
    }

    toggle = (index, item) => {
        if (this.state.clicked === index) {
            return this.setState({clicked: null});
        }
        if (item.code === "adyen_cc") {
            this.updateAdyenForm();
        }
        this.setState({
            clicked: index
        })
    }

    isNativeApp = () => {
        let { storeCode } = this.props.checkoutConfig;
        if(storeCode === "fn_au_en"){
            if(window.ApplePaySession) {
                if (window.ApplePaySession.canMakePayments()) {
                    return !(RegExp("Forever New").test(navigator.userAgent) || RegExp("ForeverNew").test(navigator.userAgent))
                }
            }
        } else {
            return false;
        }
    }


    noPaymentRequired = async () => {
        const checkoutValid = await this.props.checkIsValidCheckout();
        if (!checkoutValid) {
            this.setState({payNowEnabled: true});
            return false;
        }
        const noPayment = this.props.checkoutConfig.paymentMethods.find(method => method.code === 'free');

        if (noPayment) {
            this.props.handlePayNowAction({
                additional_data: null,
                method: noPayment.code,
                po_number: null
            }, null);
            return;
        }
    }
    renderPaymentMethods = (code, title) => {
        if (code === "adyen_cc") {
            return this.renderAdyenFormFields(false)
        }

        if (code === 'afterpaypayovertime') {
            const {basePriceFormat, defaultCountryId} = this.props.checkoutConfig;
            const {email} = this.props.customer;
            const {addGlobalErrorMessage, clearGlobalErrorMessages} = this.props;
            const totalPrice = this.state.totals.total_segments.find(total => total.code === 'grand_total');

            return (
                <Fragment>
                    <AfterpayContent total={parseFloat(totalPrice.value)}
                                     pricePattern={basePriceFormat.pattern}
                                     defaultCountryId={defaultCountryId}/>

                    <AfterpayButtonContainer
                        customerEmail={email.value}
                        defaultCountryId={defaultCountryId}
                        handleAction={this.props.handleAfterpay}
                        validate={this.props.checkIsValidCheckout}
                        addGlobalError={addGlobalErrorMessage}
                        clearGlobalErrors={clearGlobalErrorMessages}
                    />
                </Fragment>
            )
        }

        if (code === 'zippayment') {
            return (
                <Fragment>
                    <div className="zippay-tab-content">
                        <p className="zippay-slogan-container"><span
                            className="zippay-slogan">Shop Now Pay Later</span> with <span
                            className="zip-logo">{/** Populated using CSS */}</span></p>
                        <p className="zippay-description">Zip lets you buy the items you love today & pay off over
                            time - always interest free.
                            You will be redirected to Zip to complete your order.</p>
                    </div>
                    <ZipMoneyContainer
                        config={this.props.checkoutConfig.payment.zippayment}
                        handleAction={this.props.handleZippayAction}
                        validate={this.props.checkIsValidCheckout}
                        addGlobalErrorMessage={this.props.addGlobalErrorMessage}
                        clearGlobalErrorMessages={this.props.clearGlobalErrorMessages}
                    />
                </Fragment>
            )
        }

        if (code === 'paypal_express') {
            return (
                <Fragment>
                    Click "Pay with PayPal" button below to finalise your payment.
                    <PaypalExpressContainer
                        config={this.props.checkoutConfig.payment.paypalExpress}
                        handleAction={this.props.handlePaypalAction}
                        validate={this.props.checkIsValidCheckout}
                    />
                </Fragment>
            )
        }

        if (code === 'laybuy_payment') {
            const grandTotalSegment = this.state.totals.total_segments.find(totalSegment => totalSegment.code === 'grand_total');
            const grandTotal = grandTotalSegment.value;
            const laybuyConfig = {
                url: `/laybuy/payment/process${this.props.isGuest ? `?guest-email=${this.props.customer.email.value}` : ''}`
            }
            return (
                <Fragment>
                    <div className="laybuy-tab-content">
                        <p className="laybuy-description">Laybuy lets you receive your purchase now and
                            spread the total cost over 6 weekly automatic payments of {
                                formatPrice(
                                    (grandTotal / 6),
                                    this.props.checkoutConfig.basePriceFormat.pattern,
                                    true
                                )} . Interest Free.</p>

                    </div>
                    <LaybuyContainer
                        config={laybuyConfig}
                        handleAction={this.props.handleLaybuyAction}
                        validate={this.props.checkIsValidCheckout}
                        validateQuote={this.props.validateQuote}
                        addGlobalErrorMessage={this.props.addGlobalErrorMessage}
                        clearGlobalErrorMessages={this.props.clearGlobalErrorMessages}
                    />
                </Fragment>
            )
        }
        return (
            <Fragment>
                <div className="payment-method-container">Content for {title}</div>
                ,
                Pay Now with ${title}
            </Fragment>
        )
    }

    render() {
        const {
            address,
            activeSection, adyenIsLoading,
            checkoutConfig: {
                defaultCountryId,
                paymentMethods,
                postsale_checkout_settings: postSaleCheckoutSettings
            }
        } = this.props;
        // Class names for the entire payment column section - including both payment component and error container
        const paymentColumnClassNames = classnames(
            'checkout-section',
            'payment-container',
            {'adyen-is-loading': adyenIsLoading},
            {'section-active': activeSection === HeaderTabs.CONST__PAYMENT_LINK}
        );
        const paymentContainerClassName = classnames(
            'payment-inner-container',
            { hidden: paymentMethods.length === 0 /*|| address.invalidAddressError */ }
        );
        // const classNames = classnames('tabbed-ui__tab','payment_methods__tab', `tabbed-ui__tab--${code}`, { active: this.state.visibleTab === index || code == "free"});
        return (
            <section className={paymentColumnClassNames} ref={this.paymentSection} id="payment-container">
                <div className={paymentContainerClassName}>
                    <div className="tab_text code_tab_label">
                        {postSaleCheckoutSettings.restrict_giftcard ?
                        "Add Promotion Code" : "Add Promotion Code or Gift Card"}
                    </div>
                    <TabbedUiContainer
                        tabs={[]}
                    >
                        <Fragment>
                            {this.renderPromoModifier()}

                            {featureFlags(defaultCountryId).REWARD_POINTS_ENABLED && this.renderLoyaltyRewards()}

                            {!postSaleCheckoutSettings.restrict_giftcard && this.renderGiftCardModifier()}
                        </Fragment>
                    </TabbedUiContainer>
                    <div style={{padding: "15px 50px"}}>
                        <Fragment>
                            {this.renderOrderSummary()}
                            {this.renderTermsAndConditions()}
                            {this.getStoreEnable()}
                        </Fragment>
                    </div>
                    <div className="payment-methods-container">
                        <div className="tab_text">
                            Choose your payment method
                        </div>
                        <div style={{padding: "0px 50px 15px"}}>
                            {this.props.checkoutConfig.paymentMethods.filter((tab) => tab.code === "free")[0] === undefined ?
                                this.props.checkoutConfig.quoteData.is_express ?
                                    <React.Fragment>
                                        <div className="collapse_container">
                                            <div className="payment_methods_collapse_div">
                                                <span className="payment_method__prepaid_paypal_express"></span>
                                            </div>
                                        </div>
                                        <div className="paypal-express-container">
                                            <button type="button" className="paypal-express-button"  onClick={() => {
                                                this.props.handlePaypalAction(true);
                                            }} disabled={this.state.payNowEnabled ? null : 'disabled'}>
                                                Pay Now
                                            </button>
                                        </div>
                                        <div className="paypal-express-tab-content">
                                        <a className="choose-different-method" onClick={this.props.cancelExpressCheckout}>Choose a
                                            different payment method</a>
                                        </div>
                                    </React.Fragment> :
                                this.props.checkoutConfig.paymentMethods.map((item, index) => {
                                    return (
                                        <div key={index} className="collapse_container">
                                            <div onClick={() => this.toggle(index, item)}
                                                 className="payment_methods_collapse_div">
                                                        <span
                                                              className={item.code === "adyen_cc" && this.isNativeApp() ? `payment_method__adyen_cc_applepay` : `payment_method__${item.code}`}>
                                                        </span>
                                                <span>{this.state.clicked === index ? <FaAngleDown/> :
                                                    <FaAngleUp/>}
                                                </span>
                                            </div>
                                            <div>
                                                {this.state.clicked === index ? (
                                                    <div style={{marginTop: "20px"}}>
                                                        {this.renderPaymentMethods(item.code, item.title)}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    );
                                })
                                : <Fragment>
                                    <div className="collapse_container" style={{marginTop: "20px"}}>No Payment
                                        Required
                                    </div>
                                    <button className="payNow__button" onClick={this.noPaymentRequired}>
                                        Pay Now
                                    </button>
                                </Fragment>}
                        </div>

                        {/*<TabbedUiContainer*/}
                        {/*    tabs={this.getPaymentMethods()}*/}
                        {/*    handleAction={this.handleAction}*/}
                        {/*    payNowEnabled={this.state.payNowEnabled}*/}
                        {/*    visibleTab={this.state.activeTabId}*/}
                        {/*    noTabsMessage='No payment methods available'*/}
                        {/*    afterActivateAsync={this.handleAfterActivateAsync}*/}
                        {/*    updateForm={this.updateAdyenForm}*/}
                        {/*    dropinCata={this.state.dropinComponent}*/}
                        {/*>*/}
                        {/*    <Fragment>*/}
                        {/*        {this.renderOrderSummary()}*/}
                        {/*        {this.renderTermsAndConditions()}*/}
                        {/*        {this.getStoreEnable()}*/}
                        {/*    </Fragment>*/}
                        {/*</TabbedUiContainer>*/}
                    </div>
                </div>
            </section>
        );
    }
}

Payment.propTypes = {
    checkoutConfig: PropTypes.object.isRequired,
    payNowEnabled: PropTypes.bool.isRequired,
    activeSection: PropTypes.number.isRequired,
    handlePromoCode: PropTypes.func.isRequired,
    handleGiftExchangeCard: PropTypes.func.isRequired,
    handleRemoveGiftExchangeCard: PropTypes.func.isRequired,
    handlePayNowAction: PropTypes.func.isRequired,
    handlePaypalAction: PropTypes.func.isRequired,
    handleZippayAction: PropTypes.func.isRequired,
    handleLaybuyAction: PropTypes.func.isRequired,
    handleAfterpay: PropTypes.func.isRequired,
    checkIsValidCheckout: PropTypes.func.isRequired,
    globalErrorMessages: PropTypes.arrayOf(PropTypes.shape({
        message: PropTypes.string.isRequired
    })),
    addGlobalErrorMessage: PropTypes.func.isRequired,
    globalSuccessMessages: PropTypes.arrayOf(PropTypes.shape({
        message: PropTypes.string.isRequired
    })),
    addGlobalSuccessMessage: PropTypes.func.isRequired,
    clearGlobalErrorMessages: PropTypes.func.isRequired,
    handleCheckBalance: PropTypes.func,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    validateQuote: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    customer: PropTypes.shape(customer),
    isGuest: PropTypes.bool.isRequired,
    setAdyenIsLoading: PropTypes.func.isRequired,
    adyenIsLoading: PropTypes.bool.isRequired,
    toggleTermsAndConditions: PropTypes.func.isRequired,
    termsAndConditionsChecked: PropTypes.bool,
    showTermsAndConditionsRequiredError: PropTypes.bool
};

export default withCookies(Payment);
