import React from 'react';
import {withCookies} from "react-cookie";
import AdyenCheckout from '@adyen/adyen-web';
import AJAX from "../Util/AJAX";
import axios from "axios";
import classnames from "classnames";



class App extends React.Component {
    constructor(props) {
        super(props);
        this.fetchCardComponent();
        this.state = {
            pendingRequests: 0,
            pendingRequestsBlockUI: false,
            errorMsg: '',
            cardComponent: '',
            isLoad: false
        }
    }

    setPendingRequestsBlockUI = () => {
        this.setState({
            pendingRequestsBlockUI: true
        });
    }

    unsetPendingRequestsBlockUI = () => {
        this.setState({
            pendingRequestsBlockUI: false
        });
    }

    setPendingRequestsCount = (modifier) => {
        return new Promise((res) => {
            const newCount = this.state.pendingRequests + modifier;

            this.setState({
                pendingRequests: Math.max(newCount, 0)
            }, () => {
                res();
            });
        });
    };

    increasePendingRequestsCountAndBlockUI = () => {
        this.setPendingRequestsBlockUI();
        return this.setPendingRequestsCount(1);
    };

    decreasePendingRequestsCountAndUnblockUI = () => {
        this.unsetPendingRequestsBlockUI();
        return this.setPendingRequestsCount(-1);
    };

    adyenFetchCreator = () => {
        let call;
        return (data) => {
            if (call)
            {
                call.cancel();
            }
            call = axios.CancelToken.source();
            return AJAX.post('/forevernew_adyen/billing/session')
        };
    };

    adyenFetch = this.adyenFetchCreator();

    authResponseCreator = () => {
        let call;
        return (data) => {
            if (call)
            {
                call.cancel();
            }
            call = axios.CancelToken.source();
            return AJAX.post('/forevernew_adyen/billing_agreement/add', data);
        };
    };
    authResponse = this.authResponseCreator();

    fetchCardComponent = async () => {
        const {
            data: {
                data: {paymentSession, quotePaymentToken},
                success,
                message
            }
        } = await this.adyenFetch();
        if(this.state.cardComponent)
        {
            this.state.cardComponent.unmount();
        }
        const configuration = {
            environment: this.props.cardPaymentConfig.card_payment.environment, // Change to 'live' for the live environment.
            clientKey: this.props.cardPaymentConfig.card_payment.clientkey, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
            session: {
                id: quotePaymentToken,
                sessionData: paymentSession
            },
            onChange: (state, component) => {
                this.setState({
                    errorMsg: ''
                })
            },
            onSubmit: async (state, dropin) => {
                await this.increasePendingRequestsCountAndBlockUI();
                const responseData = new FormData();
                responseData.append('stateData', JSON.stringify(state.data));
                this.authResponse(responseData).then((response) => {
                    const result = response.data.data;
                    if(response.data.success) {
                        if (result.action) {
                            dropin.handleAction(result.action);
                        } else {
                            window.location.replace(this.props.cardPaymentConfig.card_payment.success_redirect_url);
                        }
                    } else {
                        this.decreasePendingRequestsCountAndUnblockUI();
                        this.setState({
                            errorMsg: response.data.message
                        })
                    }
                });
            },
            onError: (error, component) => {
                console.error(error.name, error.message, error.stack, component);
            },
            showPayButton: true,
    // Any payment method specific configuration. Find the configuration specific to each payment method: https://docs.adyen.com/payment-methods
    // For example, this is 3D Secure configuration for cards:
            paymentMethodsConfiguration: {
                card: {
                    hasHolderName: true,
                    holderNameRequired: true,
                    billingAddressRequired: false
                }
            },
            translations : {
                'en-US': {
                    'confirmPreauthorization' : 'Save Credit Card',
                    "creditCard.numberField.title": "Credit Card number",
                    "creditCard.expiryDateField.title": "Expiration",
                }
            }
        };

// Create an instance of AdyenCheckout using the configuration object.
        const checkout = await AdyenCheckout(configuration);
// Access the available payment methods for the session.
        console.log(checkout.paymentMethodsResponse); // => { paymentMethods: [...], storedPaymentMethods: [...] }


// Create an instance of the Component and mount it to the container you created.
        const cardComponent = checkout.create('card',{
            onConfigSuccess: () => {
                this.setState({
                    isLoad: true
                })
            },
        }).mount('#card-container');
        this.setState({
            cardComponent: cardComponent
        })
    }
    onSaveCreditCard = () => {
        let saveBtn = document.querySelector('.adyen-checkout__button--pay')
        saveBtn.click()
    }

    render() {
        let saveBtn = document.querySelector('.adyen-checkout__button--pay')
        if(saveBtn !== null) {
            saveBtn.style.display = 'none';
        }
        let isLoaded = document.getElementById('card-container');
        if(isLoaded !== null){
            if(isLoaded.hasChildNodes() !== null){
                let updatingMsg = document.getElementsByClassName('adyen-container__loading-overlay');
                if(updatingMsg[0] !== undefined) {
                    updatingMsg[0].style.display = 'none';
                }
            }
        }
        return (
            <div>
                {this.state.errorMsg !== "" ? <div style={{paddingBottom:"10px", color: "red"}}>{this.state.errorMsg}</div> : null }
                {this.state.pendingRequests > 0 && (
                    <div className={`loading-container ${this.state.pendingRequestsBlockUI ? "blocking" : "" }`} style={{display: "flex", justifyContent: "center"}}>
                        <div className="loading-container-wrapper">
                            <i className="fa fa-circle-o-notch fa-spin fa-2x fa-fw"></i>
                        </div>
                    </div>
                )}
                <div id="card-container"><div className="adyen-container__loading-overlay">Loading...</div></div>
                {this.state.isLoad  ? <div style={{paddingTop: "25px", display:"flex",justifyContent: "center"}}>
                    <button className="save-button" style={{padding: "10px 20px",margin:"0px 15px",background:"#000",color:"#fff",border:"1px solid"}} onClick={this.onSaveCreditCard}>Save Credit Card</button>
                    <button className="close-button closeCardContainer"  style={{padding: "10px 20px",margin:"0px 15px",background:"#fff",border:"1px solid"}}>Cancel</button>
                </div> : null}
            </div>
        )
    }
}
export default App;
