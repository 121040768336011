/**
 * AfterpayContent React Component
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatPrice } from '../../Util/functions';

class AfterpayContent extends Component {
    state = {
        installments: '', // Formatted price, including currency symbol
        modalVisible: false // Flag to indicate if the Afterpay "Find out more" modal is visible
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            ...prevState,
            installments: formatPrice(nextProps.total/4, nextProps.pricePattern)
        };
    }

    /**
     * Show/hide the Afterpay "Find out more" modal
     *
     * @param {Event} e The event object fired
     *
     * @returns {null}
     */
    toggleModalVisibility = (e) => {
        e.stopPropagation();

        this.setState(prevState => ({
            ...prevState,
            modalVisible: !prevState.modalVisible
        }));
    };

    /**
     * Render the Afterpay "Find out more" modal
     *
     * @returns {JSX | null}
     */
    renderModal = () => {
        if (!this.state.modalVisible) return null;

        var desktopImage = "//edm-assets.forevernew.com.au/AfterPay/Afterpay_NZ_ShopNow_Desktop-Lightbox_Mint@3x.png";
        var mobileImage = "https://edm-assets.forevernew.com.au/AfterPay/Afterpay_NZ_ShopNow_Mobile-Lightbox_Mint@3x.png";

        if (this.props.defaultCountryId === "AU") {
            desktopImage = '//static.afterpay.com/lightbox-desktop@2x.png';
            mobileImage = 'https://static.afterpay.com/lightbox-mobile@2x.png';
        }

        return (
            <div className="afterpay-modal__overlay" onClick={this.toggleModalVisibility}>
                <article className="afterpay-modal">
                    <header>
                        <button type="button" onClick={this.toggleModalVisibility} className="afterpay-modal__close">
                            Close
                        </button>
                    </header>
                    <section>
                        <a href="https://www.afterpay.com/terms/" target="_blank" rel="noopener noreferrer">
                            <picture className="afterpay-content__modal-image">
                                <source srcSet={desktopImage} media="(min-width: 992px)" />
                                <img src={mobileImage} alt="Afterpay"/>
                            </picture>
                        </a>
                    </section>
                </article>
            </div>
        );
    };
    renderCountryString(){
        //const {defaultCountryId} = this.props.checkoutConfig;
        var countrystr = "";
        switch (this.props.defaultCountryId) {
            case "AU": countrystr = "Australia";
            break;
            case "NZ": countrystr = "New Zealand";
            break;
            default:countrystr = "Australia";
        }
        return countrystr;
    }
    render() {
        return (
            <div className="afterpay-content">
                <p className="afterpay-content__paragraph">
                    4 equal payments of {this.state.installments}
                </p>
                <p className="afterpay-content__paragraph">
                    With Afterpay you can receive your order now and pay in 4 equal fortnightly payment with no interest.<br />
                    Available to customers in {this.renderCountryString()} with a debit or credit card.
                </p>
                <p className="afterpay-content__paragraph">
                    <button type="button" onClick={this.toggleModalVisibility} className="afterpay-content__button--find-out-more">Find out more</button>
                </p>
                {this.renderModal()}
            </div>
        );
    }
}

AfterpayContent.propTypes = {
    total: PropTypes.number.isRequired,
    pricePattern: PropTypes.string.isRequired,
    defaultCountryId: PropTypes.string.isRequired
};

export default AfterpayContent;
