/**
 * PaypalExpressContainer React Component
 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import scriptLoader from 'react-async-script-loader';
import AJAX from '../../Util/AJAX';

class PaypalExpressContainer extends Component {

    state = {
        buttonLoaded: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.isScriptLoaded || !nextProps.isScriptLoadSucceed) {
            return null;
        }

        return {
            ...prevState,
            buttonLoaded: true
        };
    }

    /**
     * Set up the PayPal checkout interaction and submit
     *
     * @returns {null}
     */
    submitPaypal = async () => {
        const valid = await this.props.validate();
        if (!valid) {
            return;
        }

        if (this.props.beforeSubmit) {
            await this.props.beforeSubmit();
        }

        paypal.checkout.closeFlow(); // clear previous incomplete init

        paypal.checkout.setup(this.props.config.inContextConfig.merchantId, this.props.config.inContextConfig.clientConfig);
        let button = 1;

        // Wait for checkout to tell Magento that the user is paying with PayPal
        if (this.props.handleAction) {
            await this.props.handleAction();
            button = 0;
        }

        paypal.checkout.initXO();

        const { data } = await AJAX.get(this.props.config.inContextConfig.path, {
            button // Essentially indicates if user is using "express", i.e. they haven't yet set address information
        });

        paypal.checkout.startFlow(data.url);
        // User will receive server redirects as this process completes
    };

    render() {
        if (!this.state.buttonLoaded || !this.props.config.isContextCheckout) {
            return null;
        }

        return (
            <div className="paypal-express-container">
                <div className="tab_text express-checkout-text"><span>LOG IN FOR EXPRESS CHECKOUT WITH</span></div>
                <button className="paypal-express-button" onClick={this.submitPaypal}>
                    {this.props.label}
                </button>
                <div className="paypal-note">{this.props.note}</div>
            </div>
        );
    }

}

PaypalExpressContainer.propTypes = {
    config: PropTypes.object.isRequired,
    beforeSubmit: PropTypes.func,
    handleAction: PropTypes.func,
    validate: PropTypes.func,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    note: PropTypes.node
};

PaypalExpressContainer.defaultProps = {
    validate: () => true, // Function that will return true, if the validate function isn't provided
    label: 'Pay with PayPal',
    note: <Fragment>You can still enter a <strong>coupon code</strong> when paying with paypal express</Fragment>
};

export default scriptLoader('//www.paypalobjects.com/api/checkout.js')(PaypalExpressContainer);
