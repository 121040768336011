/**
 * SuccessContainer React Component
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SuccessContainer extends Component {
    render() {
        return (
            <section className="success-container">
                {this.props.success.map((success, index) => {
                    return (
                        <span className='success-messages' key={index}>{success.message}</span>
                    );
                })}
            </section>
        );
    }
}

SuccessContainer.propTypes = {
    success: PropTypes.arrayOf(PropTypes.shape({
        message: PropTypes.string.isRequired
    }))
};

export default SuccessContainer;
