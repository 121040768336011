import axios from 'axios';
import AJAX from './AJAX';
import { extractConfigurable } from '../Util/functions';

let STORE_URL = decodeURI(BASE_URL);
/**
 * Functions that will act as a bridge between FE and BE
 */

 if (!window.gta4Versions) { window.gta4Versions = {}; }
 window.gta4Versions.api = '0.39';

/**
 * Send request to see if email is available
 * This essentially checks if an email already exists with an account, so a user can log in with it in checkout
 *
 * @param {string} customerEmail The email address to check
 *
 * @returns {Promise<any>}
 */
export const isEmailAvailable = (customerEmail) => {
    return AJAX.post((STORE_URL || "/") + 'rest/default/V1/customers/isEmailAvailable', {
        customerEmail
    });
};

/**
 * Send request to server to log a user in
 *
 * @param {string} username Uesrname a user is trying to log in with
 * @param {string} password Password a user is trying to log in with
 * @param {string} context  The context where the user is trying to log in
 *
 * @returns {Promise<any>}
 */
export const userLogin = (username, password, context = 'checkout') => {
    return AJAX.post(
        (STORE_URL || "/") + 'customer/ajax/login',
        {
            username,
            password,
            context
        },
        {
            global: true,
            contentType: 'application/json'
        }
    );
};

/**
 * Remove an item from the users cart
 *
 * @param {string} itemId - The ID of the item being removed
 * @param {string} formKey - Form key value
 *
 * @returns {Promise}
 */
export const removeItem = (itemId, formKey) => {
    const formData = new FormData();
    formData.append('item_id', itemId);
    formData.append('form_key', formKey);

    //ga4 datalayer push
    if (gta4App) {
        gta4App.pushGTMRemoveFromCart({
            productID: itemId,            //'12345',
            //productName: '',      //'Triblend Android T-Shirt',
            //productPrice: '',               //'15.25',
            //productBrand: '',               //'Google',
            //productCategory: '',            //'Apparel',
            //productVariant: '',             //'Gray',
            //itemQuantityAssociated: '',     //1,
        });
    }

    return AJAX.post(
        (STORE_URL || "/") + 'checkout/sidebar/removeItem',
        formData,
        {
            global: true,
            contentType: 'application/json'
        }
    );
};

/**
 * Send request to server to apply a coupon code
 *
 * @param {string} storeCode   The code for the store that the user is currently in
 * @param {string} cartId      The ID for the users current cart
 * @param {string} code        The coupon code to try and apply
 * @param {boolean} isLoggedIn Flag to indicate which URL endpoint to use
 *
 * @returns {Promise}
 */
export const applyCouponCode = (storeCode, cartId, code, isLoggedIn) => {
    const endpoint = (isLoggedIn) ?
        (STORE_URL || "/") + `rest/${storeCode}/V1/carts/mine/coupons/${code}` :
        (STORE_URL || "/") + `rest/${storeCode}/V1/guest-carts/${cartId}/coupons/${code}`;

    return AJAX.put(endpoint);
};

/**
 * Send request to server to remove a coupon code
 *
 * @param {string} storeCode   The code for the store that the user is currently in
 * @param {string} cartId      The ID for the users current cart
 * @param {boolean} isLoggedIn Flag to indicate which URL endpoint to use
 *
 * @returns {Promise}
 */
export const removeCouponCode = async (storeCode, cartId, code, isLoggedIn) => {
    const endpoint = (isLoggedIn) ?
        (STORE_URL || "/") + `rest/${storeCode}/V1/carts/mine/coupons/${code}` :
        (STORE_URL || "/") + `rest/${storeCode}/V1/guest-carts/${cartId}/coupons/${code}`;

    return code ? AJAX.put(endpoint) : AJAX.delete(endpoint);
};

/**
 * Send request to server to get update payment information
 *
 * @param {string} storeCode The code for the store that the user is currently in
 * @param {string} cartId    The ID for the users current cart
 * @param {boolean} isLoggedIn Flag to indicate which URL endpoint to use
 *
 * @returns {Promise}
 */
export const fetchPaymentInformation = (storeCode, cartId, isLoggedIn = false) => {
    const endpoint = (isLoggedIn) ?
        (STORE_URL || "/") + `rest/${storeCode}/V1/carts/mine/payment-information` :
        (STORE_URL || "/") + `rest/${storeCode}/V1/guest-carts/${cartId}/payment-information`;

    return AJAX.get(endpoint);
};

/**
 * Apply a gift card or exchange card to a users cart
 *
 * @param {string}  storeCode  The code for the store that the user is currently in
 * @param {string}  cartId     The ID for the users current cart
 * @param {string}  code       The code for the card (giftcard or exchange card) being applied
 * @param {string}  type       The type of card being applied
 * @param {string}  pin        PIN for the card, if any
 * @param {boolean} isLoggedIn Flag to indicate which URL endpoint to use
 *
 * @returns {Promise}
 */
export const applyCard = async (storeCode, cartId, code, type, pin, isLoggedIn = false) => {
    const endpoint = (isLoggedIn) ?
        (STORE_URL || "/") + `rest/${storeCode}/V1/carts/mine/fnGiftCards` :
        (STORE_URL || "/") + `rest/${storeCode}/V1/carts/guest-carts/${cartId}/fnGiftCards`;
    return AJAX.post(
        endpoint,
        {
            cartId,
            giftCardAccountData: {
                gift_cards: [
                    code
                ]
            },
            giftCardType: type,
            ...pin && { pin }
        },
        {
            global: true,
            contentType: 'application/json'
        }
    );
};

/**
 * Remove a gift card or exchange card from the users cart
 *
 * @param {string}  storeCode  The code for the store that the user is currently in
 * @param {string}  cartId     The ID for the users current cart
 * @param {string}  code       The code for the card (giftcard or exchange card) being removed
 * @param {boolean} isLoggedIn Flag to indicate which URL endpoint to use
 *
 * @returns {Promise}
 */
export const removeCard = async (storeCode, cartId, code, isLoggedIn = false) => {
    let success;
    const endpoint = (isLoggedIn) ?
        (STORE_URL || "/") + `rest/${storeCode}/V1/carts/mine/giftCards/${code}` :
        (STORE_URL || "/") + `rest/${storeCode}/V1/carts/guest-carts/${cartId}/giftCards/${code}`;

    try {
        success = await AJAX.delete(
            endpoint,
            {},
            {
                global: true,
                contentType: 'application/json'
            }
        );
    } catch (err) {
        success = false;
    }

    return new Promise((res) => {
        res(success);
    });
};

/**
 * Send request to get the balance of a users giftcard/exchange card
 *
 * @param {string}  storeCode  The code for the store that the user is currently in
 * @param {string}  cartId     The ID for the users current cart
 * @param {string}  code       The code for the card (giftcard or exchange card) being removed
 * @param {boolean} isLoggedIn Flag to indicate which URL endpoint to use
 *
 * @returns {Promise<any>}
 */
export const checkCardBalance = async (storeCode, cartId, code, isLoggedIn = false) => {
    const endpoint = (isLoggedIn) ?
        (STORE_URL || "/") + `rest/${storeCode}/V1/carts/mine/fnCheckGiftCard/giftcard/${code}` :
        (STORE_URL || "/") + `rest/${storeCode}/V1/carts/guest-carts/${cartId}/fnCheckGiftCard/giftcard/${code}`;

    const { data: balance } = await AJAX.get(
        endpoint,
        {},
        {
            global: true,
            contentType: 'application/json'
        }
    );

    return new Promise((res) => {
        res(balance);
    });
};

/**
 * Send request to server to update the qty of an item in the users cart
 *
 * @param {string}  storeCode      The code for the store that the user is currently in
 * @param {string}  quoteEntityId  ID of the users quote
 * @param {object}  itemData       ID of the item having its quantity changed
 * @param {boolean} isLoggedIn     Flag to indicate which URL endpoint to use
 *
 * @returns {Promise}
 */
export const updateProduct = (storeCode, quoteEntityId, itemData, isLoggedIn = false) => {
    const endpoint = (isLoggedIn) ?
        (STORE_URL || "/") + `rest/${storeCode}/V1/carts/mine/items` :
        (STORE_URL || "/") + `rest/${storeCode}/V1/guest-carts/${quoteEntityId}/items`;

    // Find the configurable_attributes entry for the used_atributes values (size and colour)
    const sizeConfig = extractConfigurable(itemData, 'size');
    const colourConfig = extractConfigurable(itemData, 'fashion_colour');

    const configurableItemOptions = [];
    if (Object.keys(sizeConfig).length > 0) {
        configurableItemOptions.push({
            option_id: sizeConfig.option_id,
            option_value: sizeConfig.option_value
        });
    }

    if (Object.keys(colourConfig).length > 0) {
        configurableItemOptions.push({
            option_id: colourConfig.option_id,
            option_value: colourConfig.option_value
        });
    }
    return AJAX.post(
        endpoint,
        {
            cartItem: {
                item_id: itemData.item_id,
                qty: itemData.qty,
                quote_id: quoteEntityId,
                product_option: {
                    extension_attributes: {
                        configurable_item_options: configurableItemOptions
                    }
                }
            }
        }
    );
};

/**
 * Address Lookup Function Creator
 * This function will allow for requests that have been made to be cancelled and replaced with a new request
 *
 * @returns {function}
 */
const addressLookupCreator = () => {
    let call;

    return (url, data) => {
        if (call) {
            call.cancel();
        }
        call = axios.CancelToken.source();

        return AJAX.get(url, data, { cancelToken: call.token });
    };
};
// Create an instance of the addressLookupFunction
const addressLookupFunction = addressLookupCreator();
/**
 * Send request to the server to get a suggestion list of addresses that match the users input
 *
 * @param {string} country 2 digit country code to search for the address within
 * @param {string} address The single line address that the user is searching for
 *
 * @returns {Promise}
 */
export const addressLookup = (country, address) => {
    return addressLookupFunction((STORE_URL || "/") + 'addressvalidation/address/search', {
        country,
        address
    });
};

/**
 * Send request to the server to get a formatted address from a single line address
 *
 * @param {string} country 2 digit country code to search for the address within
 * @param {string} address The single line address that the user is searching for
 *
 * @returns {Promise}
 */
export const addressFormat = (country, address) => {
    return AJAX.get(
        (STORE_URL || "/") + 'addressvalidation/address/format',
        {
            country,
            address
        }
    );
};

/**
 * Send request to the server to validate an address. We get a confidence score on the response
 *
 * @param {string} address The single line address that the user is searching for
 *
 * @returns {Promise}
 */
export const addressValidate = (address) => {
    return AJAX.post((STORE_URL || "/") + 'rest/V1/validate_address', { address });
};

/**
 * Suburb Lookup Function Creator
 * This function will allow for requests that have been made to be cancelled and replaced with a new request
 *
 * @returns {function}
 */
const suburbLookupCreator = () => {
    let call;

    return (url, data) => {
        if (call) {
            call.cancel();
        }
        call = axios.CancelToken.source();

        return AJAX.post(url, data, { cancelToken: call.token });
    };
};
// Create an instance of the addressLookupFunction
const suburbLookupFunction = suburbLookupCreator();

/**
 * Send request to the server to get a suggestion list of suburbs that match the users input
 *
 * @param {string} query to search for. Can be a suburb or postcode
 * @param {string} country 2 digit country code to search for the address within
 *
 * @returns {Promise}
 */
export const suburbLookup = (query,country) => {
    return suburbLookupFunction((STORE_URL || "/") + 'rest/V1/aligent/postcodevalidation', { query,country });
};

/**
 * Send request to server to get the available shipping methods for a users provided address
 *
 * @param {string} storeCode  The store from which this quote originated
 * @param {string} cartId     The ID for the users current cart
 * @param {{
 *    country_id: string,
 *    postcode: string,
 *    region: string,
 *    region_id: string
 *  }}             address The users address to estimate shipping to
 *  @param {boolean} isLoggedIn Flag indicating if a user is logged in
 *
 * @returns {*}
 */
export const estimateShippingMethods = (storeCode, cartId, address, isLoggedIn) => {
    const endpoint = (isLoggedIn) ?
        (STORE_URL || "/") + `rest/${storeCode}/V1/carts/mine/estimate-shipping-methods` :
        (STORE_URL || "/") + `rest/${storeCode}/V1/guest-carts/${cartId}/estimate-shipping-methods`;

    return AJAX.post(endpoint, { address });
};

/**
 * Send request to server to complete checkout
 *
 * @param {string}  storeCode     The code for the store that the user is currently in
 * @param {string}  cartId        The ID for the users current cart
 * @param {string}  email         Email of the user completing checkout
 * @param {object}  paymentMethod Object representing the selected payment method
 * @param {object}  address       Object representing the billing details of the user
 * @param {boolean} isLoggedIn    Boolean indicating which API endpoint to use
 *
 * @returns {Promise}
 */
export const submitCheckout = (storeCode, cartId, email, paymentMethod, address, isLoggedIn) => {
    const endpoint = (isLoggedIn) ?
        (STORE_URL || "/") + `rest/${storeCode}/V1/carts/mine/payment-information` :
        (STORE_URL || "/") + `rest/${storeCode}/V1/guest-carts/${cartId}/payment-information`;

    return AJAX.post(
        endpoint,
        {
            billingAddress: address,
            cartId,
            email,
            paymentMethod
        }
    );
};

/**
 * Send request to server to initiate password forgot for a users email
 *
 * @param {string} email  The email of the account to reset the password for
 *
 * @returns {Promise}
 */
export const resetPassword = (email) => {
    return AJAX.put((STORE_URL || "/") + 'rest/V1/customers/password', {
        email,
        template: 'email_reset'
    });
};

/**
 * Send request to server to update quote with the shipping details so far.
 * This request will also return the payment methods available to the user, based upon their shipping address
 *
 * @param {string} storeCode       The store code the current quote originated in
 * @param {string} cartId          The ID for the users current cart
 * @param {object} shippingAddress Shipping address object
 * @param {object} billingAddress  Billing address object
 * @param {string} methodCode      Shipping method code
 * @param {string} carrierCode     Shipping carrier code
 * @param {boolean} isLoggedIn     Flag indicating if the user is logged in
 *
 * @returns {Promise}
 */
export const setShippingInformation = (storeCode, cartId, shippingAddress, billingAddress, methodCode, carrierCode, isLoggedIn) => {
    const endpoint = (isLoggedIn) ?
        (STORE_URL || "/") + `rest/${storeCode}/V1/carts/mine/shipping-information` :
        (STORE_URL || "/") + `rest/${storeCode}/V1/guest-carts/${cartId}/shipping-information`;

    return AJAX.post(endpoint, {
        address_information: {
            shipping_address: shippingAddress,
            billing_address: billingAddress,
            shipping_method_code: methodCode,
            shipping_carrier_code: carrierCode
        }
    });
};

/**
 *
 * @param {string} cartId  The ID for the users current cart
 * @param {object} address Address object
 * @param {boolean} isLoggedIn     Flag indicating if the user is logged in
 *
 * @returns {Promise}
 */
export const setBillingAddress = (cartId, address, isLoggedIn) => {
    const endpoint = (isLoggedIn) ?
        (STORE_URL || "/") + 'rest/V1/carts/mine/billing-address' :
        (STORE_URL || "/") + `rest/V1/guest-carts/${cartId}/billing-address`;

    return AJAX.post(endpoint, {
        address,
        cartId
    });
};

/**
 * Send request to the server with details of the selected payment method
 *
 * @param {string} storeCode      The store code the current quote originated in
 * @param {string} cartId         The ID for the users current cart
 * @param {string}  email         Email of the user completing checkout
 * @param {object}  paymentMethod Object representing the selected payment method
 * @param {boolean} isLoggedIn    Boolean indicating which API endpoint to use
 *
 * @returns {null}
 */
export const setPaymentInformation = (storeCode, cartId, email, paymentMethod, isLoggedIn) => {
    const endpoint = (isLoggedIn) ?
        (STORE_URL || "/") + `rest/${storeCode}/V1/carts/mine/set-payment-information` :
        (STORE_URL || "/") + `rest/${storeCode}/V1/guest-carts/${cartId}/set-payment-information`;

    return AJAX.post(endpoint, {
        cartId,
        email,
        paymentMethod
    });
};

/**
 * Create a new customer account
 *
 * @param {int}     storeId      ID of the store that the customer account will be created in
 * @param {string}  email        Email address of the new customer
 * @param {string}  firstname    Firstname of the new customer
 * @param {string}  lastname     Lastname of the new customer
 * @param {string}  telephone    Telephone number of the new customer
 * @param {string}  password     Desired password for the new customer
 * @param {boolean} isSubscribed Flag indicating if the user selected to sign up for the newsletter
 *
 * @returns {Promise}
 */
export const createCustomerAccount = ({
    storeId,
    email,
    firstname,
    lastname,
    telephone,
    password,
    dob,
    isSubscribed,
    checkout_registration_secret_key,
    language_preference
}) => {
    const custom_attributes = [
        {
            attribute_code: 'mobile',
            value: telephone
        },
        {
            attribute_code: 'dob',
            value: dob
        }
    ];

    if (language_preference) {
        custom_attributes.push({
            attribute_code: 'lang_preference',
            value: language_preference
        })
    }

    return AJAX.post((STORE_URL || "/") + 'rest/V1/customers', {
        customer: {
            storeId,
            email,
            firstname,
            lastname,
            dob,
            custom_attributes,
            extension_attributes: {
                is_subscribed: isSubscribed
            },
            checkout_registration_secret_key
        },
        password
    });
};

/**
 * Send request to the server with the store ID that has been selected for click and collect
 *
 * @param {string} selectedStoreId ID of the selected store
 *
 * @returns {Promise}
 */
export const setClickAndCollectStore = (selectedStoreId) => {
    const formData = new FormData();
    formData.append('location_id', selectedStoreId);
    return AJAX.post((STORE_URL || "/") + 'omnichannel/sales/setclickcollectlocation', formData);
};

/**
 * Send request to server to remove attached click and collect store from the customers order
 *
 * @returns {Promise}
 */
export const removeClickAndCollectStore = () => {
    return AJAX.post((STORE_URL || "/") + 'omnichannel/sales/removeclickcollectlocation');
};

/**
 * Validate that the quote will likely succeed once payment has completed
 *
 * @param {string}  storeCode     The store code the current quote originated in
 * @param {string}  cartId        The ID for the users current cart
 * @param {string}  email          Users email address
 * @param {object}  billingAddress Billing address object
 * @param {object}  paymentMethod  The payment method object
 * @param {boolean} isLoggedIn    Flag to indicate which URL endpoint to use
 *
 * @returns {AxiosPromise}
 */
export const validateQuote = (storeCode, cartId, email, billingAddress, paymentMethod, isLoggedIn) => {
    const endpoint = (isLoggedIn) ?
        (STORE_URL || "/") + `rest/${storeCode}/V1/carts/mine/validate-quote` :
        (STORE_URL || "/") + `rest/${storeCode}/V1/guest-carts/${cartId}/validate-quote`;

    return AJAX.post(endpoint, {
        email,
        billing_address: billingAddress,
        payment_method: paymentMethod
    });
};
