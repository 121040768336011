/**
 * Extract configurable information from the provided item
 *
 * This function will map the configurable_attributes and used_attributes properties
 *
 * @param {Object} item         The item being updated
 * @param {string} configurable The configurable to get the currently selected value of
 *
 * @returns {Object}
 */
export default (item, configurable) => {
    const currentConfigurableId = Object.keys(item.configurable_attributes).find(optId => item.configurable_attributes[optId].attribute_code === configurable);
    if (!currentConfigurableId) {
        return {};
    }
    return item.used_attributes.find(attr => parseInt(attr.option_id, 10) === parseInt(currentConfigurableId, 10));
};
