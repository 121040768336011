/**
 * Indicates if the checkout is using the mobile UI
 *
 * This can be used to customize some of the "automatic" actions that might take place on desktop, that shouldn't
 * take place on mobile, or vice versa
 */
export default () => {
    // The width below at which the UI will change to tablet/mobile
    const CONST__MOBILE_BREAKPOINT = 950;

    return window.innerWidth <= CONST__MOBILE_BREAKPOINT;
};
