/**
 * ClickAndCollect React Component
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { deepEqual } from 'fast-equals';
import InputContainer from './../../common/InputContainer';
import EmailContainer from './../../common/EmailContainer';
import FindInStore from './../../Checkout/containers/FindInStore';
import { customer } from '../../propTypes';
import showInvalidPhoneNumberMessage from '../../Util/functions/show_invalid_phone_number_message';
import { __ } from '../../Util/Translator';

class ClickAndCollect extends Component {

    static get CONST_DISPLAY_LIMIT() { return 3; }

    constructor(props) {
        super(props);

        this.state = {
            stockists: [],
            collapse: true,
            findInStoreSet: false
        };

        this.findInStoreRef = React.createRef();
    }

    componentDidMount() {
        this.updateSelectedStoreId();
    }

    componentDidUpdate() {
        this.updateSelectedStoreId();
    }

    /**
     * Update selected store ID from localStorage, if set
     *
     * @returns {Promise<void>}
     */
    updateSelectedStoreId = async () => {
        if (!this.state.stockists.length) {
            return;
        }

        if (this.props.findInStore.selectedStore !== '' && !this.props.findInStoreSet) {
            this.findInStoreRef.current.setSuburb(this.props.findInStore.location);
            this.findInStoreRef.current.setSelectedStore(this.props.findInStore.selectedStore);

            this.setState({
                collapse: false
            });
            this.props.updateFindInStoreSet(true);
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (deepEqual(nextProps.stockists, prevState.stockists)) {
            return null;
        }

        return {
            ...prevState,
            stockists: nextProps.stockists.map(ClickAndCollect.transformStoreInfo),
            // If the user has already clicked "Show more stores", then make sure that remains
            collapse: prevState.collapse && nextProps.stockists.length > ClickAndCollect.CONST_DISPLAY_LIMIT
        };
    }

    static transformStoreInfo = (store) => {
        const storeHours = JSON.parse(store.opening_hours);
        const storeHoursArr = Object.keys(storeHours).map((storeHoursKey) => {
            const hours = storeHours[storeHoursKey];
            return `${storeHoursKey}: ${hours}`;
        });

        // round to two decimal places
        const distance = Number(store.distance).toFixed(2);

        return {
            ...store,
            distance: `${distance} km`,
            additional_info: {
                label: 'Open: ',
                value: storeHoursArr.join(', ')
            },
            click_collect_available: store.product_stock ? store.click_collect_available : false,
            product_stock: store.product_stock || [],
        };
    };

    handleShowMore = () => {
        this.setState({
            collapse: false
        });
    };

    /**
     * Renders selected click and collect store error if exists
     * @return {Element}
     */
    renderErrorSelectedClickCollectStore = () => {
        const { errorSelectedClickCollectStore: error } = this.props;

        return error && error !== '' ? (
            <div className="error-container error-container--click-and-collect">
                <header className="error-container__header">{error}</header>
            </div>
        ) : null;
    };

    renderErrorMessage() {
        if (this.props.errorMessage === '') {
            return null;
        }

        return (
            <div className="validation-message validation-error">{this.props.errorMessage}</div>
        );
    }

    render() {
        return (
            <section className="click-and-collect-container">
                <InputContainer
                    id="click-and-collect__firstname"
                    name="firstname"
                    label="First Name"
                    placeholder="Enter your first name"
                    value={this.props.customer.firstname.value}
                    isValid={this.props.customer.firstname.valid}
                    onChange={this.props.handleChangeCustomer}
                    onBlur={this.props.handleOnBlur}
                    isRequired={true}
                />
                <InputContainer
                    id="click-and-collect__lastname"
                    name="lastname"
                    label="Last Name"
                    placeholder="Enter your last name"
                    value={this.props.customer.lastname.value}
                    isValid={this.props.customer.lastname.valid}
                    onChange={this.props.handleChangeCustomer}
                    onBlur={this.props.handleOnBlur}
                    isRequired={true}
                />
                <EmailContainer
                    id="click-and-collect__email"
                    name="email"
                    placeholder="Enter your email address"
                    value={this.props.customer.email.value}
                    isValid={this.props.customer.email.valid}
                    onChange={this.props.handleChangeCustomer}
                    onBlur={this.props.handleOnBlur}
                    isRequired={true}
                />
                <InputContainer
                    id="click-and-collect__telephone"
                    name="telephone"
                    type="tel"
                    label="Telephone"
                    placeholder="Phone number..."
                    value={this.props.customer.telephone.value}
                    isValid={this.props.customer.telephone.valid}
                    onChange={this.props.handleChangeCustomer}
                    onBlur={this.props.handleOnBlur}
                    isRequired={true}
                    errorMessage={'Invalid phone number. Please enter a valid number with no more than 15 digits'}
                />

                {/*{showInvalidPhoneNumberMessage(this.props.customer.telephone.valid)}*/}

                {this.props.deliveryForm}

                <div className={`find-in-store__wrapper ${this.state.collapse || !this.state.stockists.length ? '' : 'expanded'}`}>
                    {this.renderErrorMessage()}
                    <FindInStore
                        ref={this.findInStoreRef}
                        stockists={this.state.collapse ? this.state.stockists.slice(0, ClickAndCollect.CONST_DISPLAY_LIMIT) : this.state.stockists}
                        handleSearch={this.props.handleSearch}
                        errorMessage="Some items not available for click and collect"
                        onSelectStore={this.props.onSelectStore}
                        placeholder={__('Enter Suburb...')}
                        cart={this.props.cart}
                    />
                </div>
                <button
                    className="click-and-collect__show-more-stores"
                    onClick={this.handleShowMore}
                    hidden={!this.state.collapse || this.state.stockists.length === 0}
                >Show more stores</button>

                {this.renderErrorSelectedClickCollectStore()}
            </section>
        );
    }

}

ClickAndCollect.propTypes = {
    handleChangeCustomer: PropTypes.func.isRequired,
    stockists: PropTypes.array.isRequired,
    handleSearch: PropTypes.func.isRequired,
    onSelectStore: PropTypes.func.isRequired,
    deliveryForm: PropTypes.element.isRequired,
    errorMessage: PropTypes.string.isRequired,
    customer: PropTypes.shape(customer),
    findInStore: PropTypes.shape({
        selectedStore: PropTypes.string,
        location: PropTypes.string
    }),
    findInStoreSet: PropTypes.bool,
    updateFindInStoreSet: PropTypes.func,
    errorSelectedClickCollectStore: PropTypes.string
};

export default ClickAndCollect;
