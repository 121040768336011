/**
 * Function that will validate an email address to ensure it, at the very least, includes an @ symbol and a dot
 *
 * @param {string} email The email to test
 *
 * @returns {Boolean}
 */
export default (email) => {
    return /(.+)@(.+)\.(.+){2,}/.test(email);
};
